import { FC, useState } from 'react';
import CountyStationList, { Counties, SelectedStations } from '../Observations/CountyStationList';
import { FormattedMessage, useIntl } from 'react-intl';
import PanelMap from '../Observations/MapPanel';
import { ObservationSource } from '@Types/ObservationSource';
import { Button, Chip, Grid, InputLabel, Theme, makeStyles } from '@material-ui/core';
import { StationSearchField, StationsMap } from './StationSearchField';
import { SeklimaTooltip } from 'components/SeklimaToolTip';
import { capitalizeStationName } from 'utils/stringUtils';
import { Clear } from '@material-ui/icons';
import ListIcon from '../../images/listIcon.png';
import { ReactComponent as Pin } from '../../images/pin.svg';

export const Stations: FC<{
  baseUrl: string;
  stationsMap: StationsMap;
  fetchStationsMap: (name: string) => void;
  clearStationsMap: () => void;
  counties: Counties;
  fetchCounties: () => void;
  selectedStations: SelectedStations;
  setSelectedStations: (stations: SelectedStations) => void;
  maxSelectedStations: number;
}> = ({
  baseUrl,
  stationsMap,
  fetchStationsMap,
  clearStationsMap,
  counties,
  fetchCounties,
  selectedStations,
  setSelectedStations,
  maxSelectedStations,
}) => {
  const [showMap, setShowMap] = useState(false);
  const [showCountyList, setShowCountyList] = useState(false);

  const intl = useIntl();

  const toggleCountyList = () => {
    if (showCountyList) {
      setShowCountyList(false);
    } else {
      fetchCounties();
      setShowCountyList(true);
    }
  };

  const toggleMapDialog = () => {
    setShowMap(!showMap);
  };

  const setSelectedStationsAndClear = (selectedStations: SelectedStations) => {
    setSelectedStations(selectedStations);
    clearStationsMap();
  };

  const classes = useStyles();
  const handleSelectStation = (item: ObservationSource) => {
    let newSelectedStations = new Map(selectedStations);
    if (!newSelectedStations.has(item.id)) {
      newSelectedStations.set(item.id, item.name);
      setSelectedStations(newSelectedStations);
    }
    clearStationsMap();
  };

  const handleDelete = (item: string) => {
    let newSelectedStations = new Map(selectedStations);
    if (newSelectedStations.delete(item)) {
      setSelectedStations(newSelectedStations);
    }
  };

  return (
    <>
      <div>
        <Grid container direction={'column'} className={classes.gridContainer}>
          <Grid item xs={12}>
            <InputLabel style={{ paddingBottom: 6 }}>
              <FormattedMessage id="searchStations" />
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={6} style={{ display: 'flex' }}>
            <div className={classes.searchComponent}>
              <StationSearchField
                mapMode={false}
                selectedStations={selectedStations}
                stationMap={stationsMap}
                handleSelectedStation={handleSelectStation}
                fetchStationsMap={fetchStationsMap}
                clearElementMap={clearStationsMap}
                placeHolder={intl.formatMessage({ id: 'station_name_or_number' })}
                limitSelection={{
                  hasLimit: true,
                  maxSelectedElements: maxSelectedStations,
                  maxSelectedMessage: intl.formatMessage({ id: 'max_stations_selected' }),
                }}
              />
            </div>
            <CountyListButton toggleCountyList={toggleCountyList} />
            <MapButton toggleMapDialog={toggleMapDialog} />
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <Chips handleDelete={handleDelete} selectedStations={selectedStations} />
            </div>
          </Grid>
        </Grid>
      </div>
      <PanelMap
        showMap={showMap}
        fetchStationsMap={fetchStationsMap}
        clearStationsMap={clearStationsMap}
        stationsMap={stationsMap}
        selectedStations={selectedStations}
        setSelectedStations={setSelectedStationsAndClear}
        toggleMapDialog={toggleMapDialog}
        apiUrl={baseUrl}
        maxSelectedStations={maxSelectedStations}
      />
      <CountyStationList
        showCountyList={showCountyList}
        countyList={counties}
        toggleCountyList={toggleCountyList}
        selectedStations={selectedStations}
        setSelectedStations={setSelectedStationsAndClear}
        maxStations={maxSelectedStations}
      />
    </>
  );
};

const MapButton: FC<{ toggleMapDialog: () => void }> = ({ toggleMapDialog }) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <SeklimaTooltip
      title={intl.formatMessage({
        id: 'select_in_map',
      })}
      placement="top"
    >
      <Button variant="outlined" className={classes.buttonIcons} aria-label="Location" onClick={toggleMapDialog}>
        <Pin />
      </Button>
    </SeklimaTooltip>
  );
};

const CountyListButton: FC<{ toggleCountyList: () => void }> = ({ toggleCountyList }) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <SeklimaTooltip
      title={intl.formatMessage({
        id: 'select_in_list',
      })}
      placement="top"
    >
      <Button variant="outlined" className={classes.buttonIcons} aria-label="Location" onClick={toggleCountyList}>
        <img src={ListIcon} alt="listIcon" />
      </Button>
    </SeklimaTooltip>
  );
};

const Chips: FC<{ selectedStations: SelectedStations; handleDelete: (item: string) => void }> = ({
  selectedStations,
  handleDelete,
}) => {
  const classes = useStyles();

  let stationKeys = Array.from(selectedStations.keys());
  return (
    <>
      {stationKeys.map((key) => {
        let name = selectedStations.get(key);
        return (
          <Chip
            key={key}
            label={capitalizeStationName(name)}
            onDelete={() => handleDelete(key)}
            color={'primary'}
            className={classes.chip}
            deleteIcon={<Clear fontSize={'small'} />}
          />
        );
      })}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  [theme.breakpoints.up('xs')]: {
    gridContainer: {
      marginTop: '2em',
      marginBottom: '2em',
    },
    chip: {
      margin: theme.spacing(),
    },
    buttonIcons: {
      minWidth: '55px',
      marginLeft: '0.5em',
    },
    searchComponent: {
      flexGrow: 1,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  [theme.breakpoints.up('sm')]: {
    paper: {
      width: '50%',
      maxHeight: '40%',
    },
  },
}));

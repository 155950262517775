import React from 'react';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {},
});

function AlertDialog(props) {
  const { title, message, acceptOption, cancelOption, alertHandler } = props;
  return (
    <Dialog
      open={props.open}
      onClose={() => alertHandler(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id={title} value={title} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id={message} value={message} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => alertHandler(false)} color="primary">
          <FormattedMessage id={cancelOption} value={cancelOption} />
        </Button>
        <Button onClick={() => alertHandler(true)} color="primary" autoFocus>
          <FormattedMessage id={acceptOption} value={acceptOption} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  alertHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

AlertDialog.defaultProps = {
  acceptOption: 'time_resolution_alert_accept',
  cancelOption: 'time_resolution_alert_cancel',
};

export default withStyles(styles)(AlertDialog);

import TableRow from '@material-ui/core/TableRow/index';
import TableCell from '@material-ui/core/TableCell/index';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import timeHandler from '../Observations/helper/timeHandler';

function TableBodyRow(props) {
  const { body, selectedResolution, index } = props;
  return (
    <TableRow style={{ backgroundColor: index % 2 === 0 ? 'rgba(77, 163, 106, 0.1)' : '' }}>
      {Object.keys(body).map((key, index) => {
        if (key === 'id') return null;
        const value = body[key];
        if (key === 'time')
          return (
            <TableCell key={value}>{moment(value).format(timeHandler.getTimeFormat(selectedResolution))}</TableCell>
          );
        if (key === 'name') return <TableCell key={index}>{value}</TableCell>;
        if (key === 'station') return <TableCell key={index}>{value}</TableCell>;
        if (value === '-') {
          return <TableCell key={index}>{value}</TableCell>;
        }
        let cellValue = props.intl.formatNumber(value);
        if (isNaN(cellValue)) {
          cellValue = value;
        }
        return <TableCell key={index}>{cellValue}</TableCell>;
      })}
    </TableRow>
  );
}

TableBodyRow.propTypes = {
  body: PropTypes.object.isRequired,
  selectedResolution: PropTypes.string,
  index: PropTypes.number.isRequired,
};

export default injectIntl(TableBodyRow);

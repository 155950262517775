import { ObservationSource, ObservationSourceList } from '@Types/ObservationSource';
import { otherCountyIdentifies } from './defaultValues';
import { correctedCountyName, isExcludedStation } from './stationsUtils';

export const stationsSearchMap = (sources: ObservationSource[], searchString: string) => {
  let newStationsMap = new Map<string, ObservationSource>();
  sources.forEach((source) => {
    if (source.name.toLocaleLowerCase().includes(searchString) || source.id.toLocaleLowerCase().includes(searchString))
      newStationsMap.set(source.id, source);
  });
  return newStationsMap;
};

export const buildAndSetCountyMap = (
  res: ObservationSourceList,
  setCountyMap: (arg0: Map<string, ObservationSource[]>) => void,
) => {
  let cMap = new Map<string, Array<ObservationSource>>();
  res?.data?.forEach((source) => {
    if (!isExcludedStation(source)) {
      let correctedCounty = correctedCountyName(source.county, otherCountyIdentifies);
      if (cMap.has(correctedCounty)) {
        cMap.get(correctedCounty)?.push(JSON.parse(JSON.stringify(source)));
      } else {
        cMap.set(correctedCounty, [JSON.parse(JSON.stringify(source))]);
      }
    }
  });

  setCountyMap(cMap);
};

import React, { useEffect, useState } from 'react';
import { PageHeader } from '../components/PageHeader';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { StationPeriodSelect } from 'components/Stations/StationPeriodSelect';
import { fetchStationNameSearch, isExcludedStation } from 'utils/stationsUtils';
import { ObservationSource } from '@Types/ObservationSource';
import { StationDetailsBlock } from 'components/Stations/StationDetailsBlock';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { stationsSearchMap } from 'utils/StationSearchUtils';
import { Stations } from 'components/Stations/Stations';
import { getCounties } from './observationPageUtils';
import { StationsMap } from 'components/Stations/StationSearchField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('xs')]: {
      homeContainer: {
        marginLeft: '1em',
        marginRight: '1em',
      },
      button: {
        marginBottom: theme.spacing(),
        marginRight: '1em',
      },
    },
  }),
);

interface Props {
  apiBaseUrl: string;
}

export const StationsPage: React.FC<Props> = ({ apiBaseUrl }) => {
  const classes = useStyles();
  const intl = useIntl();
  //id and name of selected stations
  const [selectedStations, setSelectedStations] = React.useState(new Map<string, string>());
  //all current station objects mapped by county
  const [sources, setSources] = React.useState<ObservationSource[]>([]);
  //selected period, in the format of url query string
  const [periodUri, setPeriodUri] = React.useState<string>('');

  React.useEffect(() => {
    document.title = intl.formatMessage({ id: 'station_information' }) + ' - Seklima';
    document.documentElement.lang = intl.locale;
  }, [intl]);

  const currentStations = (): ObservationSource[] => {
    let current = new Array<ObservationSource>();
    sources.forEach((station) => {
      if (selectedStations.has(station.id)) current.push(station);
    });

    return current;
  };

  const missingStations = (stations: ObservationSource[]) => {
    let missingStations: Array<String> = [];
    selectedStations.forEach((value, key) => {
      if (!stations.some((e) => e.id === key)) {
        missingStations.push(value);
      }
    });
    return missingStations;
  };

  useEffect(() => {
    if (!periodUri.toLowerCase().includes('invalid')) {
      fetchStationNameSearch(apiBaseUrl, 'activestations', '', periodUri).then((res) => {
        let sources: ObservationSource[] = [];
        res?.data?.forEach((source) => {
          if (!isExcludedStation(source)) {
            sources.push(source);
          }
        });

        setSources(sources);
      });
    }
  }, [apiBaseUrl, periodUri]);

  const [stationsMap, setStationsMap] = useState<StationsMap>(new Map());
  return (
    <div className="global-root">
      <div className={classes.homeContainer}>
        <PageHeader titleId="station_information" infoTextId="station_information_description" />
        <form autoComplete="off">
          <StationPeriodSelect setPeriodUri={setPeriodUri} />
          <Stations
            baseUrl={apiBaseUrl}
            stationsMap={stationsMap}
            fetchStationsMap={(name: string) => {
              const newStations = stationsSearchMap(sources, name);
              setStationsMap(newStations);
            }}
            clearStationsMap={() => setStationsMap(new Map())}
            counties={getCounties(sources, intl.formatMessage({ id: 'regions' }), intl.formatMessage({ id: 'others' }))}
            fetchCounties={() => {}}
            selectedStations={selectedStations}
            setSelectedStations={setSelectedStations}
            maxSelectedStations={50}
          />

          <StationDetailsBlock
            stations={currentStations()}
            missingStations={missingStations(currentStations())}
            apiBaseUrl={apiBaseUrl}
          />
          <Typography>
            <FormattedMessage id="met_data_licence_pt1" />
            {moment().format('DD.MM.YYYY')}
            <FormattedMessage id="met_data_licence_pt2" />
          </Typography>
        </form>
      </div>
    </div>
  );
};

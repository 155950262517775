/* eslint-disable import/no-anonymous-default-export */
import { SelectedWeatherElements } from 'pages/ObservationPage';
import { capitalizeAllWords } from '../../../utils/stringUtils';
import Moment from 'moment';
import { SelectedStations } from '../CountyStationList';
import { sortBy } from 'lodash';

interface SourceObservations {
  sourceId: string;
  referenceTime: string;
  observations: Observation[];
}

interface Observation {
  elementId: string;
  value: number;
  unit: string;
  level: Level;
  timeOffset: string;
  timeResolution: string;
  timeSeriesId: number;
  performanceCategory: string;
  exposureCategory: string;
  qualityCode: number;
}

interface Level {
  levelType: string;
  unit: string;
  value: number;
}

const generateColors: (numColors: number) => string[] = (numColors) => {
  const baseColors = [
    '#0085cc', // blå
    '#953579', // lilla
    '#8d6f1a', // gul
    '#EAA228', // oransje
    '#579575', // svak grønn
    '#fe6a35', // sterk oransje
    '#42683A', // grønn
    '#d568fb', // lys lilla
    '#2ee0ca', // turkis
    '#6b8abc', // lys blå
    '#fa4b42', // tomatrød
    '#feb56a', // gull
  ];
  const colors = new Set<string>(baseColors);

  while (colors.size < numColors) {
    const color = `#${Math.floor(Math.random() * 0xffffff)
      .toString(16)
      .padStart(6, '0')}`;
    colors.add(color);
  }

  return Array.from(colors);
};

const colorsArray = generateColors(100);

const observationHandlerProps = {
  preferredTimeOffsets: [
    { id: 'sum(precipitation_amount P1M)', offset: 'PT6H' },
    { id: 'sum(precipitation_amount P1D)', offset: 'PT6H' },
    { id: 'max(air_temperature P1D)', offset: 'PT18H' },
    { id: 'max(air_temperature PT12H)', offset: 'PT6H' },
  ],

  IdsWithFalseNegatives: [
    'cloud_area_fraction',
    'low_type_cloud_area_fraction',
    'cloud_area_fraction1',
    'cloud_base_height',
    'surface_snow_thickness',
    'surface_snow_thickness',
    'mean(surface_snow_thickness P1M)',
    'max(surface_snow_thickness P1M)',
    'min(surface_snow_thickness P1M)',
    'snow_coverage_type',
    'mean(snow_coverage_type P1M)',
    'min(snow_coverage_type P1M)',
    'max(snow_coverage_type P1M)',
    'state_of_ground',
    'wind_from_direction',
    'sum(precipitation_amount PT6H)',
    'sum(precipitation_amount PT12H)',
    'sum(precipitation_amount P1D)',
    'sum_over_undefined_period(precipitation_amount PT1H)',
    'sum(precipitation_amount P1D)',
    'max(sum(precipitation_amount P1D) P1M)',
    'sum(precipitation_amount P30D)',
    'sum_until_day_of_year(precipitation_amount P1D)]',
  ],

  preferredLevel: [{ id: 'air_temperature', value: 2 }],

  columnPlotUnits: ['mm', 'octas', 'percent', 'cm'],

  colors: colorsArray,

  currentStations: [] as string[],

  units: { degC: '°C' },
};

type Element = {
  elementId: string;
  unit?: string;
};
const getObservationDataElementIds = (dataArray: SourceObservations[]) => {
  const elements = dataArray.reduce<Element[]>((elements, obj) => {
    obj?.observations?.forEach((observation) => {
      const elementIsNotInArray = elements.map((e) => e.elementId).indexOf(observation.elementId) === -1;
      if (elementIsNotInArray) {
        elements.push({ elementId: observation.elementId, unit: observation.unit });
      }
    });

    return elements;
  }, []);
  return sortBy(elements, (element) => element.unit?.toLowerCase() === 'date');
};

const getObservationDataStationIds = (dataArray: SourceObservations[]) => {
  let elementIds = dataArray.reduce<string[]>((idArray, obj) => {
    if (obj && obj.sourceId) {
      idArray.push(obj.sourceId);
    }
    return idArray;
  }, []);
  return [...new Set(elementIds)];
};

export type YAxisOptions = Highcharts.YAxisOptions & { showInChart: boolean };
const getPlotElementList = (
  elements: Element[],
  selectedWeatherElements: SelectedWeatherElements,
): Highcharts.YAxisOptions[] => {
  let units = observationHandlerProps.units;

  let eList: YAxisOptions[] = [];
  for (let element of elements) {
    let height = Math.floor(100 / elements.length);
    let topVal = Math.floor(eList.length * height);
    let title = '';
    for (let key in selectedWeatherElements) {
      if (selectedWeatherElements[key].id === element.elementId) title = selectedWeatherElements[key].name;
    }
    const unit = units[element.unit as keyof typeof units];
    const unitToShow = unit ?? element.unit;
    eList.push({
      showInChart: unitToShow?.toLowerCase() !== 'date',
      height: height - 2 + '%',
      lineWidth: 2,
      opposite: false,
      top: topVal + '%',
      offset: 0,
      labels: {
        align: 'right',
        style: {
          fontSize: '12px',
        },
        format: '{value} ' + unitToShow,
      },
      title: {
        align: 'high',
        textAlign: 'left',
        offset: 0,
        rotation: 0,
        y: 15,
        x: 10,
        text: title,
        style: {
          width: 250,
          textOverflow: 'ellipsis',
        },
      },
    });
  }
  return eList;
};

export type SeriesOptions = (Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions) & {
  elementId: string;
  showInChart: boolean;
};
//TODO this is a fix to show data when there is only one series. Might cause some weird edge case bugs.
const hasMultipleElements = (obs: Observation, obj: Observation[]) => {
  let counter = 0;
  for (let item of obj) {
    if (item.elementId === obs.elementId) {
      if (counter === 1) return true;
      counter++;
    }
  }
  return false;
};

const getPlotSeriesList = (
  elementSet: Element[],
  stationSet: string[],
  dataArray: SourceObservations[],
  selectedStations: SelectedStations,
) => {
  let sList: SeriesOptions[] = [];
  let yAxisCount = 0;
  for (let element of elementSet) {
    let canHaveFalseNegative = observationHandlerProps.IdsWithFalseNegatives.includes(element.elementId);
    for (let station of stationSet) {
      let obsByElements = dataArray.reduce<
        {
          sourceId: string;
          referenceTime: string;
          value: number;
        }[]
      >((obsList, obj) => {
        let dataMap = new Map();
        if (station !== obj.sourceId)
          // not current station
          return obsList;

        if (obj && obj.sourceId && obj.observations) {
          for (let i = 0; i < obj.observations.length; i++) {
            let obs = obj.observations[i];
            if (obs.level && !isValidLevel(obs.elementId, obs.level))
              // ignore if invalid level
              continue;
            if (
              obs.elementId === element.elementId &&
              (isValidOffset(obs.elementId, obs.timeOffset) || !hasMultipleElements(obs, obj.observations))
            ) {
              let duration = Moment.duration(obs.timeResolution);
              const key = duration.asSeconds();

              if (canHaveFalseNegative && obs.value < 0) {
                // if element has a false negative value we set it to 0.
                obs.value = 0;
              }
              if (dataMap.has(key)) {
                let values = dataMap.get(key);
                values.push({
                  sourceId: obj.sourceId,
                  referenceTime: obj.referenceTime,
                  value: obs.value,
                });
                dataMap.set(key, values);
              } else {
                let values = [];
                values.push({
                  sourceId: obj.sourceId,
                  referenceTime: obj.referenceTime,
                  value: obs.value,
                });
                dataMap.set(key, values);
              }
            }
          }
        }

        if (isFinite(Math.min(...dataMap.keys()))) {
          obsList.push(...dataMap.get(Math.min(...dataMap.keys())));
        }
        return obsList;
      }, []);
      let series: [number, number][] = [];
      for (let obs of obsByElements) {
        series.push([new Date(obs.referenceTime).getTime(), obs.value]);
      }
      let plotType = getPlotTypeByElement(element);
      let inNavigator = plotType === 'line';

      let stationInSeries = false;
      let stationId = station.split(':')[0];
      let stationName: string = capitalizeAllWords(selectedStations.get(stationId));
      for (let series in sList) {
        if (Object.values(sList[series]).indexOf(stationName) > -1) {
          stationInSeries = true;
          break;
        }
      }

      //sets the color for the station - this is needed because some split API calls do not containing all stations.
      if (!observationHandlerProps.currentStations.includes(stationName)) {
        observationHandlerProps.currentStations.push(stationName);
      }

      let elem: SeriesOptions = {
        id: stationId,
        name: stationName,
        type: plotType,
        showInNavigator: inNavigator,
        yAxis: yAxisCount,
        tooltip: { valueDecimals: 1 },
        data: series,
        color: observationHandlerProps.colors[observationHandlerProps.currentStations.indexOf(stationName)],
        elementId: element.elementId,
        showInChart: element.unit?.toLowerCase() !== 'date',
      };
      if (stationInSeries) elem.linkedTo = stationId;
      sList.push(elem);
    }
    yAxisCount++;
  }
  return sList;
};

const isValidLevel = (elementId: string, level: { value: number }) => {
  if (level.value) {
    for (let elem in observationHandlerProps.preferredLevel) {
      if (elementId.includes(observationHandlerProps.preferredLevel[elem].id)) {
        return observationHandlerProps.preferredLevel[elem].value === level.value;
      }
    }
  }
  return true;
};

const isValidOffset = (elementId: string, timeOffset: string) => {
  for (let elem in observationHandlerProps.preferredTimeOffsets) {
    if (elementId.includes(observationHandlerProps.preferredTimeOffsets[elem].id)) {
      return observationHandlerProps.preferredTimeOffsets[elem].offset === timeOffset;
    }
  }
  return timeOffset === 'PT0H';
};

const getPlotTypeByElement = (element: Element): 'column' | 'line' => {
  if (
    element.elementId.includes('precipitation') ||
    observationHandlerProps.columnPlotUnits.includes(element.unit ?? '')
  ) {
    return 'column';
  }
  return 'line';
};

export const responseHasValidData = (response: any) => {
  return (
    response !== null &&
    response.status !== 412 &&
    response.status !== 404 &&
    response.data !== null &&
    response.data.data !== null &&
    typeof response.data.error === 'undefined'
  );
};

export default {
  convertObservationData(
    responseData: any,
    selectedWeatherElements: SelectedWeatherElements,
    selectedStations: SelectedStations,
  ): Highcharts.Options {
    observationHandlerProps.currentStations = [];
    let elements = getObservationDataElementIds(responseData);
    let stationSet = getObservationDataStationIds(responseData);
    let yAxis = getPlotElementList(elements, selectedWeatherElements);
    let series = getPlotSeriesList(elements, stationSet, responseData, selectedStations);
    return {
      yAxis,
      series,
    };
  },
};

import React, { useState } from 'react';
import {
  Box,
  createStyles,
  Drawer,
  Grid,
  IconButton,
  Link as MuiLink,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import '@fontsource/roboto';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuDrawer: {
      width: '100vw',
      maxWidth: 350,
    },
    menuItems: {
      fontFamily: 'Roboto',
      fontSize: '1.2em',
      textTransform: 'uppercase',
      marginBottom: 15,
    },
    menuSection: {
      '& + &': {
        marginTop: 24,
      },
      padding: 8,
    },
    menuIcon: {
      marginLeft: 12,
    },
    localeButton: {
      minWidth: '2em',
      paddingRight: 0,
      paddingLeft: 0,
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

interface Props {
  kssBaseUrl: string;
  currentLocale: string;
  localeSelected: () => void;
}

export const MobileMenu: React.FC<Props> = ({ kssBaseUrl, currentLocale, localeSelected }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <IconButton
        className={classes.menuIcon}
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} onClose={toggleOpen} anchor="right">
        <Box className={classes.menuDrawer}>
          <Grid
            container
            justifyContent="space-between"
            className={classes.menuSection}
            style={{ paddingLeft: 25, marginRight: 16 }}
          >
            <Button className={classes.localeButton} color="inherit" onClick={localeSelected}>
              <Typography style={currentLocale.includes('nb') ? { fontWeight: 600 } : {}}>{'Norsk'}</Typography>
              <Typography>/</Typography>
              <Typography style={currentLocale.includes('en') ? { fontWeight: 600 } : {}}>{'English'}</Typography>
            </Button>
            <IconButton color="primary" aria-label="close drawer" edge="start" onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Box className={classes.menuSection}>
            <ListItem>
              <Typography className={classes.menuItems}>
                <Link className={classes.link} to={'/observations/'}>
                  <FormattedMessage id="menu_link_observation" />
                </Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className={classes.menuItems}>
                <Link className={classes.link} to={'/stations/'}>
                  <FormattedMessage id="menu_link_station" />
                </Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className={classes.menuItems}>
                <Link className={classes.link} to={'/windrose/'}>
                  <FormattedMessage id="wind_rose_menu_label" />
                </Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className={classes.menuItems}>
                <MuiLink href={kssBaseUrl + '/ivf?locale=nb'}>
                  <FormattedMessage id="menu_link_ivf" />
                </MuiLink>
              </Typography>
            </ListItem>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

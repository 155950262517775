import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import Highcharts from 'highcharts/highstock';
import BrokenAxis from 'highcharts/modules/broken-axis';
import HighchartsReact from 'highcharts-react-official';
import TabContainer from '../TabContainer';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import tz from 'moment-timezone';
import TableComponent from '../TableComponent/TableComponent';
import HighchartsExport from 'highcharts/modules/exporting';
import timeHandler from './helper/timeHandler';
import Button from '@material-ui/core/Button';
import ItemDialog from '../TableComponent/ItemDialog';
import tableHandler from './helper/tableHandler';
import ShareDialogComponent from '../ShareDialog/ShareDialogComponent';
import { ReactComponent as ShareIcon } from '../../images/shareIcon.svg';
import { ReactComponent as DownloadIcon } from '../../images/downloadIcon.svg';
import { getSorting, stableSort } from 'utils/SortUtils';
import '@fontsource/roboto';
import { cloneDeep } from 'lodash';
import { SeriesOptions, YAxisOptions } from './helper/observationHandler';

window.moment = moment;
tz();
BrokenAxis(Highcharts);
const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up('xs')]: {
    gridContainer: {
      marginBottom: '2em',
    },
    hideGridContainer: {
      display: 'none',
    },
  },
  panelIcons: {
    width: 20,
    height: 'auto',
    paddingRight: theme.spacing(),
    display: 'inline-block',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular as any,
    marginRight: theme.spacing(4),
  },
  tabSelected: {
    border: '1px solid #979797',
    backgroundColor: 'white',
  },
  tabWrapper: {
    borderTop: '1px solid #979797',
    borderBottom: '1px solid #979797',
    marginTop: '-26px',
    backgroundColor: '#F3F9F5',
  },
}));

const langs = {
  en: {
    noData: 'There are no observations for the selected stations and weather elements.',
    loading: 'Loading...',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    decimalPoint: '.',
    numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'],
    resetZoom: 'Reset zoom',
    resetZoomTitle: 'Reset zoom level 1:1',
    thousandsSep: ' ',
    viewFullscreen: 'View in full screen',
    printChart: 'Print chart',
    downloadJPEG: 'Download JPEG image',
    downloadPDF: 'Download PDF document',
    downloadPNG: 'Download PNG image',
    downloadSVG: 'Download SVG vector image',
  },
  nb: {
    decimalPoint: ',',
    loading: 'Laster...',
    months: [
      'Januar',
      'Februar',
      'Mars',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
    noData: 'Det er ingen observasjoner for valgte stasjoner og værelement.',
    numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'],
    resetZoom: 'Nullstill zoom',
    resetZoomTitle: 'Nullstill zoomnivå 1:1',
    shortMonths: ['jan.', 'feb.', 'mar.', 'apr.', 'mai', 'jun.', 'jul.', 'aug.', 'sep.', 'okt.', 'nov.', 'des.'],
    shortWeekdays: undefined,
    thousandsSep: ' ',
    weekdays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    printChart: 'Skriv ut diagram',
    downloadJPEG: 'Last ned JPEG bilde',
    downloadPDF: 'Last ned PDF dokument',
    downloadPNG: 'Last ned PNG bilde',
    downloadSVG: 'Last ned SVG vektorbilde',
    viewFullscreen: 'Se i fullskjerm',
  },
};

HighchartsExport(Highcharts);

const ObservationPanel: FC<{
  displayObservationPanel: boolean;
  observationData: Highcharts.Options;
  elementMap: any;
  selectedResolution: string;
  shareUrl: () => void;
  selectedPeriod: string;
}> = ({ displayObservationPanel, elementMap, observationData, selectedPeriod, selectedResolution, shareUrl }) => {
  const [value, setValue] = useState(0);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);

  const handleChange = (event: any, value: any) => {
    setValue(value);
  };

  const chartOptions = (originalData: Highcharts.Options): Highcharts.Options => {
    const data = cloneDeep(originalData);
    const yAxis = data.yAxis as Highcharts.YAxisOptions[];
    let chartHeight = yAxis.length > 1 ? 200 * yAxis.length : 400;
    data.rangeSelector = {
      selected: 1,
      enabled: false,
    };
    data.title = {
      text: '',
    };

    data.xAxis = {
      ordinal: selectedPeriod === 'recurring_period',
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    };
    // Frost uses UTC 00:00 for date values higher than hours, so we cant use localtime.
    data.time = {
      timezone:
        selectedResolution === timeHandler.timeResolution.HOURS ||
        selectedResolution === timeHandler.timeResolution.MINUTES
          ? 'Europe/Oslo'
          : 'UTC',
    };
    data.chart = {
      style: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        color: '#42683A',
      },
      height: chartHeight,
      marginLeft: 60,
    };
    data.legend = {
      enabled: true,
    };
    data.credits = {
      enabled: false,
    };
    data.tooltip = {
      shared: true,
      split: false,
    };

    data.plotOptions = {
      column: {
        dataGrouping: {
          enabled: false,
        },
      },
      line: {
        dataGrouping: {
          enabled: false,
        },
        gapSize: 2,
      },
      series: {
        marker: {
          enabled: undefined,
          enabledThreshold: 1,
          radius: 2,
          symbol: 'circle',
        },
        states: {
          hover: {
            lineWidthPlus: 0,
          },
        },
      },
    };
    const series = data.series?.filter((serie) => {
      const serie2 = serie as SeriesOptions;

      return serie2.showInChart;
    });
    const yAxisToShow = (data.yAxis as YAxisOptions[]).filter((axis) => {
      return axis.showInChart;
    });
    return { ...data, series, yAxis: yAxisToShow };
  };

  const closeDownloadDialog = () => {
    setShowDownloadDialog(false);
  };

  const closeShareDialog = () => {
    setShowShareDialog(false);
  };

  const classes = useStyles();
  const intl = useIntl();

  const TableComponentFixed: any = TableComponent;
  const ItemDialogFixed: any = ItemDialog;
  const ShareDialogComponentFixed: any = ShareDialogComponent;

  if (displayObservationPanel) {
    const data = cloneDeep(observationData);
    let rows = tableHandler.getConvertedObsData(observationData, elementMap);
    const lang = langs[intl.locale as keyof typeof langs];
    Highcharts.setOptions({ lang });
    return (
      <Grid container alignItems={'center'} alignContent={'center'} className={classes.gridContainer}>
        <FormControl fullWidth={true}>
          <Tabs
            className={classes.tabRoot}
            value={value}
            onChange={handleChange}
            centered
            indicatorColor={'primary'}
            textColor={'primary'}
          >
            <Tab label={intl.formatMessage({ id: 'graph' })} className={classes.tabSelected} disableRipple />
            <Tab label={intl.formatMessage({ id: 'table' })} className={classes.tabSelected} disableRipple />
          </Tabs>
          <div className={classes.tabWrapper}>
            {value === 0 && (
              <TabContainer>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={chartOptions(data)}
                  immutable={true}
                />
                <Button style={{ margin: '12px' }} onClick={() => setShowDownloadDialog(true)}>
                  <DownloadIcon className={classes.panelIcons}></DownloadIcon>
                  <Typography style={{ textTransform: 'none' }}>
                    {intl.formatMessage({ id: 'download_table' })}
                  </Typography>
                </Button>
                <Button style={{ margin: '12px' }} onClick={() => setShowShareDialog(true)}>
                  <ShareIcon className={classes.panelIcons}></ShareIcon>
                  <Typography style={{ textTransform: 'none' }}>{intl.formatMessage({ id: 'share' })}</Typography>
                </Button>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <TableComponentFixed
                  rows={stableSort(rows, getSorting('asc', 'time'))}
                  observationData={observationData}
                  elementMap={elementMap}
                  selectedResolution={selectedResolution}
                  shareUrl={shareUrl}
                />
              </TabContainer>
            )}
            <ItemDialogFixed
              open={showDownloadDialog}
              items={['csv', 'ods', 'xlsx']}
              onClose={closeDownloadDialog}
              data={rows}
              resolution={selectedResolution}
            />
            <ShareDialogComponentFixed open={showShareDialog} onClose={closeShareDialog} shareUrl={shareUrl} />
          </div>
        </FormControl>
        <Typography>
          <FormattedMessage id="met_data_licence_pt1" />
          {moment().format('DD.MM.YYYY')}
          <FormattedMessage id="met_data_licence_pt2" />
        </Typography>
      </Grid>
    );
  } else {
    return null;
  }
};

export default ObservationPanel;

import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { capitalizeStationName } from '../../utils/stringUtils';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  categoryItem: {
    borderTop: '1px solid #ddd',
    borderLeft: '1px solid #ddd',
    borderRight: '1px solid #ddd',
    backgroundColor: 'rgba(77, 163, 106, 0.1)',
  },
  stationItem: {
    padding: 0,
    borderTop: '1px solid #ddd',
    borderLeft: '1px solid #ddd',
    borderRight: '1px solid #ddd',
  },

  listRoot: {
    padding: '2em',
    overflow: 'scroll',
  },

  clearIcon: {
    float: 'right',
    minWidth: '32px',
  },
  dialogRoot: {
    margin: '24px',
  },
  dialogTitle: {
    display: 'inline-block',
  },

  titleRoot: {
    borderBottom: '1px solid #ddd',
  },
});

export interface Station {
  stationId: string;
  name: string;
  municipality?: string;
  masl?: number;
}

type County = {
  stations: Station[];
  id: string;
  isRegion: boolean;
  isOthers: boolean;
};

export interface Counties {
  [countyName: string]: County;
}

export type SelectedStations = Map<string, string>;

const CountyStationList: FC<{
  showCountyList: boolean;
  countyList: Counties;
  toggleCountyList: () => void;
  selectedStations: SelectedStations;
  setSelectedStations: (newSelectedStations: SelectedStations) => void;
  maxStations: number;
}> = ({ countyList, maxStations, selectedStations, setSelectedStations, showCountyList, toggleCountyList }) => {
  const classes = useStyles();

  if (showCountyList) {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={showCountyList}
        onClose={toggleCountyList}
        PaperProps={{ className: classes.dialogRoot }}
      >
        <DialogTitle id="customized-dialog-title" className={classes.titleRoot}>
          <FormattedMessage id="select_station_from_list" />
          <Button className={classes.clearIcon} onClick={toggleCountyList}>
            <ClearIcon fontSize={'small'} />
          </Button>
        </DialogTitle>
        <List className={classes.listRoot}>
          <Countiess
            countyList={countyList}
            maxStations={maxStations}
            selectedStations={selectedStations}
            setSelectedStations={setSelectedStations}
          />
        </List>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default CountyStationList;

type OrderedCountyList = [string, County][];

const sortCountyStationList = (unorderedCountyList: Counties) => {
  const countyListArray = Object.entries(unorderedCountyList);
  const withSortedStations: OrderedCountyList = countyListArray.map(([countyName, county]) => {
    county.stations.sort((a, b) => a.name.localeCompare(b.name));
    return [countyName, county];
  });

  const onlyCounties = withSortedStations.filter(([, list]) => {
    const isOtherOrRegion = list.isOthers || list.isRegion;
    return !isOtherOrRegion;
  });

  onlyCounties.sort(([a], [b]) => {
    return a.localeCompare(b);
  });

  const region = withSortedStations.find(([, list]) => list.isRegion);
  const others = withSortedStations.find(([, list]) => list.isOthers);
  region && onlyCounties.push(region);
  others && onlyCounties.push(others);

  return onlyCounties;
};

const Countiess: FC<{
  countyList: Counties;
  selectedStations: SelectedStations;
  maxStations: number;
  setSelectedStations: (newSelectedStations: SelectedStations) => void;
}> = ({ countyList, maxStations, selectedStations, setSelectedStations }) => {
  const classes = useStyles();

  const [collapsedCounties, setCollapsedCounties] = useState<{ [countyName: string]: boolean }>({});

  const toggleCollapse = (countyName: string) => {
    const collapsedCountiesNew = { ...collapsedCounties, [countyName]: !collapsedCounties[countyName] };
    setCollapsedCounties(collapsedCountiesNew);
  };

  const toggleSelectedStation = (station: Station) => {
    let newSelectedStations = new Map(selectedStations);
    if (!newSelectedStations.delete(station.stationId)) {
      newSelectedStations.set(station.stationId, station.name);
    }
    setSelectedStations(newSelectedStations);
  };

  let orderedCountyList = sortCountyStationList(countyList);
  return (
    <>
      {[
        orderedCountyList.map(([countyName, county]) => {
          const countyIsClosed = collapsedCounties[countyName];
          return (
            <div key={'container_' + countyName}>
              <ListItem
                button
                onClick={() => toggleCollapse(countyName)}
                key={'item_' + countyName}
                className={classes.categoryItem}
              >
                <ListItemText>{countyName.toUpperCase()}</ListItemText>
                {countyIsClosed ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={countyIsClosed} timeout="auto" unmountOnExit>
                <List component="div" disablePadding key={'county_rows_take_me_home'} style={{ overflow: 'scroll' }}>
                  <Stations
                    stations={county.stations}
                    toggleSelectedStation={toggleSelectedStation}
                    maxStations={maxStations}
                    selectedStations={selectedStations}
                  />
                </List>
              </Collapse>
            </div>
          );
        }),
      ]}
    </>
  );
};

const Stations: FC<{
  stations: Station[];
  selectedStations: SelectedStations;
  maxStations: number;
  toggleSelectedStation: (station: Station) => void;
}> = ({ stations, toggleSelectedStation, maxStations, selectedStations }) => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <>
      {stations.map((station) => {
        const masl = station.masl ? `, ${intl.formatMessage({ id: 'masl' })}: ${station.masl}` : '';
        const name = capitalizeStationName(station.name) + ',';
        const municipality = station.municipality;
        const stationId = '(' + station.stationId + ')  ';

        const stationIsSelected = selectedStations.has(station.stationId);
        const maxNumberOfStationsIsSelected = selectedStations.size === maxStations;
        return (
          <ListItem
            button
            key={station.stationId}
            className={classes.stationItem}
            onClick={() => toggleSelectedStation(station)}
            disabled={maxNumberOfStationsIsSelected && !stationIsSelected}
          >
            <Checkbox checked={stationIsSelected} />
            <Typography display="inline">
              {name} <em>{municipality}</em> {stationId} {masl}
            </Typography>
          </ListItem>
        );
      })}
    </>
  );
};

export function captitalizeWord(text) {
  if (typeof text !== 'string') return '';
  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function capitalizeAllWords(text) {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

export function capitalizeAllWordsExcept(text, exception) {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => {
      return s.toLowerCase() === exception ? s : s.charAt(0).toUpperCase() + s.substring(1);
    })
    .join(' ');
}

export function capitalizeStationName(text) {
  let name = capitalizeAllWords(text);
  name = name.replace(' Iiii', ' IIII');
  name = name.replace(' Iii', ' III');
  name = name.replace(' Ii', ' II');
  name = name.replace(' Viii', ' VIII');
  name = name.replace(' Vii', ' VII');
  name = name.replace(' Vi', ' VI');
  name = name.replace(' Iv', ' IV');
  name = name.replace('Rv', 'RV');
  name = name.replace('Fv', 'FV');
  return name;
}

/* eslint-disable import/no-anonymous-default-export */
export default {
  en: {
    observations_and_weather_statistics: 'Observations and weather statistics',
    station_information: 'Station information',
    station_information_description:
      'On this page, you may access information about all the weather stations available in Norway. You may for example see the stations’ locations and in which periods they measure(d) various weather elements. \n\n If there are both automatic and manual observations at the same time, the automatic observations are displayed on Seklima.\n\n' +
      'Information about the measurement periods:\n\n' +
      'Exposure category provides the quality of the location of a station.\n' +
      "1 = Fulfills all WMO's requirements on location.\n" +
      '2 = Unknown location, assumed good.\n' +
      '4 = Unknown location, assumed bad.\n' +
      '5 = Bad location.\n\n' +
      'Performance category provides the quality of the measurement accuracy, calibration and maintenance.\n' +
      'A = The sensor type fulfills the requirements from WMO/CIMO on measurement accuracy, calibration and maintenance.\n' +
      'B = Routines for calibration and maintenance are known. Control of the montage exists. The precision of the measurement is lower than the WMO/CIMO requirements.\n' +
      'C = The sensor type is assumed to fulfill the WMO/CIMO requirements. Missing measurement for control, routines for calibration, or maintenance.\n' +
      'D = The sensor type is assumed to fulfill the WMO/CIMO requirements. Some controls show deviations from the WMO/CIMO requirements.\n' +
      'E = Unknown performance. Less valuable. Possibly useful for extraordinary purposes.',
    time_resolution: 'Time resolution',
    weather_elements: 'Weather element',
    select_weather_elements_most_used: 'Select up to 5 weather elements (most used)',
    select_weather_elements: 'Select up to 5 weather elements',
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    months: 'Months',
    seasons: 'Seasons',
    years: 'Years',
    searchStations: 'Search for station, or select place in list or map',
    period: 'Period',
    last_hour: 'Last hour',
    last_24_hours: 'Last 24 hours',
    last_7_days: 'Last 7 days',
    last_30_days: 'Last 30 days',
    last_6_months: 'Last 6 months',
    last_12_months: 'Last 12 months',
    custom_period: 'Custom period',
    recurring_period: 'Recurring period',
    last_5_years: 'Last 5 years',
    last_10_years: 'Last 10 years',
    last_30_years: 'Last 30 years',
    all_years: 'All years',
    select_period: 'Select period',
    from: 'From',
    to: 'To',
    from_recurring: 'Start month and first year',
    to_recurring: 'End month and last year',
    date: 'Date',
    min_date_validation_msg: '«To date» can not be before «From date»',
    max_date_validation_msg: 'Date can not be set to a future date',
    invalid_date_format: 'Invalid date format',
    max_stations_selected: 'Maximum number of stations selected',
    time_resolution_alert:
      'If you change time resolution now, your chosen weather elements, period and stations will reset.',
    time_resolution_alert_title: 'Change time resolution?',
    time_resolution_alert_cancel: 'Cancel',
    time_resolution_alert_accept: 'OK',
    recurring_time_period_start: 'Start date and first year',
    recurring_time_period_end: 'End date and last year',
    graph: 'GRAPH',
    table: 'TABLE',
    show_result_button: 'SHOW RESULT',
    subject: 'Subject',
    name: 'Name',
    email: 'Email',
    organization: 'Organization',
    mobile: 'Phone number',
    contact: 'Contact',
    message: 'Message',
    attachment: 'Attachment',
    UPDATE_NEED: 'Press «SHOW RESULT» to update the graph.',
    NO_DATA: 'No data found for this combination of weather element(s), station(s) and time period.',
    VALID_DATA: '',
    FETCHING_DATA: 'Fetching observations, please wait ...',
    send_email: 'Send email',
    upload_file: 'Upload file (Max 5MB)',
    invalid_file_size: 'Invalid file size',
    invalid_file_type: 'Invalid file type. Use only png and jpg.',
    email_successfully_sent_header: 'Thank you!',
    email_successfully_sent_message: 'Your message has been successfully sent.',
    email_error_header: 'Something went wrong :(',
    email_error_message: 'It seems like we are having some technical difficulties, try again a bit later.',
    obs_count_warning_seconds: 'observations needs to be downloaded, this may take a minute.',
    obs_count_warning_minutes: 'observations needs to be downloaded, this may take a several minutes.',
    obs_count_warning_limit: 'observations needs to be downloaded, please limit your request.',
    privacy_policy: 'Privacy policy',
    contact_link: 'Contact',
    met_data_licence_pt1: 'Data valid for ',
    met_data_licence_pt2: ' (CC BY 4.0), The Norwegian Meteorological Institute (MET Norway)',
    map_search: 'Search by name of place, station, station number',
    search_placeholder: 'Name of place or station.',
    search_subtitle: 'Measuring station, masl: ',
    stations: 'stations',
    masl: 'masl.',
    select_in_map: 'Select stations in map',
    station_nr: 'Stno',
    rows_per_page: 'Rows per page:',
    of: 'of',
    stations_selected: '{x} of maximum {y} stations selected.',
    others: 'Others',
    regions: 'Regions',
    select_station_from_list: 'Select stations',
    select__one_station_from_list: 'Select one station',
    map_help_text: "Change the map section using '+' and '-' buttons, or click and drag the map.",
    map_attribution_nma: 'Background map from the Norwegian Mapping Authority.',
    about_map: 'About the map',
    location_search: 'Location name search',
    select_in_list: 'Select station in list',
    date_season: 'From month',
    station: 'Station',
    multiple_stations: 'Multiple stations',
    selected_station: 'Selected station',
    one_or_more_selected: 'One or more selected',
    symbols: 'Symbols',
    station_name: 'Station name',
    station_name_or_number: 'Station name or number',
    download_table: 'Download table',
    info_text_header:
      'In this page, you may access observations/measurements from all the weather stations we have available in Norway. You may also find weather statistics like mean values and deviations from the normal. Start by doing some simple selections and then press “Show results”.\n\n' +
      'Which stations that are available in search, list, and map depends on your selections in the menus for time resolution, weather element, and period.\n\n' +
      'See <link>Region values</link> for description of the regions (Norwegian only).',
    info_text_time_resolution:
      'Here, you may change the time resolution of the observations.\n\n Observations of the weather may be done once or several times per day, each hour and/or with only minute intervals. Averages and other statistics are also computed over various time periods, such as months, seasons and years.\n\n Example: for the weather element "mean temperature" you may choose between the time resolutions daily, monthly, seasonal and annual.',
    download_format: 'Choose file format',
    date_hours_minutes: 'Date (local time)',
    seklima: 'Seklima',
    select_all_elements: 'Select from all weather elements',
    share: 'Share',
    copy_to_clipboard: 'Copy to clipboard',
    operational_stations: 'Operative stations',
    station_number: 'Station number (id)',
    height_above_sea_level: 'Height above mean sea level',
    latitude: 'Latitude',
    longitude: 'Longitude',
    latitude_mesurement: 'N',
    longitude_mesurement: 'E',
    wmo_number: 'WMO number',
    wigos_number: 'WIGOS number',
    station_holder: 'Station holder',
    county: 'County',
    municipality: 'Municipality',
    operating_period: 'Operating period',
    geo_location_missing: 'Geographic location is unknown',
    now: 'now',
    unknown: 'unknown',
    select_and_close: 'SELECT AND CLOSE',
    fetch_error_alert: 'Unable to get observations. ',
    fetch_windrose_alert: 'Unable to get data for selected wind rose.',
    service_too_busy: 'The service is very busy at the moment, try again later.',
    stations_not_operative: 'Stations not operative for the selected period:',
    measurement_periods: 'Measurement periods',
    measurement_periods_for: 'Measurement periods for ',
    menu_link_observation: 'Observations and weather statistics',
    menu_link_station: 'Station information',
    menu_link_ivf: 'Precipitation intensity (IDF values)',
    custom_date_info: 'No station information available earlier than 1762',
    category: 'Category',
    weather_element: 'Weather element',
    from_date: 'From date',
    to_date: 'To date',
    performace_category: 'Performance category',
    exposure_category: 'Exposure category',
    one_hour: '1h',
    three_hours: '3h',
    six_hours: '6h',
    twelve_hours: '12h',
    one_day: '24h',
    one_month: 'month',
    three_months: '3 months',
    six_months: '6 months',
    one_year: 'year',
    operative: 'operative',
    region_information: 'Regional values',
    region_information_description:
      'Find regional values for Norway of temperature and precipitation by month, season and year since 1900. The values are based on grids that cover the entire country by 1 * 1 km^2^ resolution.',
    logoKss: '/kss_logo_en.svg',
    wind_rose: 'Wind rose with frequency distribution',
    wind_rose_menu_label: 'Wind rose',
    wind_rose_info:
      'On this page, you get wind rose with frequency distribution based on  observations of wind direction and mean wind speed from weather stations in Norway. You can select one station at a time.',
    too_much_data:
      'Seklima cannot retrieve data for such a long time period for this time resolution. Please use frost.met.no to retrieve large amount of data.',
    wind_rose_graph_title: 'Wind Rose for {sName} ({sNumber}) in period; {from}-{to}.',
    wind_rose_graph_title_recurring: 'Wind Rose for {sName} ({sNumber}) in period; {from}-{to}. Months: {months}',
    wind_rose_graph_subtitle: 'Calm (0.0-0.2 m/s) = {calm} %',
    wind_rose_table_title: 'Mean wind speed and wind directon for {sName} ({sNumber}) in period {date}. (%)',
    wind_rose_table_title_recurring:
      'Mean wind speed and wind directon for {sName} ({sNumber}) in period {date}. (%) Months: {months}',
    wind_speed: 'Wind speed(m/s)',
    label_rows_per_page: 'Rows per page',
    share_button: 'URL copied to clipboard.',
    windrose_graph_infobox:
      'Frequency distribution of wind direction and mean wind speed. Wind direction is divided into 16 sectors of 22.5 degrees each. The sectors represent the incoming direction of the wind, i.e. the northern sector represents wind coming from the north and heading south. The sector for 348.75–11.25 degrees represents wind from the north, and 78.75–101.25 degrees represents wind from the east. The wind speed is color coded and categorized by the Beaufort scale. The concentric circles indicate the percentage share of the data coming from the different directions.',
    windrose_table_infobox:
      'The table shows the relative frequency (%) of observations for wind direction (horizontal) and mean wind speed (vertical). Wind direction is divided into sectors of 22.5 degrees, where e.g. 348.75-11.25 degrees is wind from the north, and 78.75 - 101.25 degrees is wind from the east. The wind speed is divided according to the Beaufort scale, e.g. 3.4 - 5.4 m/s is “gentle breeze”',
    jump_to_main_content: 'Jump to main content',
  },
  nb: {
    observations_and_weather_statistics: 'Observasjoner og værstatistikk',
    station_information: 'Stasjonsinformasjon',
    station_information_description:
      'På denne siden får du informasjon om alle værstasjonene vi har tilgjengelig i hele Norge. Du kan for eksempel se stasjoners plassering og hvilke perioder de har målt ulike værelementer. \n\n Hvis det foreligger både automatiske og manuelle observasjoner, brukes de automatiske observasjonene på Seklima.\n\n' +
      'Forklaring til måleperiodene:\n\n' +
      'Eksponeringsklasse angir kvaliteten på stasjonens plassering for måling av værelementet.\n\n' +
      '1 = Tilfredstiller alle WMOs krav til plassering.\n' +
      '2 = Ukjent plasseringskvalitet, antatt god.\n' +
      '4 = Ukjent plasseringskvalitet, antatt dårlig.\n' +
      '5 = Dårlig plassering.\n\n' +
      'Ytelsesklasse angir sensorens kvalitet mht. målenøyaktighet, kalibrering og vedlikehold.\n' +
      'A = Sensortypen oppfyller kravene fra WMO/CIMO til målenøyaktighet, kalibrering og vedlikehold.\n' +
      'B = Rutiner for kalibrering og vedlikehold er kjent. Kontroll av montasjen eksisterer. Presisjonen til målingen er lavere enn kravene fra WMO/CIMO.\n' +
      'C = Sensortypen antas å oppfylle kravene fra WMO/CIMO. Manglende konrollmåling, rutiner for kalibrering eller vedlikehold.\n' +
      'D = Sensortypen antas å oppfylle kravene fra WMO/CIMO. Noen kontroller viser avvik fra WMO-/CIMO-kravene. \n' +
      'E = Ukjent ytelse. Mindre verdifullt. Muligens nyttig for ekstraordinære formål.',
    time_resolution: 'Tidsoppløsning',
    weather_elements: 'Værelementer',
    select_weather_elements: 'Velg opptil 5 værelementer',
    select_weather_elements_most_used: 'Velg opptil 5 værelementer (mest brukte)',
    minutes: 'Minutter',
    hours: 'Timer',
    days: 'Døgn',
    months: 'Måneder',
    seasons: 'Sesonger',
    years: 'År',
    searchStations: 'Søk på stasjon, eller velg sted i liste eller kart',
    period: 'Tidsrom',
    last_hour: 'Siste time',
    last_24_hours: 'Siste 24 timer',
    last_7_days: 'Siste 7 dager',
    last_30_days: 'Siste 30 dager',
    last_6_months: 'Siste 6 måneder',
    last_12_months: 'Siste år',
    custom_period: 'Valgfri periode',
    recurring_period: 'Samme periode over flere år',
    last_5_years: 'Siste 5 år',
    last_10_years: 'Siste 10 år',
    last_30_years: 'Siste 30 år',
    all_years: 'Alle år',
    select_period: 'Velg tidsrom',
    from: 'Fra',
    to: 'Til',
    from_recurring: 'Startmåned og første år',
    to_recurring: 'Sluttmåned og siste år',
    date: 'Dato',
    min_date_validation_msg: '«Til dato» kan ikke være før «Fra dato»',
    max_date_validation_msg: 'Valgt dato kan ikke være satt frem i tid',
    invalid_date_format: 'Feil dato format',
    max_stations_selected: 'Høyest antall værstasjoner valgt',
    time_resolution_alert: 'Hvis du skifter tidsoppløsning så vil valgte værelementer, tidsrom og stasjoner fjernes.',
    time_resolution_alert_title: 'Endre tidsoppløsning?',
    time_resolution_alert_cancel: 'Avbryt',
    time_resolution_alert_accept: 'OK',
    recurring_time_period_start: 'Startdato og første år',
    recurring_time_period_end: 'Sluttdato og siste år',
    graph: 'GRAF',
    table: 'TABELL',
    show_result_button: 'VIS RESULTAT',
    subject: 'Emne',
    name: 'Navn',
    email: 'E-Post',
    organization: 'Organisasjon',
    mobile: 'Mobil',
    contact: 'Kontakt',
    message: 'Melding',
    attachment: 'Vedlegg',
    UPDATE_NEED: 'Trykk «VIS RESULTAT» for å oppdatere grafen.',
    NO_DATA: 'Denne kombinasjonen av værelement(er), stasjon(er) og tidsrom har ingen data.',
    VALID_DATA: '',
    FETCHING_DATA: 'Henter observasjoner, vennligst vent ...',
    send_email: 'Send e-post',
    upload_file: 'Velg fil (max 5MB)',
    invalid_file_size: 'Feil filstørrelse',
    invalid_file_type: 'Ugyldig filtype. Bruk bare png og jpg.',
    email_successfully_sent_header: 'Takk for tilbakemeldingen.',
    email_successfully_sent_message: 'Din melding har blitt sendt.',
    email_error_header: 'Feil oppsto.',
    email_error_message: 'Vi klarer ikke kontakte serverene våre, prøv igjen litt senere.',
    obs_count_warning_seconds: 'observasjoner må lastes ned, dette kan ta litt tid.',
    obs_count_warning_minutes: 'observasjoner må lastes ned, dette kan ta mange minutter.',
    obs_count_warning_limit: 'observasjoner må lastes ned, vennligst begrens utplukket.',
    privacy_policy: 'Personvern',
    contact_link: 'Kontakt',
    met_data_licence_pt1: 'Data er gyldig per ',
    met_data_licence_pt2: ' (CC BY 4.0), Meteorologisk institutt (MET)',
    map_search: 'Søk sted, stasjonsnavn, stasjonsnummer',
    search_placeholder: 'Stedsnavn eller stasjonsnavn',
    search_subtitle: 'Målestasjon, hoh: ',
    stations: 'stasjoner',
    masl: 'moh.',
    select_in_map: 'Velg målestasjoner i kart',
    station_nr: 'Stnr',
    rows_per_page: 'Rader per side:',
    of: 'av',
    stations_selected: '{x} av maksimalt {y} stasjoner er valgt.',
    others: 'Øvrige',
    regions: 'Regioner',
    select_station_from_list: 'Velg målestasjoner',
    select__one_station_from_list: 'Velg en stasjon',
    map_help_text: "Endre kartutsnittet ved hjelp av knappene '+' og '-', eller klikk og dra i kartet.",
    map_attribution_nma: 'Bakgrunnskart fra Statens kartverk.',
    about_map: 'Om kartet',
    location_search: 'Stedsnavnsøk',
    select_in_list: 'Velg målestasjon i liste',
    date_season: 'Fra måned',
    station: 'Stasjon',
    multiple_stations: 'Flere stasjoner',
    selected_station: 'Valgt stasjon',
    one_or_more_selected: 'En eller flere valgte',
    symbols: 'Symbolforklaring',
    station_name: 'Stasjonsnavn',
    station_name_or_number: 'Stasjonsnavn eller nummer',
    download_table: 'Last ned tabell',
    info_text_header:
      'På denne siden får du tilgang til observasjoner/målinger fra alle værstasjonene vi har tilgjengelig i hele Norge. Du finner også værstatistikk som gjennomsnittsverdier og avvik fra normalen. Start med å gjøre noen enkle valg og trykk deretter på knappen “Vis resultat”.\n' +
      '\n' +
      'Hvilke stasjoner som er tilgjengelig i søk, liste og kart er avhengig av dine valg i menyene for tidsoppløsning, værelement og periode.\n\n' +
      'Se <link>Regionverdier</link> for beskrivelse av de ulike regionene.',
    info_text_time_resolution:
      'Her kan du endre hvilken tidsoppløsning du ønsker å se observasjonene i.\n\n Observasjoner av været kan bli gjort en eller flere ganger i døgnet, hver time og/eller med minutters mellomrom. Det blir også beregnet gjennomsnitt og annen statistikk over ulike tidsperioder, som måneder, sesonger og år.\n\n Eksempel: værelementet "gjennomsnittstemperatur" kan du velge mellom tidsoppløsningene døgn, måned, sesong og år.',
    download_format: 'Velg filformat',
    date_hours_minutes: 'Dato (lokaltid)',
    seklima: 'Seklima',
    select_all_elements: 'Velg fra alle værelementer',
    share: 'Del',
    copy_to_clipboard: 'Kopier link',
    operational_stations: 'Stasjoner i drift',
    station_number: 'Stasjonsnummer (id)',
    height_above_sea_level: 'Høyde over havet',
    latitude: 'Breddegrad',
    longitude: 'Lengdegrad',
    latitude_mesurement: 'N',
    longitude_mesurement: 'Ø',
    wmo_number: 'WMO-nummer',
    wigos_number: 'WIGOS-nummer',
    station_holder: 'Stasjonseier',
    county: 'Fylke',
    municipality: 'Kommune',
    operating_period: 'Driftsperiode',
    geo_location_missing: 'Geografisk lokasjon er ukjent',
    now: 'nå',
    unknown: 'ukjent',
    select_and_close: 'VELG OG LUKK',
    fetch_error_alert: 'Problemer ved henting av observasjoner. ',
    fetch_windrose_alert: 'Problemer ved henting av datagrunnlag for valgt vindrose.',
    service_too_busy: 'Tjenesten er veldig opptatt, prøv igjen senere. ',
    stations_not_operative: 'Ikke operative stasjoner for valgt periode:',
    measurement_periods: 'Måleperioder',
    measurement_periods_for: 'Måleperioder for ',
    menu_link_observation: 'Observasjoner og værstatistikk',
    menu_link_station: 'Stasjonsinformasjon',
    menu_link_ivf: 'Nedbørintensitet (IVF-verdier)',
    custom_date_info: 'Ingen stasjonsinformasjon tilgjengelig før 1762',
    category: 'Kategori',
    weather_element: 'Værelement',
    from_date: 'Fra dato',
    to_date: 'Til dato',
    performace_category: 'Sensorens ytelsesklasse',
    exposure_category: 'Sensorens eksponeringsklasse',
    one_hour: '1 t',
    three_hours: '3 t',
    six_hours: '6 t',
    twelve_hours: '12 t',
    one_day: 'døgn',
    one_month: 'mnd',
    three_months: '3 mnd',
    six_months: '6 mnd',
    one_year: 'år',
    operative: 'i drift',
    region_information: 'Regionverdier',
    region_information_description:
      'Finn regionverdier for Norge av temperatur og nedbør per måned, sesong og år siden 1900. Verdiene er basert på grid som dekker hele landet med 1*1 km^2^ oppløsning. ',
    logoKss: '/kss_logo_no.svg',
    wind_rose: 'Vindrose med frekvensfordeling',
    wind_rose_menu_label: 'Vindrose',
    wind_rose_info:
      'På denne siden får du vindrose med frekvensfordeling, basert på observasjoner av middelvindens fra-retning og styrke. Du kan velge én stasjon om gangen blant norske værstasjoner.',
    too_much_data:
      'Seklima kan ikke hente data for så lang periode med denne tidsoppløsningen. Vennligst benytt frost.met.no for å hente mye data.',
    wind_rose_graph_title: 'Vindrose for {sName} ({sNumber}) i perioden; {from}-{to}.',
    wind_rose_graph_title_recurring: 'Vindrose for {sName} ({sNumber}) i perioden; {from}-{to}. Mnd: {months}',
    wind_rose_graph_subtitle: 'Stille (0,0-0,2 m/s) = {calm} %',
    wind_rose_table_title: 'Middelvind og retningen vinden kommer fra for {sName} ({sNumber}) i perioden {date}. (%)',
    wind_rose_table_title_recurring:
      'Middelvind og retningen vinden kommer fra for {sName} ({sNumber}) i perioden {date}. (%) Mnd: {months}',
    wind_speed: 'Middelvind (m/s)',
    label_rows_per_page: 'Rader per side:',
    share_button: 'URL kopiert til utklippstavle.',
    windrose_graph_infobox:
      'Frekvensfordeling av vindretning og middelvind. Vindretning deles i 16 sektorer på 22,5 grader og kommer fra den retningen som sektoren peker. Det vil si at sektor oppover representerer vind fra nord. Sektoren 348,75–11,25 grader er fra nord, 78,75–101,25 grader er fra øst. Vindstyrken til middelvinden deles inn i fargekoder etter Beaufort-skalaen. %-sirklene representerer %-andel av dataene som kommer fra de ulike retningene.',
    windrose_table_infobox:
      'Tabellen viser relativ frekvens (%) av observasjoner for vindretning (horisontalt) og middelvind (vertikalt). Vindretning er inndelt i sektorer på 22,5 grader hvor f.eks. 348,75-11,25 grader er fra nord, 78,75 - 101,25 grader er fra øst. Middelvind er inndelt etter Beaufort-skalaen. F.eks. er 3,4 - 5,4 m/s lett bris.',
    jump_to_main_content: 'Hopp til hovedinnhold',
  },
};

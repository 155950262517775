import moment from 'moment';
import { Moment } from 'moment';
import { BodyCells } from './SortableTableTypes';

function descendingComparator<T>(
  a: T,
  b: T,
  order: Order,
  externalComparatorRule?: (a: T, b: T, order: Order) => number,
) {
  if (externalComparatorRule) return externalComparatorRule(a, b, order);
  // Handeling moment objects
  if (b instanceof moment && a instanceof moment) {
    let tmpB = b as unknown;
    let tmpA = a as unknown;
    if ((tmpB as Moment).isBefore(tmpA as Moment)) return -1;
    if ((tmpB as Moment).isAfter(tmpA as Moment)) return 1;
  } else {
    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator(
  order: Order,
  orderBy: keyof BodyCells,
  externalComparatorRule?: (a: string | number | Moment, b: string | number | Moment, order: Order) => number,
): (a: BodyCells, b: BodyCells) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a[orderBy].value, b[orderBy].value, order, externalComparatorRule)
    : (a, b) => -descendingComparator(a[orderBy].value, b[orderBy].value, order, externalComparatorRule);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export { getComparator, stableSort };
export type { Order };

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { ModeType } from '../../pages/ObservationPage';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    cover: {
      position: 'relative',
      width: '100%',
      zIndex: 50,
    },
    position: {
      zIndex: 51,
      textAlign: 'center',
      position: 'relative',
      top: '50%',
    },
  },
});

function CircularProgressBar(props) {
  const { classes } = props;
  return (
    <div className={classes.cover}>
      <div className={classes.position}>
        <CircularProgress color={'secondary'} />
        <Typography>
          <FormattedMessage id={ModeType.FETCHING_DATA} />
        </Typography>
      </div>
    </div>
  );
}

CircularProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CircularProgressBar);

import { WindrosePeriod, WindrosePeriodOptions } from '@Types/WindrosePeriod';
import { BodyCells, HeaderCells } from 'components/SorteableTable/SortableTableTypes';
import { IntlShape } from 'react-intl';
import { Windrose } from 'utils/WindroseUtils';

export const getHeaderCells = (intl: IntlShape) => {
  let headerCells: HeaderCells[] = [];
  headerCells.push({
    id: 'wind_speed',
    label: intl.formatMessage({ id: 'wind_speed' }),
  });
  if (intl.locale === 'en') {
    for (let direction of Windrose.enDescription.direction) {
      headerCells.push({
        id: direction,
        label: direction,
      });
    }
  } else {
    for (let direction of Windrose.nbDescription.direction) {
      headerCells.push({
        id: direction,
        label: direction,
      });
    }
  }
  headerCells.push({
    id: 'SUM',
    label: 'SUM',
  });
  return headerCells;
};

export const getTableCells = (intl: IntlShape, data: windrose.Data) => {
  let tableBody: BodyCells[] = [];
  const headerCell = getHeaderCells(intl);
  // Ugly stuff to make silent wind work.
  let silentWindRow: BodyCells = {};
  for (let j = 0; j < data.extras.length; j++) {
    if (data.extras[j].title === 'silentWind') {
      silentWindRow['wind_speed'] = {
        value: 0,
        id: '0',
        label: intl.locale === 'nb' ? '0,0-0,2' : '0.0-0.2',
      };
      for (let i = 0; i < data.table[0].length; i++) {
        silentWindRow[headerCell[i + 1].id] = {
          value: -1,
          id: '0',
          label: ' ',
        };
      }
      silentWindRow['SUM'] = {
        label:
          intl.locale === 'nb' ? data.extras[j].value.toString().replaceAll('.', ',') : data.extras[j].value.toString(),
        value: data.extras[j].value,
        id: data.extras[j].value.toString(),
      };
    }
  }
  tableBody.push(silentWindRow);

  for (let i = 0; i < data.table.length; i++) {
    let row: BodyCells = {};
    let value = data.verticalAxis.titles[i];
    // we want to sort the middlewind by the first digets, so we split the string and append the end as a suffix
    row['wind_speed'] = {
      value: value.includes('-') ? parseFloat(value.split('-')[0]) : parseFloat(value.substring(1)),
      id: value,
      label: intl.locale === 'nb' ? value.replaceAll('.', ',') : value,
    };
    for (let j = 0; j < data.table[i].length; j++) {
      row[headerCell[j + 1].id] = {
        value: data.table[i][j],
        id: data.table[i][j].toString(),
        label: intl.locale === 'nb' ? data.table[i][j].toString().replaceAll('.', ',') : data.table[i][j].toString(),
      };
    }
    row['SUM'] = {
      label:
        intl.locale === 'nb'
          ? data.verticalAxis.sums[i].toString().replaceAll('.', ',')
          : data.verticalAxis.sums[i].toString(),
      value: data.verticalAxis.sums[i],
      id: data.verticalAxis.sums[i].toString(),
    };
    tableBody.push(row);
  }
  let endRow: BodyCells = {};
  endRow['wind_speed'] = { value: 'SUM', id: '-' };
  for (let i = 0; i < data.horizontalAxis.sums.length; i++) {
    endRow[headerCell[i + 1].id] = {
      label:
        intl.locale === 'nb'
          ? data.horizontalAxis.sums[i].toString().replaceAll('.', ',')
          : data.horizontalAxis.sums[i].toString(),
      id: data.horizontalAxis.sums[i].toString(),
      value: '-',
    };
  }
  endRow[headerCell[data.horizontalAxis.sums.length + 1].id] = { value: 100, id: '-' };
  tableBody.push(endRow);
  return tableBody;
};

export const getTableTitle = (
  intl: IntlShape,
  data: windrose.Data,
  recurringMonths: number[],
  period: WindrosePeriod,
): string => {
  let fromDate = new Date(data.metadata.fromTime).toLocaleDateString('no-NO', { year: 'numeric', month: '2-digit' });
  let toDate = new Date(data.metadata.toTime).toLocaleDateString('no-NO', { year: 'numeric', month: '2-digit' });
  let date = fromDate + '-' + toDate;
  if (period.option === WindrosePeriodOptions.RECURRING_PERIOD) {
    return intl.formatMessage(
      { id: 'wind_rose_table_title_recurring' },
      { sName: data.stationName, sNumber: data.metadata.stationID, date: date, months: recurringMonths.toString() },
    );
  } else {
    return intl.formatMessage(
      { id: 'wind_rose_table_title' },
      { sName: data.stationName, sNumber: data.metadata.stationID, date: date },
    );
  }
};

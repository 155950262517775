import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { WindrosePeriod, WindrosePeriodOptions } from '@Types/WindrosePeriod';
import moment, { Moment } from 'moment';
import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SelectDate } from '../SelectDate';

interface WindrosePeriodSelectProps {
  setPeriodUri: (uri: WindrosePeriod) => void;
  selectedPeriod: WindrosePeriodOptions;
  setSelectedPeriod: Dispatch<SetStateAction<WindrosePeriodOptions>>;
  fromDate: Moment;
  toDate: Moment;
  setFromDate: Dispatch<SetStateAction<Moment>>;
  setToDate: Dispatch<SetStateAction<Moment>>;
}

export const WindrosePeriodSelect: React.FC<WindrosePeriodSelectProps> = ({
  setPeriodUri,
  selectedPeriod,
  setSelectedPeriod,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
}) => {
  const intl = useIntl();

  const handlePeriodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPeriod(event.target.value as WindrosePeriodOptions);
  };

  React.useEffect(() => {
    setPeriodUri({ option: selectedPeriod, from: fromDate.toDate(), to: toDate.toDate() });
  }, [selectedPeriod, fromDate, toDate, setPeriodUri]);

  return (
    <Grid container direction={'column'}>
      <Grid item xs={12} md={6}>
        <InputLabel htmlFor={'wr_p_select'} style={{ paddingBottom: 6 }}>
          <FormattedMessage id={'period'} />
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true} variant="outlined">
          <Select id={'wr_p_select'} value={selectedPeriod} onChange={handlePeriodChange}>
            <MenuItem value={WindrosePeriodOptions.LAST_10_YEARS}>
              {intl.formatMessage({ id: WindrosePeriodOptions.LAST_10_YEARS })}
            </MenuItem>
            <MenuItem value={WindrosePeriodOptions.LAST_30_YEARS}>
              {intl.formatMessage({ id: WindrosePeriodOptions.LAST_30_YEARS })}
            </MenuItem>
            <MenuItem value={WindrosePeriodOptions.CUSTOM_PERIOD}>
              {intl.formatMessage({ id: WindrosePeriodOptions.CUSTOM_PERIOD })}
            </MenuItem>
            <MenuItem value={WindrosePeriodOptions.RECURRING_PERIOD}>
              {intl.formatMessage({ id: WindrosePeriodOptions.RECURRING_PERIOD })}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {(selectedPeriod === WindrosePeriodOptions.CUSTOM_PERIOD ||
        selectedPeriod === WindrosePeriodOptions.RECURRING_PERIOD) && (
        <>
          <SelectDate
            selectedDate={fromDate}
            setDate={setFromDate}
            labelId={selectedPeriod === WindrosePeriodOptions.CUSTOM_PERIOD ? 'from' : 'from_recurring'}
            views={['year', 'month']}
            format={'MM/YYYY'}
            minDate={moment(new Date('1000-01-01'))}
          />
          <SelectDate
            selectedDate={toDate}
            setDate={setToDate}
            labelId={selectedPeriod === WindrosePeriodOptions.CUSTOM_PERIOD ? 'to' : 'to_recurring'}
            views={['year', 'month']}
            format={'MM/YYYY'}
            minDate={fromDate}
          />
        </>
      )}
    </Grid>
  );
};

import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

export const SeklimaTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14,
  },
}))(Tooltip);

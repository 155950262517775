import client from './client';
import { set as lodashSet } from 'lodash';

export const fetchConfig = (setConfig) => {
  client.get('/', encodeURI('frontend.properties')).then((response) => {
    if (response.data) {
      var result = response.data
        .split('\n')
        .filter(Boolean)
        .reduce((acc, line) => {
          lodashSet(acc, ...line.split('='));
          return acc;
        }, {});
      setConfig(result);
    }
    return null;
  });
};

const mandatoryConfigKeys = [
  'REACT_APP_GA_LISTENER',
  'REACT_APP_API_URL',
  'REACT_APP_SEKLIMA_URL',
  'REACT_APP_KSS_URL',
];

export const validateConfig = (config) => {
  let missingValues = [];
  if (!config) {
    return { valid: false, missing: missingValues };
  }
  mandatoryConfigKeys.forEach((mandatory) => {
    if (!Object.keys(config).includes(mandatory)) {
      missingValues.push(mandatory);
    }
  });
  return { valid: missingValues.length === 0, missing: missingValues };
};

import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ObservationSource } from '@Types/ObservationSource';
import { WindrosePeriodSelect } from 'components/Windrose/WindrosePeriodSelect';
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { buildAndSetCountyMap, stationsSearchMap } from 'utils/StationSearchUtils';
import { fetchStationNameSearch } from 'utils/stationsUtils';
import { PageHeader } from '../components/PageHeader';
import {
  WindrosePeriod,
  DefaultWindrosePeriod,
  WindrosePeriodOptions,
  DefaultWindrosePeriodOption,
} from '@Types/WindrosePeriod';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchWindroseValues } from 'utils/WindroseUtils';
import { AlertTile } from 'components/Alert/AlertTile';
import { WindroseDataPanel } from 'components/Windrose/WindroseDataPanel';
import { capitalizeStationName } from 'utils/stringUtils';
import CircularProgressBar from 'components/ProgressBar/CircularProgressBar';
import { useLocation, useHistory } from 'react-router-dom';
import { Stations } from 'components/Stations/Stations';
import { getCounties } from './observationPageUtils';
import { StationsMap } from 'components/Stations/StationSearchField';
import { Counties, SelectedStations } from 'components/Observations/CountyStationList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('xs')]: {
      homeContainer: {
        marginLeft: '1em',
        marginRight: '1em',
      },
      graphContainer: {
        marginTop: '2em',
        marginBottom: '2em',
      },
      cover: {
        position: 'relative',
        height: '400px',
        width: '100%',
        zIndex: 1,
        backgroundColor: '#ddd',
        opacity: 0.6,
      },
      textCover: {
        zIndex: 3,
        textAlign: 'center',
        position: 'relative',
        top: '50%',
        color: '#000000',
        fontSize: 20,
      },
    },
  }),
);

interface WindRoseProps {
  apiBaseUrl: string;
}

const WindRosePage: React.FC<WindRoseProps> = ({ apiBaseUrl }) => {
  const classes = useStyles();
  const intl = useIntl();
  //id and name of selected stations
  const [selectedStations, setSelectedStations] = React.useState(new Map<string, string>());

  //Unfiltered list of wind rose stations mapped by county
  const [fullCountyMap, setFullCountyMap] = React.useState(new Map<string, Array<ObservationSource>>());
  //Unfiltered list of wind rose stations mapped by county
  const [periodFilteredCountyMap, setPeriodFilteredCountyMap] = React.useState(
    new Map<string, Array<ObservationSource>>(),
  );
  //selected period, in the format of url query string
  const [period, setPeriod] = React.useState<WindrosePeriod>(DefaultWindrosePeriod);
  //display map dialog
  const [showFetchError, setShowFetchError] = React.useState(false);
  //display county-list dialog
  const [stations, setStations] = React.useState<ObservationSource[]>();
  const [fetchingData, setFetchingData] = React.useState(false);
  const [windroseData, setWindroseData] = React.useState<windrose.Data | null>(null);
  // selected period
  const [selectedPeriod, setSelectedPeriod] = React.useState<WindrosePeriodOptions>(DefaultWindrosePeriodOption);
  // handle URL params
  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = new URLSearchParams(location.search);
  const [init, setInit] = React.useState(true);
  const history = useHistory();

  const [fromDate, setFromDate] = React.useState(moment().subtract(10, 'year').startOf('month'));
  const [toDate, setToDate] = React.useState(moment().startOf('month'));
  const [recurringMonths, setRecurringMonths] = React.useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

  React.useEffect(() => {
    document.title = intl.formatMessage({ id: 'wind_rose' }) + ' - Seklima';
    document.documentElement.lang = intl.locale;
  }, [intl]);

  useEffect(() => {
    if (WindrosePeriodOptions.RECURRING_PERIOD === period.option) {
      let months = [];
      if (fromDate.month() <= toDate.month()) {
        for (let i = fromDate.month(); i <= toDate.month(); i++) {
          months.push(i + 1);
        }
      } else {
        for (let i = fromDate.month(); i <= 11; i++) {
          months.push(i + 1);
        }
        for (let i = 0; i <= toDate.month(); i++) {
          months.push(i + 1);
        }
      }
      setRecurringMonths(months);
    }
  }, [period, fromDate, toDate]);

  useEffect(() => {
    const locationId = params.get('locationid');
    const timeResolution = params.get('timeresolution');

    if (timeResolution) {
      let periodOption = timeResolution as SetStateAction<WindrosePeriodOptions>;
      setSelectedPeriod(periodOption);
      if (
        periodOption === WindrosePeriodOptions.CUSTOM_PERIOD ||
        periodOption === WindrosePeriodOptions.RECURRING_PERIOD
      ) {
        const fromTime = params.get('from');
        const toTime = params.get('to');
        let from = moment(fromTime);
        let to = moment(toTime);
        if (from.isValid()) setFromDate(moment(fromTime));
        if (to.isValid()) setToDate(moment(toTime));
      }
    }
    if (locationId && stations) {
      let selectedFromUri = new Map<string, string>();
      for (let station of stations) {
        if (station.id === locationId) {
          selectedFromUri.set(locationId, station.name);
          break;
        }
      }
      setSelectedStations(selectedFromUri);
    }
    setInit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stations]);

  useEffect(() => {
    // ugly stuff to make automated URL work properly
    const locationId = params.get('locationid');
    const timeResolution = params.get('timeresolution');
    const from = params.get('from');
    const to = params.get('to');
    if (
      (selectedPeriod.toString() !== timeResolution ||
        (fromDate.isValid() &&
          !moment(from).isSame(fromDate) &&
          (selectedPeriod === WindrosePeriodOptions.CUSTOM_PERIOD ||
            selectedPeriod === WindrosePeriodOptions.RECURRING_PERIOD)) ||
        (toDate.isValid() &&
          !moment(to).isSame(toDate) &&
          (selectedPeriod === WindrosePeriodOptions.CUSTOM_PERIOD ||
            selectedPeriod === WindrosePeriodOptions.RECURRING_PERIOD))) &&
      !init
    ) {
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('timeresolution', selectedPeriod.toString());
      if (
        selectedPeriod === WindrosePeriodOptions.CUSTOM_PERIOD ||
        selectedPeriod === WindrosePeriodOptions.RECURRING_PERIOD
      ) {
        currentUrlParams.set('from', fromDate.format('YYYY-MM'));
        currentUrlParams.set('to', toDate.format('YYYY-MM'));
      } else {
        currentUrlParams.delete('from');
        currentUrlParams.delete('to');
      }
      history.push(window.location.pathname + '?' + currentUrlParams.toString());
    }
    if (
      (selectedStations.size && !locationId) ||
      (locationId && selectedStations.size && !selectedStations.has(locationId))
    ) {
      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('locationid', selectedStations.keys().next().value);
      history.push(window.location.pathname + '?' + currentUrlParams.toString());
    }
  }, [selectedStations, selectedPeriod, history, init, params, fromDate, toDate]);

  useEffect(() => {
    let filteredMap = new Map<string, Array<ObservationSource>>();
    if (fullCountyMap && fullCountyMap.size) {
      fullCountyMap.forEach((stations, county) => {
        let filteredStations = stations.filter((s) => {
          if (period.option === WindrosePeriodOptions.ALL_YEARS) {
            return true;
          }
          // does not need to have data for whole period according to KSS-1035
          // exclude stations if observations does not overlap with selected period
          let periodStartBeforeDataEnd = false;
          let dataStartBeforePeriodEnd = false;
          let hasDataFrom = moment(s.validFrom);
          let hasDataTo = moment(s.validTo);
          let stationOperational = !(hasDataTo.isValid() && hasDataTo.get('year') > 100);
          if (stationOperational) {
            hasDataTo = moment().utc().add(1, 'days'); // some time in the future
          }
          if (period.option === WindrosePeriodOptions.LAST_10_YEARS) {
            let tenYearsAgo = moment.utc().subtract(10, 'years');
            periodStartBeforeDataEnd = tenYearsAgo.isBefore(hasDataTo);
            dataStartBeforePeriodEnd = hasDataFrom.isBefore(moment.utc());
          } else if (period.option === WindrosePeriodOptions.LAST_30_YEARS) {
            let thirtyYearsAgo = moment.utc().subtract(30, 'years');
            periodStartBeforeDataEnd = thirtyYearsAgo.isBefore(hasDataTo);
            dataStartBeforePeriodEnd = hasDataFrom.isBefore(moment.utc());
          } else if (
            period.option === WindrosePeriodOptions.CUSTOM_PERIOD ||
            period.option === WindrosePeriodOptions.RECURRING_PERIOD
          ) {
            let periodToEndOfMonth = moment(period.to).add(1, 'month').subtract(1, 'days');
            periodStartBeforeDataEnd = moment(period.from).isBefore(hasDataTo);
            dataStartBeforePeriodEnd = hasDataFrom.isBefore(periodToEndOfMonth);
          }
          return periodStartBeforeDataEnd && dataStartBeforePeriodEnd; // overlapping
        });
        if (filteredStations && filteredStations.length) {
          filteredMap.set(county, filteredStations);
        }
      });
    }
    setPeriodFilteredCountyMap(filteredMap);
  }, [fullCountyMap, period]);

  useEffect(() => {
    fetchStationNameSearch(apiBaseUrl, 'windrose/stations').then((res) => {
      buildAndSetCountyMap(res, setFullCountyMap);
      setStations(res.data);
    });
  }, [apiBaseUrl]);

  useEffect(() => {
    setWindroseData(null);
    setShowFetchError(false);
  }, [selectedStations, period]);

  const handleShowWindrose = () => {
    let stationNumber = 0;
    let stationName = '';
    selectedStations.forEach((name, id) => {
      // We only have one selected station
      stationNumber = id ? parseInt(id.trim().replace('SN', '')) : 0;
      stationName = capitalizeStationName(name);
    });
    if (stationNumber) {
      setFetchingData(true);
      fetchWindroseValues(apiBaseUrl, stationNumber, period, recurringMonths)
        .then((res) => {
          if (res && res.data) {
            res.data.stationName = stationName;
            setWindroseData(res.data);
            setShowFetchError(false);
            setFetchingData(false);
          }
        })
        .catch(() => {
          // all errrors are 500 and we do not trust the error message
          setShowFetchError(true);
          setFetchingData(false);
        });
    }
  };

  return (
    <div className="global-root">
      <div className={classes.homeContainer}>
        <PageHeader titleId="wind_rose" infoTextId="wind_rose_info" />
        <WindrosePeriodSelect
          setPeriodUri={setPeriod}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
        <WindRosePageStations
          periodFilteredCountyMap={periodFilteredCountyMap}
          apiBaseUrl={apiBaseUrl}
          selectedStations={selectedStations}
          setSelectedStations={setSelectedStations}
        />

        <Button onClick={handleShowWindrose} variant={'contained'} color={'primary'} className={classes.button}>
          <FormattedMessage id="show_result_button" />
        </Button>
        <AlertTile
          displayAlert={showFetchError}
          onClose={() => setShowFetchError(false)}
          alertTitle={intl.formatMessage({ id: 'fetch_windrose_alert' })}
        />
        {fetchingData && (
          <div className={classes.cover}>
            <div className={classes.textCover}>
              <CircularProgressBar />
            </div>
          </div>
        )}
        {windroseData && (
          <div className={classes.graphContainer}>
            <WindroseDataPanel data={windroseData} recurringMonths={recurringMonths} period={period} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WindRosePage;

const WindRosePageStations: FC<{
  periodFilteredCountyMap: Map<string, ObservationSource[]>;
  apiBaseUrl: string;
  selectedStations: SelectedStations;
  setSelectedStations: (stations: SelectedStations) => void;
}> = ({ apiBaseUrl, periodFilteredCountyMap, selectedStations, setSelectedStations }) => {
  const [stationsMap, setStationsMap] = useState<StationsMap>(new Map());
  const [counties, setCounties] = useState<Counties>({});

  const intl = useIntl();
  const sources: ObservationSource[] = Array.from(periodFilteredCountyMap.values()).flat();
  useEffect(() => {
    const newCounties = getCounties(
      sources,
      intl.formatMessage({
        id: 'regions',
      }),
      intl.formatMessage({
        id: 'others',
      }),
    );
    setCounties(newCounties);
    // eslint-disable-next-line
  }, [periodFilteredCountyMap]);

  return (
    <Stations
      baseUrl={apiBaseUrl}
      stationsMap={stationsMap}
      fetchStationsMap={(name: string) => {
        const newStations = stationsSearchMap(sources, name);
        setStationsMap(newStations);
      }}
      clearStationsMap={() => setStationsMap(new Map())}
      counties={counties}
      fetchCounties={() => {}}
      selectedStations={selectedStations}
      setSelectedStations={setSelectedStations}
      maxSelectedStations={1}
    />
  );
};

import { IntlShape } from 'react-intl';

export function getCalenderFormat(selectedResolution: string) {
  if (selectedResolution === 'months' || selectedResolution === 'seasons') return 'MM/YYYY';
  if (selectedResolution === 'years') return 'YYYY';
  return 'DD/MM/YYYY';
}

export function getCalenderType(selectedResolution: string) {
  if (selectedResolution === 'months' || selectedResolution === 'seasons' || selectedResolution === 'years')
    return 'year';
  return 'date';
}

export function getCalenderView(selectedResolution: string): ('year' | 'month' | 'date')[] {
  if (selectedResolution === 'months' || selectedResolution === 'seasons') return ['year', 'month'];
  if (selectedResolution === 'years') return ['year'];
  return ['date'];
}

export function getTimeresolutionAsText(timeResolution: string, intl: IntlShape) {
  switch (timeResolution) {
    case 'PT1M':
      return '1 min';
    case 'PT10M':
      return '10 min';
    case 'PT20M':
      return '20 min';
    case 'PT30M':
      return '30 min';
    case 'PT1H':
      return intl.formatMessage({ id: 'one_hour' });
    case 'PT3H':
      return intl.formatMessage({ id: 'three_hours' });
    case 'PT6H':
      return intl.formatMessage({ id: 'six_hours' });
    case 'PT12H':
      return intl.formatMessage({ id: 'twelve_hours' });
    case 'P1D':
      return intl.formatMessage({ id: 'one_day' });
    case 'P1M':
      return intl.formatMessage({ id: 'one_month' });
    case 'P3M':
      return intl.formatMessage({ id: 'three_months' });
    case 'P6M':
      return intl.formatMessage({ id: 'six_months' });
    case 'P1Y':
      return intl.formatMessage({ id: 'one_year' });
    default:
      return timeResolution;
  }
}

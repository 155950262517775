import { Typography, Paper, Container, Grid, Link as MuiLink, Theme, createStyles } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuBar: {
      height: 37,
      marginTop: '-20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      boxShadow: '0px 2px 10px rgba(0,0,0,0.3)',
    },
    menu: {},
    menuItems: {
      fontSize: '1.2em',
      textTransform: 'uppercase',
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

interface Props {
  kssBaseUrl: string;
}

export const Menu: React.FC<Props> = ({ kssBaseUrl }) => {
  const classes = useStyles();
  return (
    <Paper square className={classes.menuBar}>
      <Container className={classes.menu}>
        <Grid container item lg={12} justifyContent="space-between">
          <Typography className={classes.menuItems}>
            <Link className={classes.link} to={'/observations/'}>
              <FormattedMessage id="menu_link_observation" />
            </Link>
          </Typography>
          <Typography className={classes.menuItems}>
            <Link className={classes.link} to={'/stations/'}>
              <FormattedMessage id="menu_link_station" />
            </Link>
          </Typography>
          <Typography className={classes.menuItems}>
            <Link className={classes.link} to={'/windrose/'}>
              <FormattedMessage id="wind_rose_menu_label" />
            </Link>
          </Typography>
          <Typography className={classes.menuItems}>
            <MuiLink href={kssBaseUrl + '/ivf?locale=nb'}>
              <FormattedMessage id="menu_link_ivf" />
            </MuiLink>
          </Typography>
        </Grid>
      </Container>
    </Paper>
  );
};

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ModeType } from '../../pages/ObservationPage';
import CircularProgressBar from '../ProgressBar/CircularProgressBar';

const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    cover: {
      position: 'relative',
      height: '400px',
      width: '100%',
      zIndex: 1,
      backgroundColor: '#ddd',
      opacity: 0.6,
    },
    textCover: {
      zIndex: 3,
      textAlign: 'center',
      position: 'relative',
      top: '50%',
      color: '#000000',
      fontSize: 20,
    },
  },
});

class MessagePanel extends Component {
  showPanel = () => {
    if (this.props.mode) {
      return this.props.mode !== ModeType.VALID_DATA && this.props.mode !== ModeType.INIT_STATE;
    }
    return false;
  };

  render() {
    const { classes } = this.props;
    if (this.props.mode === ModeType.UPDATE_NEED || this.props.mode === ModeType.NO_DATA) {
      return (
        <div className={classes.cover}>
          <div className={classes.textCover}>
            <FormattedMessage id={this.props.mode} />
          </div>
        </div>
      );
    } else if (this.props.mode === ModeType.FETCHING_DATA) {
      return (
        <div className={classes.cover}>
          <div className={classes.textCover}>
            <CircularProgressBar />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

MessagePanel.propTypes = {
  classes: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
};

export default injectIntl(withStyles(styles)(MessagePanel));

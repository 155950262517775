import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    padding: '1rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'black',
    fontSize: '14px',
    backgroundColor: '#F3F9F5',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    whiteSpace: 'pre-line',
  },
}))(Tooltip);

export default LightTooltip;

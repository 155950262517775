export const black_palette = {
  light: '#E9E9E9',
  main: '#496C80',
  dark: '#323232',
};

export const green_palette = {
  light: '#d6eed3',
  main: '#00501b',
};

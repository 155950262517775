import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import React from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableHeadRow from './TableHeadRow';
import TableBodyRow from './TableBodyRow';
import TablePagination from '@material-ui/core/TablePagination';
import timeHandler from '../Observations/helper/timeHandler';
import Button from '@material-ui/core/Button';
import { ReactComponent as DownloadIcon } from '../../images/downloadIcon.svg';
import ItemDialog from '../../components/TableComponent/ItemDialog';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as ShareIcon } from '../../images/shareIcon.svg';
import ShareDialogComponent from '../ShareDialog/ShareDialogComponent';
import getElementById from '../Observations/helper/elementHandler';
import { getSorting, stableSort } from 'utils/SortUtils';

const styles = (theme) => ({
  root: {
    overflow: 'scroll',
  },
  panelIcons: {
    width: 20,
    height: 'auto',
    paddingRight: theme.spacing(),
    display: 'inline-block',
  },
});

/**
 *
 <Paper className={classes.root}>
 <Table className={classes.table}>
 * @param props
 * @returns {*}
 * @constructor
 */

class TableComponent extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    page: 0,
    rowsPerPage: 10,
    showDownloadDialog: false,
    showShareDialog: false,
  };

  getElementTypes = (observationData) => {
    let elemList = [];
    const { series } = observationData;
    for (let index in series) {
      if (!elemList.includes(series[index].elementId)) {
        elemList.push(series[index].elementId);
      }
    }
    return elemList;
  };

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property });
  };

  buildHeadRow = () => {
    let weatherElements = this.getElementTypes(this.props.observationData);
    let headRows = [
      { id: 'name', numeric: false, label: this.props.intl.formatMessage({ id: 'name' }) },
      { id: 'station', numeric: false, label: this.props.intl.formatMessage({ id: 'station_nr' }) },
    ];

    if (this.props.selectedResolution === timeHandler.timeResolution.SEASONS) {
      headRows.push({ id: 'time', numeric: false, label: this.props.intl.formatMessage({ id: 'date_season' }) });
    } else if (
      this.props.selectedResolution === timeHandler.timeResolution.MINUTES ||
      this.props.selectedResolution === timeHandler.timeResolution.HOURS
    ) {
      headRows.push({ id: 'time', numeric: false, label: this.props.intl.formatMessage({ id: 'date_hours_minutes' }) });
    } else {
      headRows.push({ id: 'time', numeric: false, label: this.props.intl.formatMessage({ id: 'date' }) });
    }

    for (let index in weatherElements) {
      let elemName = getElementById(this.props.elementMap, weatherElements[index]).name;
      headRows.push({
        id: elemName,
        numeric: true,
        label: elemName,
      });
    }
    return headRows;
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  closeDownloadDialog = () => {
    this.setState({ showDownloadDialog: false });
  };

  closeShareDialog = () => {
    this.setState({ showShareDialog: false });
  };

  render() {
    const { classes, rows } = this.props;
    return (
      <Paper className={classes.root}>
        <Table>
          <TableHead>
            <TableHeadRow
              headRow={this.buildHeadRow()}
              order={this.state.order}
              orderBy={this.state.orderBy}
              handleRequestSort={this.handleRequestSort}
            />
          </TableHead>
          <TableBody>
            {stableSort(rows, getSorting(this.state.order, this.state.orderBy))
              .slice(
                this.state.page * this.state.rowsPerPage,
                this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
              )
              .map((row, index) => (
                <TableBodyRow
                  body={row}
                  index={index}
                  key={index + '_' + row.station}
                  selectedResolution={this.props.selectedResolution}
                />
              ))}
          </TableBody>
        </Table>
        <Button style={{ margin: '12px' }} onClick={() => this.setState({ showDownloadDialog: true })}>
          <DownloadIcon className={classes.panelIcons}></DownloadIcon>
          <Typography style={{ textTransform: 'none' }}>
            {this.props.intl.formatMessage({ id: 'download_table' })}
          </Typography>
        </Button>
        <Button style={{ margin: '12px' }} onClick={() => this.setState({ showShareDialog: true })}>
          <ShareIcon className={classes.panelIcons}></ShareIcon>
          <Typography style={{ textTransform: 'none' }}>{this.props.intl.formatMessage({ id: 'share' })}</Typography>
        </Button>
        <TablePagination
          style={{ display: 'inline-block', float: 'right' }}
          rowsPerPageOptions={[10, 25, 100, 1000]}
          component="div"
          count={rows.length < this.state.rowsPerPage ? this.state.rowsPerPage : rows.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          labelRowsPerPage={this.props.intl.formatMessage({ id: 'rows_per_page' })}
          labelDisplayedRows={({ from, to, count }) =>
            from + '-' + to + this.props.intl.formatMessage({ id: 'of' }) + count
          }
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        <ItemDialog
          open={this.state.showDownloadDialog}
          items={['csv', 'ods', 'xlsx']}
          onClose={this.closeDownloadDialog}
          data={stableSort(rows, getSorting(this.state.order, this.state.orderBy))}
          resolution={this.props.selectedResolution}
        />
        <ShareDialogComponent
          open={this.state.showShareDialog}
          onClose={this.closeShareDialog}
          shareUrl={this.props.shareUrl}
        />
      </Paper>
    );
  }
}

TableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  observationData: PropTypes.object,
  elementMap: PropTypes.object.isRequired,
  selectedResolution: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  shareUrl: PropTypes.func.isRequired,
};

export default injectIntl(withStyles(styles)(TableComponent));

import React from 'react';
import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import LightTooltip from './InfoBox/LightTooltip';
import { ReactComponent as InfoIcon } from '../images/infoIcon.svg';
import '@fontsource/roboto';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('xs')]: {
      root: {
        fontFamily: 'Roboto',
        fontSize: '2em',
        lineHeight: '1.1em',
        marginTop: '0.5em',
      },
      headerHr: {
        border: '0px',
        height: '1px',
        backgroundColor: '#4A4A4A;',
      },
      seklimaHeader: {
        fontWeight: 400,
        fontSize: '1.5em',
      },
    },
  }),
);

interface Props {
  titleId: string;
  infoTextId: string;
}

export const PageHeader: React.FC<Props> = ({ titleId, infoTextId }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant={'h6'}>
        <FormattedMessage id={'seklima'} />
      </Typography>
      <FormattedMessage id={titleId} />
      <LightTooltip
        enterTouchDelay={50}
        leaveTouchDelay={20000}
        title={
          <Typography>
            <FormattedMessage
              id={infoTextId}
              values={{
                link: (text: string) => (
                  <a href="https://klimaservicesenter.no/kss/vrdata/regionv" target="_blank" rel="noopener noreferrer">
                    {text}
                  </a>
                ),
              }}
            />
          </Typography>
        }
        interactive
        placement="bottom-start"
      >
        <IconButton aria-label="info">
          <InfoIcon style={{ width: 20 }} />
        </IconButton>
      </LightTooltip>
      <hr className={classes.headerHr} />
    </div>
  );
};

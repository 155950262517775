import moment from 'moment';
import * as XLSX from 'xlsx';
import timeHandler from './timeHandler';
import { captitalizeWord } from '../../../utils/stringUtils';

export default function handleExport(type, data, currentResolution, intl) {
  let keys = Object.keys(data[0]);
  let table = JSON.parse(JSON.stringify(data));
  table.forEach((item) => {
    keys.forEach((key) => {
      if (
        key !== 'time' &&
        key !== 'station' &&
        key !== 'name' &&
        key !== 'date' &&
        type !== 'xlsx' &&
        intl.locale === 'nb'
      ) {
        item[key] = item[key].toString().replace('.', ',');
      }
      if (key === 'time') {
        // we want special timeformat for downloads.
        let timeKey = 'time';
        item[timeKey] = moment.tz(item[timeKey], 'Etc/GMT-1').format(timeHandler.getTimeFormat(currentResolution));
      }
    });
  });

  table.push({
    name:
      intl.formatMessage({ id: 'met_data_licence_pt1' }) +
      moment().format('DD.MM.YYYY') +
      intl.formatMessage({ id: 'met_data_licence_pt2' }),
  });
  let worksheet = XLSX.utils.json_to_sheet(table);
  let range = XLSX.utils.decode_range(worksheet['!ref']);

  for (let C = range.s.r; C <= range.e.r; ++C) {
    let address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
    if (!worksheet[address]) continue;
    if (intl.locale === 'nb') {
      worksheet[address].v = captitalizeWord(getNorwegianTranslation(worksheet[address].v));
    } else {
      worksheet[address].v = captitalizeWord(getEnglishTranslation(worksheet[address].v));
    }
  }

  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet');
  if (type === 'csv') {
    XLSX.writeFile(wb, 'table.' + type, { bookType: 'csv', FS: ';' });
  } else {
    XLSX.writeFile(wb, 'table.' + type);
  }
}

function getEnglishTranslation(value) {
  let translation;
  switch (value) {
    case 'time':
      translation = 'time(norwegian mean time)';
      break;
    default:
      translation = value;
  }
  return translation;
}

function getNorwegianTranslation(value) {
  let translation;
  switch (value) {
    case 'name':
      translation = 'navn';
      break;
    case 'station':
      translation = 'stasjon';
      break;
    case 'time':
      translation = 'tid(norsk normaltid)';
      break;
    default:
      translation = value;
  }
  return translation;
}

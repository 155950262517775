export default function getElementById(currentElementMap, elementId) {
  for (let weatherCategory in currentElementMap) {
    for (let weatherType in currentElementMap[weatherCategory]) {
      if (currentElementMap[weatherCategory][weatherType].id === elementId) {
        return {
          name: currentElementMap[weatherCategory][weatherType].name,
          id: currentElementMap[weatherCategory][weatherType].id,
        };
      }
    }
  }
  return null;
}

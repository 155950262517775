export enum WindrosePeriodOptions {
  LAST_10_YEARS = 'last_10_years',
  LAST_30_YEARS = 'last_30_years',
  ALL_YEARS = 'all_years',
  CUSTOM_PERIOD = 'custom_period',
  RECURRING_PERIOD = 'recurring_period',
}

export const DefaultWindrosePeriodOption = WindrosePeriodOptions.LAST_10_YEARS;

export interface WindrosePeriod {
  option: WindrosePeriodOptions;
  from: Date;
  to: Date;
}

export const DefaultWindrosePeriod: WindrosePeriod = {
  option: DefaultWindrosePeriodOption,
  from: new Date(),
  to: new Date(),
};

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Toolbar from '@material-ui/core/Toolbar';
import { Order } from './SortUtils';
import { TableBodyRows } from './TableBodyRows';
import { TableHeaderRow } from './TableHeaderRow';
import { Button, Paper, TablePagination, Typography } from '@material-ui/core';
import { Moment } from 'moment';
import { Descriptions, HeaderCells, BodyCells } from './SortableTableTypes';
import { ReactComponent as DownloadIcon } from '../../images/downloadIcon.svg';
import { useIntl } from 'react-intl';
import { ExportDialog } from './ExportDialog';
import { ShareButton } from 'components/ShareButton/ShareButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
    },
    panelIcons: {
      width: 20,
      height: 'auto',
      paddingRight: theme.spacing(),
      display: 'inline-block',
    },
    footer: {
      display: 'flex',
    },
  }),
);

interface SortableTableProps {
  title: string;
  headerCells: HeaderCells[];
  bodyCells: BodyCells[];
  descriptions?: Descriptions;
  orderByDefault: string | number;
  dense?: boolean;
  rowsPerPageOptions?: number[];
  rowsPerPageDefault?: number;
  dateFormat?: string;
  defaultSort?: string | number;
  labelRowsPerPage?: string;
  displayShareButton?: boolean;
  externalComparatorRule?: (a: number | string | Moment, b: number | string | Moment, order: Order) => number;
}

/**
 * Generic sortable table.
 *
 * @param {dense} boolean control table denseness. default: false
 * @param {title} string title for table
 * @param {headerCells} HeaderCells[] Header of table. HeaderCells [id] must be the same as [key] in rows
 * @param {bodyCells} BodyCells[] Rows of table. Rows [key] must be the same as [id] in the HeaderCell
 * @param {descriptions} Descriptions Object of descriptions, key value gets connected to TableRowData id
 * @param {rowsPerPageOptions} number[] row selection size for table. default [5, 10, 25]
 * @param {rowsPerPageDefault} number default row selection size. default: 5
 * @param {dateFormat} string how to format Moment dates displayed in table. default: 'DD-MM-YYYY'
 * @param {defaultSort} id of headercell to be the default sort category
 * @param {externalComparatorRule} function Override internal sorting function for table.
 * @param {labelRowsPerPage} label for the rows per page text
 * @param {displayShareButton} boolean display a share button for the current window url
 * @public
 */
export const SortableTable: React.FC<SortableTableProps> = ({
  dense = false,
  title,
  headerCells,
  bodyCells,
  descriptions,
  orderByDefault,
  rowsPerPageOptions = [5, 10, 25],
  rowsPerPageDefault = 5,
  dateFormat = 'DD-MM-YYYY',
  defaultSort,
  labelRowsPerPage,
  displayShareButton = false,
  externalComparatorRule,
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string | number>(orderByDefault);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageDefault);
  const [displayDownloadDialog, setDisplayDownloadDialog] = React.useState(false);
  const intl = useIntl();
  const handleRequestSort = (property: string | number) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  React.useEffect(() => {
    if (defaultSort) handleRequestSort(defaultSort);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            {title}
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <TableHeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              cells={headerCells}
              dense={dense}
            />
            <TableBodyRows
              rows={bodyCells}
              descriptions={descriptions}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              dateFormat={dateFormat}
              dense={dense}
              externalComparatorRule={externalComparatorRule}
            />
          </Table>
        </TableContainer>
        <div className={classes.footer}>
          <Button style={{ paddingLeft: '1em' }} onClick={() => setDisplayDownloadDialog(true)}>
            <DownloadIcon className={classes.panelIcons}></DownloadIcon>
            <Typography style={{ textTransform: 'none' }}>{intl.formatMessage({ id: 'download_table' })}</Typography>
          </Button>
          {displayShareButton && <ShareButton />}
          <div style={{ flexGrow: 1 }}></div>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={bodyCells.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={labelRowsPerPage}
          />
        </div>
      </Paper>
      <ExportDialog
        open={displayDownloadDialog}
        handleClose={() => setDisplayDownloadDialog(false)}
        data={bodyCells}
        header={headerCells}
        title={title}
        dateFormat={dateFormat}
      />
    </div>
  );
};

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const styles = (theme) => ({
  textfield: {
    padding: theme.spacing(2),
  },
});

function ShareDialog(props) {
  const { onClose, open, intl, classes, shareUrl } = props;

  function handleClose() {
    onClose();
  }
  return (
    <Grid item xs={12} md={6}>
      <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle>{intl.formatMessage({ id: 'share' })}</DialogTitle>
        <TextField
          spellCheck={false}
          className={classes.textfield}
          value={shareUrl()}
          InputProps={{
            endAdornment: (
              <Button color="primary" style={{ width: 150 }} size={'medium'} onClick={() => copy(shareUrl())}>
                <FormattedMessage id="copy_to_clipboard" />
              </Button>
            ),
          }}
        />
      </Dialog>
    </Grid>
  );
}
ShareDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  shareUrl: PropTypes.func.isRequired,
};

export default injectIntl(withStyles(styles)(ShareDialog));

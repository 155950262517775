import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    gridContainer: {
      marginTop: '2em',
      marginBottom: '2em',
    },
    dateLabel: {
      display: 'contents',
    },
  },
});

function SelectTimePeriod(props) {
  const { classes, changed, selectedResolution, selectedPeriod } = props;

  let selectItems = null;
  switch (selectedResolution) {
    case 'minutes':
      selectItems = [
        <MenuItem key="select_period" value="" disabled>
          <FormattedMessage id="select_period" />
        </MenuItem>,
        <MenuItem key="last_hour" value={'last_hour'}>
          <FormattedMessage id="last_hour" />
        </MenuItem>,
        <MenuItem key="last_24_hours" value={'last_24_hours'}>
          <FormattedMessage id="last_24_hours" />
        </MenuItem>,
        <MenuItem key="last_7_days" value={'last_7_days'}>
          <FormattedMessage id="last_7_days" />
        </MenuItem>,
        <MenuItem key="custom_period" value={'custom_period'}>
          <FormattedMessage id="custom_period" />
        </MenuItem>,
      ];
      break;
    case 'hours':
      selectItems = [
        <MenuItem key="select_period" value="" disabled>
          <FormattedMessage id="select_period" />
        </MenuItem>,
        <MenuItem key="last_24_hours" value={'last_24_hours'}>
          <FormattedMessage id="last_24_hours" />
        </MenuItem>,
        <MenuItem key="last_7_days" value={'last_7_days'}>
          <FormattedMessage id="last_7_days" />
        </MenuItem>,
        <MenuItem key="last_30_days" value={'last_30_days'}>
          <FormattedMessage id="last_30_days" />
        </MenuItem>,
        <MenuItem key="custom_period" value={'custom_period'}>
          <FormattedMessage id="custom_period" />
        </MenuItem>,
      ];
      break;
    case 'days':
      selectItems = [
        <MenuItem key="select_period" value="" disabled>
          <FormattedMessage id="select_period" />
        </MenuItem>,
        <MenuItem key="last_7_days" value={'last_7_days'}>
          <FormattedMessage id="last_7_days" />
        </MenuItem>,
        <MenuItem key="last_30_days" value={'last_30_days'}>
          <FormattedMessage id="last_30_days" />
        </MenuItem>,
        <MenuItem key="last_6_months" value={'last_6_months'}>
          <FormattedMessage id="last_6_months" />
        </MenuItem>,
        <MenuItem key="last_12_months" value={'last_12_months'}>
          <FormattedMessage id="last_12_months" />
        </MenuItem>,
        <MenuItem key="custom_period" value={'custom_period'}>
          <FormattedMessage id="custom_period" />
        </MenuItem>,
        <MenuItem key="recurring_period" value={'recurring_period'}>
          <FormattedMessage id="recurring_period" />
        </MenuItem>,
      ];
      break;
    case 'months':
      selectItems = [
        <MenuItem key="select_period" value="" disabled>
          <FormattedMessage id="select_period" />
        </MenuItem>,
        <MenuItem key="last_12_months" value={'last_12_months'}>
          <FormattedMessage id="last_12_months" />
        </MenuItem>,
        <MenuItem key="last_5_years" value={'last_5_years'}>
          <FormattedMessage id="last_5_years" />
        </MenuItem>,
        <MenuItem key="all_years" value={'all_years'}>
          <FormattedMessage id="all_years" />
        </MenuItem>,
        <MenuItem key="custom_period" value={'custom_period'}>
          <FormattedMessage id="custom_period" />
        </MenuItem>,
        <MenuItem key="recurring_period" value={'recurring_period'}>
          <FormattedMessage id="recurring_period" />
        </MenuItem>,
      ];
      break;
    case 'years':
      selectItems = [
        <MenuItem key="select_period" value="" disabled>
          <FormattedMessage id="select_period" />
        </MenuItem>,
        <MenuItem key="last_5_years" value={'last_5_years'}>
          <FormattedMessage id="last_5_years" />
        </MenuItem>,
        <MenuItem key="all_years" value={'all_years'}>
          <FormattedMessage id="all_years" />
        </MenuItem>,
        <MenuItem key="custom_period" value={'custom_period'}>
          <FormattedMessage id="custom_period" />
        </MenuItem>,
      ];
      break;
    case 'seasons':
      selectItems = [
        <MenuItem key="select_period" value="" disabled>
          <FormattedMessage id="select_period" />
        </MenuItem>,
        <MenuItem key="last_12_months" value={'last_12_months'}>
          <FormattedMessage id="last_12_months" />
        </MenuItem>,
        <MenuItem key="last_5_years" value={'last_5_years'}>
          <FormattedMessage id="last_5_years" />
        </MenuItem>,
        <MenuItem key="all_years" value={'all_years'}>
          <FormattedMessage id="all_years" />
        </MenuItem>,
        <MenuItem key="custom_period" value={'custom_period'}>
          <FormattedMessage id="custom_period" />
        </MenuItem>,
        <MenuItem key="recurring_period" value={'recurring_period'}>
          <FormattedMessage id="recurring_period" />
        </MenuItem>,
      ];
      break;
    default:
      selectItems = null;
  }

  return (
    <Grid container direction={'column'} className={classes.gridContainer}>
      <Grid item xs={12} md={6}>
        <InputLabel htmlFor={props.labelId + '_input'} style={{ paddingBottom: 6 }}>
          <FormattedMessage id={props.labelId} />
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true}>
          <Select
            id={props.labelId + '-select'}
            value={selectedPeriod}
            onChange={(event) => changed(event.target.value)}
            displayEmpty
            input={<OutlinedInput labelWidth={0} error={props.noPeriodSelectedAtSearch} />}
          >
            {selectItems}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

SelectTimePeriod.propTypes = {
  classes: PropTypes.object.isRequired,
  labelId: PropTypes.string.isRequired,
  selectedResolution: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  changed: PropTypes.func.isRequired,
  noPeriodSelectedAtSearch: PropTypes.bool.isRequired,
};

SelectTimePeriod.defaultProps = {
  labelId: 'period',
};

export default withStyles(styles)(SelectTimePeriod);

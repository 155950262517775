export const defaultWeatherElements = {
  days: [
    'sum(precipitation_amount P1D)',
    'mean(air_temperature P1D)',
    'max(air_temperature P1D)',
    'min(air_temperature P1D)',
    'max(max(wind_speed PT1H) P1D)',
    'max(wind_speed_of_gust P1D)',
    'mean(wind_speed P1D)',
    'surface_snow_thickness',
  ],
  minutes: [
    'sum(precipitation_amount PT1M)',
    'sum(precipitation_amount PT10M)',
    'air_temperature',
    'max(air_temperature PT10M)',
    'min(air_temperature PT10M)',
    'wind_speed',
    'wind_from_direction',
    'max(wind_speed_of_gust PT10M)',
  ],
  hours: [
    'sum(precipitation_amount PT1H)',
    'sum(precipitation_amount PT6H)',
    'sum(precipitation_amount PT12H)',
    'air_temperature',
    'max(air_temperature PT1H)',
    'min(air_temperature PT1H)',
    'max(wind_speed PT1H)',
    'max(wind_speed_of_gust PT1H)',
    'wind_speed',
    'wind_from_direction',
  ],
  months: [
    'mean(air_temperature P1M)',
    'max(air_temperature P1M)',
    'min(air_temperature P1M)',
    'max(max(wind_speed PT1D) P1M)',
    'mean(air_temperature_anomaly P1M 1991_2020)',
    'sum(precipitation_amount P1M)',
    'sum(precipitation_amount_anomaly P1M 1991_2020)',
    'max(max(wind_speed P1D) P1M)',
    'max(wind_speed_of_gust P1M)',
    'max(surface_snow_thickness P1M)',
    'mean(surface_snow_thickness P1M)',
  ],
  years: [
    'sum(precipitation_amount P1Y)',
    'sum(precipitation_amount_anomaly P1Y 1991_2020)',
    'mean(air_temperature P1Y)',
    'max(air_temperature P1Y)',
    'min(air_temperature P1Y)',
    'mean(air_temperature_anomaly P1Y 1991_2020)',
  ],
  seasons: [
    'sum(precipitation_amount P3M)',
    'sum(precipitation_amount_anomaly P3M 1991_2020)',
    'max(air_temperature P3M)',
    'mean(air_temperature P3M)',
    'min(air_temperature P3M)',
    'mean(air_temperature_anomaly P3M 1991_2020)',
    'max(air_temperature P6M)',
    'mean(air_temperature P6M)',
    'min(air_temperature P6M)',
    'mean(air_temperature_anomaly P6M 1991_2020)',
  ],
};

export const otherCountyIdentifies = 'xxxOtherxxx';

import { FC, useEffect, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import OLMap from 'ol/Map';
import { Tile } from 'ol/layer';
import View from 'ol/View';
import { boundingExtent } from 'ol/extent';
import Overlay from 'ol/Overlay';
import TileWMS from 'ol/source/TileWMS';
import { WMTS, Cluster } from 'ol/source';
import { Zoom } from 'ol/control';
import TilegridWMTS from 'ol/tilegrid/WMTS';
import { get as getProjection, transform } from 'ol/proj.js';
import { getWidth, getTopLeft } from 'ol/extent.js';
import { register } from 'ol/proj/proj4.js';
import proj4 from 'proj4';
import './StationMap.css';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import LocationMarker from '../../images/sted_lilla.png';
import LocationMarkerIco from '../../images/sted_lilla_ico.png';
import StationDefaultMarker from '../../images/mapIconStation.png';
import { capitalizeStationName } from '../../utils/stringUtils';
import StationSelectedMarker from '../../images/mapIconStationSelected.png';
import ClusterSelectedMarker from '../../images/mapIconClusterSelected.png';
import ClusterMarker from '../../images/mapIconCluster.png';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Control from 'ol/control/Control';
import Attribution from 'ol/control/Attribution';
import Geometry from 'ol/geom/Geometry';
import RenderFeature from 'ol/render/Feature';
import { ObservationSource } from '@Types/ObservationSource';
import { MapBrowserEvent } from 'ol';
import { SelectedStations } from 'components/Observations/CountyStationList';

const iconStyle = new Style({
  image: new Icon(
    /** @type {module:ol/style/Icon~Options} */ {
      src: LocationMarker,
      scale: 0.5,
    },
  ),
});

const MapTSComponent: FC<{
  lat: number;
  long: number;
  isStation: boolean;
  selectedStations: SelectedStations;
  setSelectedStations: (s: SelectedStations) => void;
  stations: ObservationSource[];
  stationSelectLimit: number;
}> = ({ isStation, lat, long, selectedStations, setSelectedStations, stationSelectLimit, stations }) => {
  const [state, setState] = useState<{
    map: OLMap | null;
    locationLayer: VectorLayer<VectorSource<Geometry>> | null;
    stationLayer: VectorLayer<Cluster> | null;
    sProjection: string;
    baseZoom: number;
    closeZoom: number;
    selectionDesc: HTMLParagraphElement | null;
  }>({
    map: null,
    locationLayer: null,
    stationLayer: null,
    sProjection: 'EPSG:32633',
    baseZoom: 4,
    closeZoom: 11,
    selectionDesc: null,
  });

  const iconFeature = (lat: number, long: number) => {
    return new Feature({
      geometry: new Point(fromLonLat([long, lat], state.sProjection)),
    });
  };

  /**
   * this fly animation is take from openlayers animation example:
   * https://openlayers.org/en/latest/examples/animation.html
   * @param view
   * @param location
   * @param done
   */
  const flyTo = (view: View, location: number[], done: (d: boolean) => void) => {
    let duration = 2000;
    let parts = 2;
    let called = false;
    function callback(complete: boolean) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        done(complete);
      }
    }
    view.animate(
      {
        center: location,
        duration: duration,
      },
      callback,
    );
    view.animate(
      {
        zoom: state.closeZoom / 2,
        duration: duration / 2,
      },
      {
        zoom: state.closeZoom,
        duration: duration / 2,
      },
      callback,
    );
  };
  useEffect(() => {
    if (!state.map) return;
    if (isStation) {
      flyTo(state.map.getView(), fromLonLat([long, lat], state.sProjection), function () {});
      return;
    }
    let icon = iconFeature(lat, long);
    icon.setStyle(iconStyle);
    let vectorSource = new VectorSource({
      features: [icon],
    });
    if (state.locationLayer && Object.entries(state.locationLayer).length) {
      state.locationLayer.getSource().clear();
      flyTo(state.map.getView(), fromLonLat([long, lat], state.sProjection), function () {});
      state.locationLayer.setSource(vectorSource);
    } else {
      flyTo(state.map.getView(), fromLonLat([long, lat], state.sProjection), function () {});
      let vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      setState({ ...state, locationLayer: vectorLayer });
      state.map.addLayer(vectorLayer);
    }
    // eslint-disable-next-line
  }, [lat, long, isStation]);

  useEffect(() => {
    let selectedIds = Array.from(selectedStations.keys());
    if (state.selectionDesc) updateSelectionDesc(selectedIds.length, state.selectionDesc);
    let clusters = state.stationLayer ? state.stationLayer.getSource().getFeatures() : [];
    for (let cIndex in clusters) {
      let features = clusters[cIndex].getProperties().features;
      for (let fIndex in features) {
        let selected = selectedIds.includes(features[fIndex].get('stationId'));
        if (selected) {
          features[fIndex].setProperties({ selected });
        } else if (features[fIndex].get('selected')) {
          // unselect feature
          features[fIndex].setProperties({ selected: false });
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedStations]);

  const intl = useIntl();
  const addFeatureLayer = (map: OLMap, selectedStations: Map<string, string>) => {
    let selectedIds = Array.from(selectedStations.keys());
    let features: Feature<Geometry>[] = [];
    stations.forEach((station) => {
      if (station && station.geometry && station.geometry.coordinates) {
        const maslStr = intl.formatMessage({ id: 'masl' });
        const municipality = station.municipality ? capitalizeStationName(station.municipality) + ', ' : '';
        const htmlDescription =
          '<strong>' +
          capitalizeStationName(station.name) +
          '</strong><br>' +
          municipality +
          maslStr +
          ': ' +
          station.masl +
          ' m';
        const selected = selectedIds.includes(station.id);
        const f = new Feature({
          geometry: new Point(fromLonLat(station.geometry.coordinates, state.sProjection)),
          stationId: station.id,
          stationName: station.name,
          selected: selected,
          htmlDescription: htmlDescription,
        });
        features.push(f);
      }
    });
    const stationVectorSource = new VectorSource<Geometry>({
      features: features,
    });
    const stationSource = new Cluster({
      distance: 20,
      source: stationVectorSource,
    });
    const stationLayer = new VectorLayer({
      source: stationSource,
      style: stationFeatureStyle,
    });
    map.addLayer(stationLayer);
    setState({ ...state, stationLayer: stationLayer });
  };

  useEffect(() => {
    const { map } = state;
    if (!map) return;
    let element = document.getElementById('featureDescription');
    if (element) {
      let popup = new Overlay({
        element: element,
        positioning: 'bottom-center',
        stopEvent: false,
        offset: [0, -20],
      });
      map.addOverlay(popup);

      const handleHover = (event: MapBrowserEvent<any>) => {
        // show mouse-over popup
        let features = map.getFeaturesAtPixel(event.pixel, { hitTolerance: 2 });
        if (features) {
          features.forEach(function (feature) {
            if (element && feature && feature.getProperties().features) {
              element.style.display = 'block';
              let coordinates = feature.getGeometry() && (feature.getGeometry() as Point).getCoordinates();
              popup.setPosition(coordinates);
              if (feature.getProperties().features.length === 1) {
                element.innerHTML = feature.getProperties().features[0].get('htmlDescription');
              } else if (feature.getProperties().features.length > 1) {
                let stationStr = intl.formatMessage({ id: 'stations' });
                element.innerHTML =
                  '<strong>' + feature.getProperties().features.length + ' ' + stationStr + '</strong>';
              }
            }
          });
        } else if (element) {
          element.style.display = 'none';
        }
      };
      map.on('pointermove', handleHover);
    }
    // eslint-disable-next-line
  }, [state.map]);

  useEffect(() => {
    const { map } = state;
    if (!map) return;
    let element = document.getElementById('featureDescription');

    const handleClick = (event: MapBrowserEvent<any>) => {
      // select feature
      let clusters = map.getFeaturesAtPixel(event.pixel, { hitTolerance: 2 });
      if (clusters) {
        clusters.forEach((cluster) => {
          if (cluster && cluster.getProperties().features) {
            let features = cluster.getProperties().features as Feature<Geometry>[];
            if (features.length === 1) {
              //toggle station
              handleSelectStation(
                features[0].get('stationId'),
                features[0].get('stationName'),
                features[0].get('selected'),
              );
            } else {
              // zoom in on cluster
              let extents: number[][] = [];
              features.forEach((f) => {
                extents.push((f.getGeometry() as Point).getCoordinates());
              });
              let newExtent = boundingExtent(extents);
              map.getView().fit(newExtent, {
                size: map.getSize(),
                padding: [50, 10, 20, 10],
                duration: 400,
              });
            }
          }
        });
      } else if (element) {
        element.style.display = 'none';
      }
    };
    map.on('singleclick', handleClick);

    return () => {
      map.un('singleclick', handleClick);
    };
    // eslint-disable-next-line
  }, [selectedStations, state.map]);

  const handleSelectStation = (stationId: string, stationName: string, selected: boolean) => {
    if (!selected && selectedStations.size >= stationSelectLimit) {
      return; // not allowed to select more than stationSelectLimit
    }
    let newSelectedStations = new Map(selectedStations);
    if (!selected) {
      newSelectedStations.set(stationId, stationName);
      setSelectedStations(newSelectedStations);
    } else if (newSelectedStations.delete(stationId)) {
      setSelectedStations(newSelectedStations);
    }
  };

  const stationFeatureStyle = (feature: Feature<Geometry> | RenderFeature): Style => {
    let style = null;
    let features = feature.getProperties().features;
    if (features.length > 1) {
      let isSelected = false;
      for (let i in features) {
        if (features[i].get('selected')) {
          isSelected = true;
          break;
        }
      }
      let marker = isSelected ? ClusterSelectedMarker : ClusterMarker;
      style = new Style({
        image: new Icon({
          src: marker,
        }),
      });
    } else {
      let isSelected = features[0].get('selected');
      let marker = isSelected ? StationSelectedMarker : StationDefaultMarker;
      style = new Style({
        image: new Icon({
          src: marker,
        }),
      });
    }
    return style;
  };

  useEffect(() => {
    proj4.defs('EPSG:32633', '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs ');
    register(proj4);
    let sProjection = state.sProjection;
    let projection = getProjection(sProjection);
    let extent = [-2500000, 3500000, 3045984, 9045984];
    projection.setExtent(extent);

    let size = getWidth(extent) / 256;
    let resolutions = [],
      matrixIds = [];
    for (let z = 0; z < 21; ++z) {
      //Max 18?
      resolutions[z] = size / Math.pow(2, z);
      matrixIds[z] = sProjection + ':' + z;
    }

    let worldLayer = new Tile({
      source: new TileWMS({
        url: 'https://public-wms.met.no/verportal/verportal.map?bgcolor=0xF5F5F5',
        params: {
          VERSION: '1.1.1',
          LAYERS: 'kart',
          FORMAT: 'image/png',
          TRANSPARENT: false,
        },
        attributions: getMapHelpContent(intl),
      }),
    });

    let norwayLayerAttr = intl.formatMessage({ id: 'map_attribution_nma' });
    let norwayLayer = new Tile({
      extent: projection.getExtent(),
      source: new WMTS({
        attributions: norwayLayerAttr,
        url: 'https://opencache.statkart.no/gatekeeper/gk/gk.open_wmts?',
        layer: 'topo4graatone',
        matrixSet: sProjection,
        format: 'image/png',
        projection: projection,
        tileGrid: new TilegridWMTS({
          origin: getTopLeft(projection.getExtent()),
          resolutions: resolutions,
          matrixIds: matrixIds,
        }),
        style: 'default',
      }),
    });

    let attribution = new Attribution({
      collapsible: true,
      tipLabel: intl.formatMessage({ id: 'about_map' }),
    });

    let selectionDesc = document.createElement('p');
    let map = new OLMap({
      target: 'mapContainer',
      layers: [worldLayer, norwayLayer],
      controls: [new Zoom(), getSelectionController(selectionDesc), attribution],
      view: new View({
        projection: projection,
        center: transform([12, 64], 'EPSG:4326', sProjection),
        zoom: state.baseZoom,
      }),
    });
    setState({ ...state, map, selectionDesc });
    // eslint-disable-next-line
  }, []);

  const featuresAreAdded = useRef(false);
  useEffect(() => {
    if (state.map && state.selectionDesc && !featuresAreAdded.current && stations.length > 0) {
      addFeatureLayer(state.map, selectedStations);
      updateSelectionDesc(selectedStations.size, state.selectionDesc);
      featuresAreAdded.current = true;
    }
    // eslint-disable-next-line
  }, [state.map, stations]);

  const updateSelectionDesc = (count: number, element: HTMLElement) => {
    let text = intl.formatMessage({ id: 'stations_selected' }, { x: count, y: stationSelectLimit });
    if (element.parentElement) {
      let isAlert = element.parentElement.className.includes('alert-panel');
      if (!isAlert && count >= stationSelectLimit) {
        // add alert style if max elements
        element.parentElement.className += ' alert-panel';
      }
      if (isAlert && count < stationSelectLimit) {
        // remove alert style if less than max elements
        element.parentElement.className = element.parentElement.className.replace(' alert-panel', '');
      }
    }
    element.innerHTML = text;
  };

  const getSelectionController = (descElement: HTMLElement) => {
    let element = document.createElement('div');
    element.className = 'rotate-north ol-unselectable ol-control selection-panel';
    element.appendChild(descElement);
    return new Control({
      element: element,
    });
  };

  return (
    <div id="mapContainer" className={'map-container'}>
      <div id="featureDescription" className={'station-description'} />
    </div>
  );
};

export default MapTSComponent;

const getMapHelpContent = (intl: IntlShape) => {
  let mapHelp = '<p>' + intl.formatMessage({ id: 'map_help_text' }) + '</p>';
  mapHelp += '<p>' + intl.formatMessage({ id: 'symbols' }) + ':<br/>';
  mapHelp +=
    "<img class='symbolDescImg' src='" +
    LocationMarkerIco +
    "' ><span class='symbolDescText'>" +
    intl.formatMessage({ id: 'location_search' }) +
    '</span><br/>';
  mapHelp +=
    "<img class='symbolDescImg' src='" +
    StationDefaultMarker +
    "' ><span class='symbolDescText'>" +
    intl.formatMessage({ id: 'station' }) +
    '</span><br/>';
  mapHelp +=
    "<img class='symbolDescImg' src='" +
    ClusterMarker +
    "' ><span class='symbolDescText'>" +
    intl.formatMessage({ id: 'multiple_stations' }) +
    '</span><br/>';
  mapHelp +=
    "<img class='symbolDescImg' src='" +
    StationSelectedMarker +
    "' ><span class='symbolDescText'>" +
    intl.formatMessage({ id: 'selected_station' }) +
    '</span><br/>';
  mapHelp +=
    "<img class='symbolDescImg' src='" +
    ClusterSelectedMarker +
    "' ><span class='symbolDescText'>" +
    intl.formatMessage({ id: 'one_or_more_selected' }) +
    '</span></p>';
  return mapHelp;
};

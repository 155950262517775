import React, { RefObject, useRef } from 'react';
import Highcharts, { Chart, SeriesColumnOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsData from 'highcharts/modules/data';
import HighchartsExport from 'highcharts/modules/exporting';
import { Windrose } from '../../utils/WindroseUtils';
import { useIntl } from 'react-intl';
import { WindrosePeriod, WindrosePeriodOptions } from '@Types/WindrosePeriod';

HighchartsData(Highcharts);
HighchartsMore(Highcharts);
HighchartsExport(Highcharts);

interface Props {
  data: windrose.Data;
  language: string;
  recurringMonths: number[];
  period: WindrosePeriod;
}

export const WindroseGraph: React.FC<Props> = ({ data, language, recurringMonths, period }) => {
  const chartRef = useRef<{ chart: Chart; container: RefObject<HTMLDivElement> }>(null);
  const intl = useIntl();

  let desc = language.toLowerCase().startsWith('en') ? Windrose.enDescription : Windrose.nbDescription;
  let fromTime = new Date(data.metadata.fromTime);
  let toTime = new Date(data.metadata.toTime);
  let fromTitle = fromTime.getMonth() + 1 + '.' + fromTime.getFullYear();
  let toTitle = toTime.getMonth() + 1 + '.' + toTime.getFullYear();
  let silent = data.extras ? data.extras.find((ex) => ex.title === 'silentWind') : { title: 'silentWind', value: 0 };

  let series: Array<SeriesColumnOptions> = [];
  for (let i = 0; i < data.verticalAxis.titles.length; i++) {
    let seriesData = [];
    let seriesName =
      desc.beaufort[i + 1] +
      ' (' +
      (intl.locale === 'nb' ? data.verticalAxis.titles[i].replaceAll('.', ',') : data.verticalAxis.titles[i]) +
      ' m/s)';
    for (let j = 0; j < data.horizontalAxis.titles.length; j++) {
      seriesData.push([desc.direction[j], data.table[i][j]]);
    }
    series.push({ data: seriesData, name: seriesName, type: 'column' });
  }
  const options: Highcharts.Options = {
    title: {
      text:
        period.option === WindrosePeriodOptions.RECURRING_PERIOD
          ? intl.formatMessage(
              { id: 'wind_rose_graph_title_recurring' },
              {
                sName: data.stationName,
                sNumber: data.metadata.stationID,
                from: fromTitle,
                to: toTitle,
                months: recurringMonths.toString(),
              },
            )
          : intl.formatMessage(
              { id: 'wind_rose_graph_title' },
              { sName: data.stationName, sNumber: data.metadata.stationID, from: fromTitle, to: toTitle },
            ),
    },
    subtitle: {
      text: intl.formatMessage(
        { id: 'wind_rose_graph_subtitle' },
        { calm: intl.locale === 'nb' ? silent?.value.toString().replace('.', ',') : silent?.value },
      ),
    },
    series: series,
    chart: Windrose.chart,
    legend: Windrose.desktopLegend,
    xAxis: Windrose.xAxis,
    yAxis: Windrose.yAxis,
    tooltip: Windrose.tooltip,
    colors: Windrose.colors,
    plotOptions: Windrose.plotOptions,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            chart: Windrose.mobileChart,
            legend: Windrose.mobileLegend,
          },
        },
      ],
    },
  };

  Highcharts.setOptions({
    lang: {
      decimalPoint: intl.locale === 'nb' ? ',' : '.',
    },
  });

  return <HighchartsReact highcharts={Highcharts} options={options} immutable={true} ref={chartRef} />;
};

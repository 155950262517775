/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';

const getClient = (url) => {
  const options = {
    baseURL: url,
  };

  const client = axios.create(options);
  return client;
};

export default {
  // Provide request methods with the default base_url
  get(baseUrl, suffix, conf = {}) {
    return getClient(baseUrl)
      .get(suffix, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  post(baseUrl, suffix, data, config) {
    return getClient(baseUrl)
      .post(suffix, data, config)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};

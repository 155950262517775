import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import LightTooltip from '../InfoBox/LightTooltip';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as InfoIcon } from '../../images/infoIcon.svg';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    gridContainer: {
      marginTop: '2em',
      marginBottom: '2em',
    },
  },
});

function SelectTimeResolution(props) {
  const { classes, onClickHandler, selectedResolution } = props;

  return (
    <Grid container direction={'column'} className={classes.gridContainer}>
      <Grid item xs={12} md={6}>
        <InputLabel htmlFor={props.labelId + '_input'}>
          <FormattedMessage id={props.labelId} />
          <LightTooltip
            enterTouchDelay={50}
            leaveTouchDelay={20000}
            title={<Typography>{props.intl.formatMessage({ id: 'info_text_time_resolution' })}</Typography>}
            interactive
            placement="bottom-start"
          >
            <IconButton aria-label="info" size={'small'}>
              <InfoIcon style={{ width: 20 }} />
            </IconButton>
          </LightTooltip>
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true}>
          <Select value={selectedResolution} onChange={onClickHandler} input={<OutlinedInput labelWidth={0} />}>
            <MenuItem value={'minutes'}>
              <FormattedMessage id="minutes" />
            </MenuItem>
            <MenuItem value={'hours'}>
              <FormattedMessage id="hours" />
            </MenuItem>
            <MenuItem value={'days'}>
              <FormattedMessage id="days" />
            </MenuItem>
            <MenuItem value={'months'}>
              <FormattedMessage id="months" />
            </MenuItem>
            <MenuItem value={'seasons'}>
              <FormattedMessage id="seasons" />
            </MenuItem>
            <MenuItem value={'years'}>
              <FormattedMessage id="years" />
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

SelectTimeResolution.propTypes = {
  classes: PropTypes.object.isRequired,
  labelId: PropTypes.string.isRequired,
  selectedResolution: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

export default injectIntl(withStyles(styles)(SelectTimeResolution));

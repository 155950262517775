import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import metLogo from '../../images/met.png';
import nveLogo from '../../images/nve.png';
import norceLogo from '../../images/norce.png';
import bncLogo from '../../images/bnc2.png';
import { Grid, Link as MuiLink } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    root: {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexShrink: 0,
    },
    headerSpace: {
      paddingRight: '0.5em',
    },
    kssLogo: {
      display: 'flex',
      maxWidth: '20em',
      textAlign: 'center',
      width: 300,
    },
    alignment: {
      fontSize: '1.5rem',
      color: 'inherit',
    },
    logos: {
      display: 'inline-block',
      maxHeight: '1.43em',
      paddingRight: '0.5em',
    },
    line: {
      width: '20em',
      border: 0,
      height: '1px',
      background: '#FFF',
    },

    gridAlignment: {
      margin: '0 auto',
    },
    contactLink: {
      marginTop: '1em',
      textAlign: 'center',
    },
  },
  [theme.breakpoints.up('md')]: {
    gridAlignment: {
      margin: '0',
    },
    alignment: {
      fontSize: '2rem',
    },
    logos: {
      maxHeight: '2rem',
      paddingRight: '0.5em',
    },
    line: {
      width: '100%',
    },
    kssLogo: {
      display: 'flex',
      maxWidth: '100%',
      textAlign: 'inherit',
    },
    contactLink: {
      marginTop: '0',
      textAlign: 'right',
    },
  },
});

/**
 * @return {boolean}
 */
function Footer(props) {
  const { classes } = props;
  let history = useHistory();
  return (
    <footer className={classes.root}>
      <div className={'global-root'}>
        <Grid container direction={'column'}>
          <Grid item xs={12} className={classes.gridAlignment + ' ' + classes.kssLogo}>
            <FormattedMessage id={'logoKss'}>
              {(...fragments) => (
                <img className={classes.kssLogo} src={process.env.PUBLIC_URL + fragments} alt={'KSS logo'} />
              )}
            </FormattedMessage>
          </Grid>
          <Grid item xs={12} className={classes.gridAlignment}>
            <hr className={classes.line} />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={9} className={classes.gridAlignment + ' ' + classes.kssLogo}>
              <Link to={'/met'}>
                <img className={classes.logos} src={metLogo} alt="met logo" />
              </Link>
              <Link to={'/nve'}>
                <img className={classes.logos} src={nveLogo} alt="nve logo" />
              </Link>
              <Link to={'/uni'}>
                <img className={classes.logos} src={norceLogo} alt="norce logo" />
              </Link>
              <Link to={'/bnc'}>
                <img className={classes.logos} src={bncLogo} alt="bnc logo" />
              </Link>
            </Grid>
            <Grid item container xs={12} sm={12} md={3} className={classes.contactLink}>
              <Grid container>
                <MuiLink color="inherit" underline="none" href="https://klimaservicesenter.no/kss/om-oss/personvern">
                  <FormattedMessage id="privacy_policy" />
                </MuiLink>
              </Grid>
              <Grid container>
                <Link
                  to={'/contact'}
                  onClick={() => history.push('/contact')}
                  style={{ textDecoration: 'auto', color: 'white' }}
                >
                  <FormattedMessage id="contact_link" />
                </Link>
              </Grid>
              <Grid container>
                <MuiLink
                  rel="noopener"
                  target="_blank"
                  color="inherit"
                  underline="none"
                  href="https://uustatus.no/nb/erklaringer/publisert/181bc5c8-8de3-4571-ab54-c49aa4aecdc8"
                >
                  Tilgjengelighetserklæring
                </MuiLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);

import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { FormattedMessage, useIntl } from 'react-intl';
import { StationSearchField, StationsMap } from '../Stations/StationSearchField';
import { fetchLocationSearch } from '../../utils/stationsUtils';
import { GeoLocation } from '@Types/GeoLocation';
import { SelectedStations } from './CountyStationList';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up('xs')]: {
    gridContainer: {
      marginTop: '0em',
      marginBottom: '1em',
      padding: '1em 2em',
    },
    chip: {
      margin: theme.spacing(),
    },
    mapIcon: {
      display: 'inline-block',
      width: '1.5em',
    },
    searchComponent: {
      flexGrow: 1,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  [theme.breakpoints.up('sm')]: {
    paper: {
      width: '50%',
      maxHeight: '40%',
    },
    mapIcon: {
      width: '2em',
    },
  },
}));

const SearchMap: FC<{
  fetchStationsMap: (searchString: string) => void;
  clearStationsMap: () => void;
  stationsMap: StationsMap;
  selectedStations: SelectedStations;
  setSelectedStations: (selectedStations: SelectedStations) => void;
  handleSelectStation: (lat: number, long: number, isStation: boolean) => void;
  apiUrl: string;
}> = (props) => {
  const [geoLocations, setGeoLocations] = useState<GeoLocation[]>([]);

  const clearSearch = () => {
    setGeoLocations([]);
    props.clearStationsMap();
  };

  const fetchLocation = async (searchString: string) => {
    const data = await fetchLocationSearch(props.apiUrl, searchString);
    let norwegianResults: GeoLocation[] = [];
    data?.response?.docs?.forEach((geoLocation) => {
      if (geoLocation.country_code?.[0] === 'NO') {
        norwegianResults.push(geoLocation);
      }
    });

    setGeoLocations(norwegianResults);
  };

  const fetchCombined = (searchString: string) => {
    fetchLocation(searchString);
    props.fetchStationsMap(searchString);
  };

  const handleSelectStation = (item: any) => {
    const { result_category } = item;
    if (result_category) {
      props.handleSelectStation(item.lat, item.long, false);
    } else {
      let newSelectedStations = new Map(props.selectedStations);
      if (!newSelectedStations.has(item.id)) {
        newSelectedStations.set(item.id, item.name);
        props.setSelectedStations(newSelectedStations);
        if (item.coordinates) {
          props.handleSelectStation(item.geometry.coordinates[1], item.geometry.coordinates[0], true);
        }
      }
      props.clearStationsMap();
    }
  };

  const classes = useStyles();
  const intl = useIntl();
  return (
    <div>
      <Grid container direction={'column'} className={classes.gridContainer}>
        <Grid item xs={12}>
          <InputLabel>
            <FormattedMessage id="map_search" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={12}>
          <StationSearchField
            stationMap={props.stationsMap}
            selectedStations={props.selectedStations}
            geoLocations={geoLocations}
            handleSelectedStation={handleSelectStation}
            fetchStationsMap={fetchCombined}
            clearElementMap={clearSearch}
            placeHolder={intl.formatMessage({ id: 'search_placeholder' })}
            limitSelection={{
              hasLimit: true,
              maxSelectedElements: 50,
              maxSelectedMessage: intl.formatMessage({ id: 'max_stations_selected' }),
            }}
            dropDownWidth={'100%'}
            dropDownMaxWidth={'98%'}
            noStationsSelectedAtSearch={false} // we dont need warning in map
            mapMode={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchMap;

import { Collapse, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Alert, AlertTitle, Color } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      marginTop: theme.spacing(1),
    },
    title: {
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
  }),
);

interface Props {
  displayAlert: boolean;
  onClose: () => void;
  alertTitle: string;
  message?: string;
  severity?: Color;
}
/**
 *
 * @param displayAlert Display alert tile
 * @param onClose Close alert tile
 * @param AlertTitle Title of alert
 * @param message Message for tile
 * @param severity 'error'| 'warning' | 'info' | 'success'
 * @returns
 */
export const AlertTile: React.FC<Props> = ({ displayAlert, onClose, alertTitle, message, severity }) => {
  const classes = useStyles();
  return (
    <Collapse in={displayAlert}>
      <Alert
        className={classes.box}
        severity={severity ? severity : 'error'}
        onClose={() => {
          onClose();
        }}
      >
        <AlertTitle className={classes.title}>{alertTitle}</AlertTitle>
        {message}
      </Alert>
    </Collapse>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import handleExport from '../Observations/helper/exportHandler';
import { ReactComponent as DownloadIcon } from '../../images/downloadIcon.svg';
import { injectIntl } from 'react-intl';

const styles = (theme) => ({
  panelIcons: {
    width: 20,
    height: 'auto',
    paddingRight: theme.spacing(),
    display: 'inline-block',
  },
});
function ItemDialog(props) {
  const { onClose, open, items, data, resolution, intl, classes } = props;

  function handleClose() {
    onClose();
  }

  function handleListItemClick(value) {
    handleExport(value, data, resolution, intl);
    onClose();
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{intl.formatMessage({ id: 'download_format' })}</DialogTitle>
      <List>
        {items.map((item) => (
          <ListItem button onClick={() => handleListItemClick(item)} key={item}>
            <DownloadIcon className={classes.panelIcons}></DownloadIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

ItemDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  resolution: PropTypes.string.isRequired,
};
export default injectIntl(withStyles(styles)(ItemDialog));

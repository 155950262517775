import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { SelectDate } from '../SelectDate';

enum periodOptions {
  OPERATIONAL_STATIONS = 'operational_stations',
  CUSTOM_PERIOD = 'custom_period',
}

interface StationPeriodSelectProps {
  setPeriodUri: (uri: string) => void;
}

export const StationPeriodSelect: React.FC<StationPeriodSelectProps> = ({ setPeriodUri }) => {
  const intl = useIntl();
  const [selectedPeriod, setSelectedPeriod] = React.useState<
    periodOptions.OPERATIONAL_STATIONS | periodOptions.CUSTOM_PERIOD
  >(periodOptions.OPERATIONAL_STATIONS);
  const [fromDate, setFromDate] = React.useState(moment());
  const [toDate, setToDate] = React.useState(moment());
  const [currentQuery, setCurrentQuery] = React.useState('');
  const handlePeriodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPeriod(event.target.value as periodOptions);
  };

  React.useEffect(() => {
    if (selectedPeriod === periodOptions.OPERATIONAL_STATIONS) {
      if (currentQuery.length) setCurrentQuery('');
    } else {
      let newQuery = 'from=' + fromDate.utc(true).format('YYYY-MM-DD') + '&to=' + toDate.utc(true).format('YYYY-MM-DD');
      if (newQuery !== currentQuery) setCurrentQuery(newQuery);
    }
  }, [fromDate, toDate, selectedPeriod, currentQuery]);

  React.useEffect(() => {
    setPeriodUri(currentQuery);
  }, [currentQuery, setPeriodUri]);

  return (
    <Grid container direction={'column'}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth={true} variant="outlined">
          <Select value={selectedPeriod} onChange={handlePeriodChange}>
            <MenuItem value={periodOptions.OPERATIONAL_STATIONS}>
              {intl.formatMessage({ id: periodOptions.OPERATIONAL_STATIONS })}
            </MenuItem>
            <MenuItem value={periodOptions.CUSTOM_PERIOD}>
              {intl.formatMessage({ id: periodOptions.CUSTOM_PERIOD })}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {selectedPeriod === periodOptions.CUSTOM_PERIOD && (
        <>
          <SelectDate
            selectedDate={fromDate}
            setDate={setFromDate}
            labelId={'from'}
            views={['year']}
            format={'YYYY'}
            minDate={moment(new Date('1000-01-01'))}
          />
          <SelectDate
            selectedDate={toDate}
            setDate={setToDate}
            labelId={'to'}
            views={['year']}
            format={'YYYY'}
            minDate={fromDate}
          />
        </>
      )}
    </Grid>
  );
};

import { BodyCells } from 'components/SorteableTable/SortableTableTypes';
import moment, { Moment } from 'moment';
import { IntlShape } from 'react-intl';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { HeaderCells } from './SortableTableTypes';

interface Text {
  [key: string]: string;
}

export default function exportTable(
  type: string,
  bodyCells: BodyCells[],
  headerCells: HeaderCells[],
  title: string,
  intl: IntlShape,
  dateFormat: string,
) {
  var ws = XLSX.utils.aoa_to_sheet([[' '], [title]]);
  let table = [];
  for (let i = 0; i < bodyCells.length; i++) {
    let obj: Text = {};
    for (const [key, value] of Object.entries(bodyCells[i])) {
      let text = '';
      if (value.label) {
        text = value.label;
      } else if (value.value instanceof moment) {
        text = (value.value as Moment).format(dateFormat);
      } else {
        text = value.value.toString();
      }
      let useKey = key;
      for (let i = 0; i < headerCells.length; i++) {
        if (headerCells[i].id === key) {
          useKey = headerCells[i].label;
          break;
        }
      }
      obj[useKey] = text;
    }
    table.push(obj);
  }
  XLSX.utils.sheet_add_json(ws, JSON.parse(JSON.stringify(table)), { origin: 'A6' });
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        intl.formatMessage({ id: 'met_data_licence_pt1' }) +
          moment().format('DD.MM.YYYY') +
          intl.formatMessage({ id: 'met_data_licence_pt2' }),
      ],
    ],
    { origin: -1 },
  );

  /* add to workbook */
  var wb = XLSX.utils.book_new();
  if (!wb.Props) wb.Props = {};
  XLSX.utils.book_append_sheet(wb, ws, 'Table');

  /* generate an XLSX file */

  if (type === 'csv') {
    /* tslint:disable-next-line */
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ';' });
    const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });
    saveAs(blob, `table.csv`);
  } else {
    XLSX.writeFile(wb, 'table.' + type);
  }
}

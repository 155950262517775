/* eslint-disable import/no-anonymous-default-export */
import moment from 'moment';

/**
 * ############ Timezones in seklima ############
 * Seklima is currently using 3 types of timezones:
 * UTC - all calls from frost return UTC time
 * Europe/Oslo (localtime) - we display graphs/tables in localtime
 * GMT +1 - On file download owner wants timezone in norwegian mean time.
 *
 * Sadly frost sometimes take timezone into account, and other times are set to UTC 00:00 to mark it being that day.
 * making conversion between dates messy.
 *
 */
export const timeResolution = Object.freeze({
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
  SEASONS: 'seasons',
});

export default {
  resetTime(moment, resolution, dateVar) {
    if (!moment || !resolution) {
      return moment;
    }
    if (timeResolution.MINUTES === resolution) {
      dateVar === 'selectedFrom'
        ? moment.set({ second: 0, millisecond: 0 })
        : moment.set({ second: 59, millisecond: 99 });
      return moment;
    } else if (timeResolution.HOURS === resolution) {
      dateVar === 'selectedFrom'
        ? moment.set({ minute: 0, second: 0, millisecond: 0 })
        : moment.set({ minute: 59, second: 59, millisecond: 99 });
    } else {
      dateVar === 'selectedFrom'
        ? moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        : moment.set({ hour: 23, minute: 59, second: 59, millisecond: 99 });
    }
    if (timeResolution.DAYS === resolution) {
      dateVar === 'selectedFrom' ? moment.startOf('day') : moment.endOf('day');
    }
    if (timeResolution.MONTHS === resolution || timeResolution.SEASONS === resolution) {
      dateVar === 'selectedFrom' ? moment.startOf('month') : moment.endOf('month');
    } else if (timeResolution.YEARS === resolution) {
      dateVar === 'selectedFrom' ? moment.startOf('year') : moment.endOf('year');
    }
    return moment;
  },

  numberOfObservations(resolution, from, to, nElements, nStations) {
    let numTimesteps = 0;

    if (timeResolution.MINUTES === resolution) {
      numTimesteps = moment.duration(to.diff(from)).asMinutes();
    } else if (timeResolution.HOURS4 === resolution) {
      numTimesteps = moment.duration(to.diff(from)).asHours();
    } else if (timeResolution.DAYS === resolution) {
      numTimesteps = moment.duration(to.diff(from)).asDays();
    } else if (timeResolution.MONTHS === resolution) {
      numTimesteps = moment.duration(to.diff(from)).asMonths();
    } else if (timeResolution.YEARS === resolution) {
      numTimesteps = moment.duration(to.diff(from)).asYears();
    }
    return Math.ceil(numTimesteps) * nElements * nStations;
  },

  /**
   * Format: YYYY-MM-DD/YYYY-MM-DD for all data within period
   * Recurring period: Rn/YYYY-MM-DD/YYYY-MM-DD/P1Y, Where Rn is the number of recurrences. At the moment we are
   * only using the period P1Y, but this can be changed to eg monthly values, see: https://frost.met.no/concepts#time_specification
   * for mor information
   * @returns {[]}
   */

  getRefrenceTime(selectedPeriod, selectedFrom, selectedTo, selectedResolution) {
    let fromTmp = selectedFrom.clone();
    let toTmp = selectedTo.clone();
    if (selectedPeriod === 'recurring_period' && selectedTo.year() - selectedFrom.year()) {
      // Recurring time is not split due to complex logic and might lead complex to issues
      let recurrences = 1 + selectedTo.year() - selectedFrom.year();
      toTmp.set('year', fromTmp.year());
      if (fromTmp.month() > toTmp.month()) toTmp.add('years', 1);
      // Setting utc(true) makes moment not take timezone into account when formatting.
      return 'R' + recurrences + '/' + fromTmp.utc(true).format() + '/' + toTmp.utc(true).format() + '/P1Y';
    } else {
      if (selectedPeriod === 'custom_period') {
        /* fromTmp.startOf('day');
        toTmp.endOf('day');
        toTmp.add(1, 'seconds');
        fromTmp.subtract(1, 'seconds'); */
        return fromTmp.utc(true).format() + '/' + toTmp.utc(true).format();
      }
      if (selectedResolution === timeResolution.MINUTES || selectedResolution === timeResolution.HOURS) {
        return moment.utc(fromTmp).format() + '/' + moment.utc(toTmp).format();
      }
      return fromTmp.utc(true).format() + '/' + toTmp.utc(true).format();
    }
  },
  /*
  getChunkedReferenceTime(selectedPeriod, selectedFrom, selectedTo, numObservations, selectedResolution) {
    // Max from frost is 100.000, but > 20.000 values will give slower total response time if not split
    const MAX_OBS_COUNT = 20000;
    let fromTmp = selectedFrom.clone();
    let toTmp = selectedTo.clone();
    let refTimes = [];
    if (selectedResolution === timeResolution.MINUTES || selectedResolution === timeResolution.HOURS) {
      if (selectedPeriod === 'custom_period') {
        fromTmp.startOf('day');
        toTmp.endOf('day');
      }
      if (numObservations > MAX_OBS_COUNT) {
        //hard set to to +1 year from current date. This is to make frost happy
        if (selectedPeriod === 'custom_period' && toTmp.diff(moment.now()) > 0) {
          toTmp = moment(moment.now()).add(1, 'year');
        }
        let splitCount = Math.round(numObservations / MAX_OBS_COUNT) + 1;
        let diffSec = moment.duration(toTmp.diff(fromTmp)).asSeconds();
        let intervalSec = Math.round(diffSec / splitCount);
        for (let i = 0; i < splitCount; i++) {
          let nextTo = fromTmp.clone();
          nextTo.add(intervalSec, 'seconds');
          //do not push if from is in future
          if (fromTmp.diff(moment.now()) <= 0) {
            refTimes.push(fromTmp.utc().format() + '/' + nextTo.utc().format());
          }
          fromTmp.add(intervalSec, 'seconds');
        }
      } else {
        refTimes.push(fromTmp.utc().format() + '/' + toTmp.utc().format());
      }
    } else {
      fromTmp.startOf('day');
      toTmp.endOf('day');
      if (selectedPeriod === 'recurring_period' && selectedTo.year() - selectedFrom.year()) {
        // Recurring time is not split due to complex logic and might lead complex to issues
        let recurrences = 1 + selectedTo.year() - selectedFrom.year();
        toTmp.set('year', fromTmp.year());
        if (fromTmp.month() > toTmp.month()) toTmp.add('years', 1);
        // Setting utc(true) makes moment not take timezone into account when formatting.
        refTimes.push('R' + recurrences + '/' + fromTmp.utc(true).format() + '/' + toTmp.utc(true).format() + '/P1Y');
      } else if (numObservations > MAX_OBS_COUNT) {
        if (selectedPeriod === 'custom_period' && toTmp.diff(moment.now()) > 0) {
          toTmp = moment(moment.now()).add(1, 'year');
        }
        let splitCount = Math.round(numObservations / MAX_OBS_COUNT) + 1;
        let diffSec = moment.duration(selectedTo.diff(selectedFrom)).asSeconds();
        let intervalSec = Math.round(diffSec / splitCount);
        for (let i = 0; i < splitCount; i++) {
          //do not push if from is in future
          let nextTo = fromTmp.clone();
          nextTo.add(intervalSec, 'seconds');
          nextTo.endOf('day');
          if (fromTmp.diff(moment.now()) <= 0) {
            refTimes.push(fromTmp.utc(true).format() + '/' + nextTo.utc(true).format());
          }
          fromTmp.add(intervalSec, 'seconds');
          fromTmp.add(1, 'days'); // no overlap
          fromTmp.startOf('day');
        }
      } else {
        refTimes.push(fromTmp.utc(true).format() + '/' + toTmp.utc(true).format());
      }
    }
    return refTimes;
  },*/

  timePeriodDateChanger(period, resolution) {
    // subtract one unit to ensure correct start time
    let fromTime = moment();
    switch (resolution) {
      case timeResolution.MINUTES:
        fromTime.subtract(1, 'minutes');
        break;
      case timeResolution.HOURS:
        fromTime.subtract(1, 'hours');
        break;
      case timeResolution.DAYS:
        fromTime.startOf('day');
        break;
      case timeResolution.MONTHS:
        fromTime.startOf('month');
        break;
      case timeResolution.SEASONS:
        fromTime.startOf('month');
        break;
      case timeResolution.YEARS:
        fromTime.startOf('year');
        break;
      default:
        break;
    }

    switch (period) {
      case 'last_hour':
        return fromTime.subtract(1, 'hours');
      case 'last_24_hours':
        return fromTime.subtract(1, 'days');
      case 'last_7_days':
        return fromTime.subtract(6, 'days');
      case 'last_30_days':
        return fromTime.subtract(29, 'days');
      case 'last_6_months':
        return fromTime.subtract(6, 'months');
      case 'last_12_months':
        return fromTime.subtract(1, 'years');
      case 'last_5_years':
        return fromTime.subtract(5, 'years');
      case 'all_years':
        return fromTime.year(1700);
      default:
        return fromTime.startOf('day');
    }
  },
  getTimeFormat(currentResolution) {
    switch (currentResolution) {
      case timeResolution.MINUTES:
        return 'DD.MM.YYYY HH:mm';
      case timeResolution.HOURS:
        return 'DD.MM.YYYY HH:mm';
      case timeResolution.DAYS:
        return 'DD.MM.YYYY';
      case timeResolution.MONTHS:
        return 'MM.YYYY';
      case timeResolution.SEASONS:
        return 'MM.YYYY';
      case timeResolution.YEARS:
        return 'YYYY';
      default:
        return 'DD.MM.YYYY';
    }
  },
  timeResolution,
};

import TableRow from '@material-ui/core/TableRow/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableSortLabel from '@material-ui/core/TableSortLabel/index';
import React from 'react';
import PropTypes from 'prop-types';

function TableHeadRow(props) {
  const { headRow, order, orderBy, handleRequestSort } = props;
  return (
    <TableRow>
      {headRow.map((row) => (
        <TableCell key={row.id} sortDirection={orderBy === row.id ? order : false}>
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={() => handleRequestSort(row.id)}
            style={{ fontWeight: 'bold' }}
          >
            {row.label}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  );
}

TableHeadRow.propTypes = {
  headRow: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
};

export default TableHeadRow;

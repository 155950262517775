import React from 'react';
import {
  createStyles,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import TabContainer from 'components/TabContainer';
import { WindroseGraph } from './WindroseGraph';
import moment from 'moment';
import { SortableTable } from 'components/SorteableTable/SortableTable';
import { getHeaderCells, getTableCells, getTableTitle } from './WindroseTable';
import { ShareButton } from 'components/ShareButton/ShareButton';
import { WindrosePeriod } from '@Types/WindrosePeriod';
import LightTooltip from '../InfoBox/LightTooltip';
import { ReactComponent as InfoIcon } from '../../images/infoIcon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('xs')]: {
      gridContainer: {
        marginBottom: '2em',
      },
      hideGridContainer: {
        display: 'none',
      },
    },
    panelIcons: {
      width: 20,
      height: 'auto',
      paddingRight: theme.spacing(),
      display: 'inline-block',
    },
    tabRoot: {
      textTransform: 'initial',
      minWidth: 72,
      width: '100%',
      fontWeight: 'normal',
      marginRight: theme.spacing(4),
    },
    tabSelected: {
      border: '1px solid #979797',
      backgroundColor: 'white',
    },
    tabWrapper: {
      borderTop: '1px solid #979797',
      borderBottom: '1px solid #979797',
      marginTop: '-26px',
      backgroundColor: '#F3F9F5',
    },
    tabContent: {
      width: '100%',
      display: 'inline',
    },
    shareButton: {
      padding: theme.spacing(2),
    },
    infoBox: {
      width: '100%',
      display: 'inline-block',
    },
  }),
);

interface Props {
  data: windrose.Data;
  recurringMonths: number[];
  period: WindrosePeriod;
}

export const WindroseDataPanel: React.FC<Props> = ({ data, recurringMonths, period }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [currentTab, setCurrentTab] = React.useState<number>(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, value: any) => {
    setCurrentTab(parseInt(value));
  };

  const infoBox = (messageId: string) => {
    return (
      <div className={classes.infoBox}>
        <LightTooltip
          style={{ float: 'right' }}
          enterTouchDelay={50}
          leaveTouchDelay={20000}
          title={<Typography>{intl.formatMessage({ id: messageId })}</Typography>}
          interactive
          placement="bottom-start"
        >
          <IconButton aria-label="info">
            <InfoIcon style={{ width: 20 }} />
          </IconButton>
        </LightTooltip>
      </div>
    );
  };

  return (
    <Grid container alignItems={'center'} alignContent={'center'} className={classes.gridContainer}>
      <FormControl fullWidth={true}>
        <Tabs
          className={classes.tabRoot}
          value={currentTab}
          onChange={handleChangeTab}
          centered
          indicatorColor={'primary'}
          textColor={'primary'}
        >
          <Tab label={intl.formatMessage({ id: 'graph' })} className={classes.tabSelected} disableRipple />
          <Tab label={intl.formatMessage({ id: 'table' })} className={classes.tabSelected} disableRipple />
        </Tabs>
        <div className={classes.tabWrapper}>
          {currentTab === 0 && (
            <TabContainer>
              {infoBox('windrose_graph_infobox')}
              <div className={classes.tabContent}>
                <Paper>
                  <WindroseGraph data={data} language={intl.locale} recurringMonths={recurringMonths} period={period} />
                  <div className={classes.shareButton}>
                    <ShareButton />
                  </div>
                </Paper>
              </div>
            </TabContainer>
          )}
          {currentTab === 1 && (
            <TabContainer>
              {infoBox('windrose_table_infobox')}
              <div className={classes.tabContent}>
                <SortableTable
                  dense
                  title={getTableTitle(intl, data, recurringMonths, period)}
                  headerCells={getHeaderCells(intl)}
                  bodyCells={getTableCells(intl, data)}
                  orderByDefault={'wind_speed'}
                  rowsPerPageDefault={25}
                  labelRowsPerPage={intl.formatMessage({ id: 'label_rows_per_page' })}
                  displayShareButton
                />
              </div>
            </TabContainer>
          )}
        </div>
      </FormControl>
      <Typography>
        <FormattedMessage id="met_data_licence_pt1" />
        {moment().format('DD.MM.YYYY')}
        <FormattedMessage id="met_data_licence_pt2" />
      </Typography>
    </Grid>
  );
};

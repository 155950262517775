import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ObservationSource } from '@Types/ObservationSource';
import { capitalizeAllWords, capitalizeStationName } from 'utils/stringUtils';
import ShipIcon from '@material-ui/icons/DirectionsBoat';
import { findObservationSourceType, ObservationSourceType } from 'utils/stationsUtils';
import moment from 'moment';
import { AlertTile } from 'components/Alert/AlertTile';
import {
  DisplayPeroid,
  LocaleMesurementPeriod,
  StationDetailsWeatherElementTable,
} from './StationDetailsWeatherElementTable';
import '@fontsource/roboto';
import { LocaleEnum } from 'utils/localizationUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('xs')]: {
      root: {
        lineHeight: '1.1em',
        marginTop: '0.5em',
      },
      headerHr: {
        border: '0px',
        height: '1px',
        backgroundColor: '#AAAAAA',
      },
      detailContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid gray',
        fontFamily: 'Roboto',
      },
      detailField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
  }),
);

interface Props {
  stations: ObservationSource[];
  missingStations: Array<String>;
  apiBaseUrl: string;
}

export const StationDetailsBlock: React.FC<Props> = ({ stations, missingStations, apiBaseUrl }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [displayMissing, setDisplayMissing] = React.useState<boolean>(false);
  const [displayMesurmentPeriods, setDisplayMesurmentPeriods] = React.useState<DisplayPeroid>();
  const [stationMesurementPeriod, setStationMesurementPeriod] = React.useState<LocaleMesurementPeriod>({
    [LocaleEnum.en]: {},
    [LocaleEnum.nb]: {},
  });

  React.useEffect(() => {
    if (missingStations.length > 0) {
      setDisplayMissing(true);
    } else {
      setDisplayMissing(false);
    }
  }, [missingStations]);

  const stationLocation = (station: ObservationSource, latitude: boolean) => {
    if (station && station.geometry && station.geometry.coordinates && station.geometry.coordinates.length > 1) {
      return latitude ? station.geometry.coordinates[1] : station.geometry.coordinates[0];
    }
    return null;
  };

  const stationIcon = (station: ObservationSource) => {
    if (findObservationSourceType(station) === ObservationSourceType.Ship)
      return <ShipIcon style={{ width: 30, verticalAlign: 'text-bottom' }} />;
    return null;
  };

  const stationOperationPeriod = (station: ObservationSource) => {
    let pStr = '';
    if (station.validFrom) {
      pStr += moment(station.validFrom).format('DD.MM.YYYY');
    }
    if (station.validTo) {
      if (new Date(station.validTo).getTime() === new Date('0001-01-01T00:00:00Z').getTime()) {
        pStr += ' - ' + intl.formatMessage({ id: 'now' });
      } else {
        pStr += ' - ' + moment(station.validTo).format('DD.MM.YYYY');
      }
    }
    return pStr ? pStr : intl.formatMessage({ id: 'unknown' });
  };

  const showMunicipality = (station: ObservationSource) => {
    if (findObservationSourceType(station) === ObservationSourceType.Ship || !station.municipality) {
      return false;
    } else {
      return true;
    }
  };

  const stationItems = () => {
    return [
      stations.map((station, index) => {
        return (
          <div key={'container_' + station.id} className={classes.detailContainer}>
            <Typography variant={'h6'}>
              {stationIcon(station)} {capitalizeStationName(station.name)}
            </Typography>
            {showMunicipality(station) && (
              <div className={classes.detailField}>
                {intl.formatMessage({ id: 'municipality' })}: {capitalizeAllWords(station.municipality)}
              </div>
            )}
            {station.county && (
              <div className={classes.detailField}>
                {intl.formatMessage({ id: 'county' })}: {capitalizeAllWords(station.county)}
              </div>
            )}
            {station.id && (
              <div className={classes.detailField}>
                {intl.formatMessage({ id: 'station_number' })}: {station.id}
              </div>
            )}
            {findObservationSourceType(station) !== ObservationSourceType.Ship && (
              <div className={classes.detailField}>
                {intl.formatMessage({ id: 'height_above_sea_level' })}: {station.masl} m
              </div>
            )}
            {stationLocation(station, true) && (
              <div className={classes.detailField}>
                {intl.formatMessage({ id: 'latitude' })}: {stationLocation(station, true)}º{' '}
                {intl.formatMessage({ id: 'latitude_mesurement' })}
              </div>
            )}
            {stationLocation(station, false) && (
              <div className={classes.detailField}>
                {intl.formatMessage({ id: 'longitude' })}: {stationLocation(station, false)}º{' '}
                {intl.formatMessage({ id: 'longitude_mesurement' })}
              </div>
            )}
            <div className={classes.detailField}>
              {intl.formatMessage({ id: 'operating_period' })}: {stationOperationPeriod(station)}
            </div>
            {station.wmoId !== 0 && (
              <div className={classes.detailField}>
                {intl.formatMessage({ id: 'wmo_number' })}: {station.wmoId}
              </div>
            )}
            {station.wigosId && (
              <div className={classes.detailField}>
                {intl.formatMessage({ id: 'wigos_number' })}: {station.wigosId}
              </div>
            )}
            {station.stationHolders.length > 0 && (
              <div className={classes.detailField}>
                {intl.formatMessage({ id: 'station_holder' })}:{' '}
                {capitalizeStationName(station.stationHolders.join(', '))}
              </div>
            )}
            {!stationLocation(station, true) && (
              <div className={classes.detailField}>{intl.formatMessage({ id: 'geo_location_missing' })}.</div>
            )}
            <StationDetailsWeatherElementTable
              station={station}
              apiBaseUrl={apiBaseUrl}
              displayMesurmentPeriods={displayMesurmentPeriods}
              setDisplayMesurmentPeriods={setDisplayMesurmentPeriods}
              stationMesurementPeriod={stationMesurementPeriod}
              setStationMesurementPeriod={setStationMesurementPeriod}
            />
          </div>
        );
      }),
    ];
  };

  return (
    <>
      <Grid container direction={'column'}>
        <Grid item xs={12} md={6}>
          <AlertTile
            displayAlert={displayMissing}
            onClose={() => setDisplayMissing(false)}
            alertTitle={intl.formatMessage({ id: 'stations_not_operative' })}
            message={capitalizeStationName(missingStations.join(', '))}
            severity="info"
          />
        </Grid>
        <Grid item xs={12}>
          {stationItems()}
        </Grid>
      </Grid>
    </>
  );
};

/* eslint-disable import/no-anonymous-default-export */
import getElementById from './elementHandler';

export default {
  /**
   * converts observation data into a list of single value depth objects.
   * @param observationData data object used for creating a highchart graph
   * @param timeSeries all available timestamps for each station id
   * @returns {Array}
   */
  getConvertedObsData(obsData, elementMap) {
    let rows = {};
    let elementIds = {};
    // We want element name as key. This is for writing to file.
    for (let index in obsData.series) {
      elementIds[obsData.series[index].elementId] = getElementById(elementMap, obsData.series[index].elementId).name;
    }
    obsData.series.forEach((current) => {
      current.data.forEach((measurement) => {
        // Join key
        let key = [current.id, measurement[0]].join(',');
        if (!rows.hasOwnProperty(key)) {
          rows[key] = {
            name: current.name,
            station: current.id,
            time: measurement[0],
          };
          for (let value of Object.values(elementIds)) {
            rows[key][value] = '-';
          }
        }
        rows[key][elementIds[current.elementId]] = measurement[1];
      });
    });
    return Object.values(rows);
  },
};

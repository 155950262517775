import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { HeaderCells } from './SortableTableTypes';
import { Order } from './SortUtils';

interface TableHeaderRowProps {
  orderBy: any;
  order: Order;
  cells: HeaderCells[];
  onRequestSort: (property: string | number) => void;
  dense: boolean;
}

export const TableHeaderRow: React.FC<TableHeaderRowProps> = ({ cells, orderBy, order, onRequestSort, dense }) => {
  const createSortHandler = (id: string | number) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(id);
  };

  return (
    <TableHead>
      <TableRow>
        {cells.map((cell) => (
          <TableCell
            key={cell.id}
            style={{ fontWeight: 'bold', padding: dense ? '10px' : '16px' }}
            align="left"
            sortDirection={orderBy === cell.id ? order : false}
          >
            {!cell.disableSort ? (
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
              >
                {cell.label}
              </TableSortLabel>
            ) : (
              cell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

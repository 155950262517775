import React, { Component } from 'react';
import ObservationPage from './pages/ObservationPage';
import { StationsPage } from './pages/StationsPage';
import { RegionPage } from './pages/RegionPage';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import createMainTheme from './utils/createTheme';
import { fetchConfig, validateConfig } from './utils/configUtils';
import * as PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import messages from './messages.js';
import Header from './components/Header/Header';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/nb';
import MomentUtils from '@date-io/moment';
import WindRosePage from './pages/WindRosePage';
moment.locale('nb');
const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    '@global': {
      '.global-root': {
        width: '100%',
        maxWidth: '1440px',
        margin: '0px auto',
      },
    },
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
      paddingTop: '5rem',
    },
  },
  [theme.breakpoints.up('sm')]: {
    content: {
      paddingTop: '6rem',
    },
  },
});

/**
 * The entire app get generated from this container.
 * We set the material UI theme by choosing a primary and secondary color from the metMuiThemes file
 * and creating a color palette with the createTheme method.
 * For information about using the different palettes see material UI documentation
 */
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: 'nb',
      config: null,
    };
  }

  componentDidMount() {
    fetchConfig((conf) => {
      this.setState({
        config: conf,
      });
    });
  }

  render() {
    const { classes } = this.props;
    const renderMainPage = (props) => (
      <MuiThemeProvider theme={createMainTheme()}>
        <div className={classes.content}>
          <Header
            config={this.state.config}
            localeSelected={() => this.handleLocaleSelect()}
            currentLocale={this.state.locale}
          />
          <div id="main-content">
            <ObservationPage
              {...props}
              currentLocale={this.state.locale}
              localeSelected={() => this.handleLocaleSelect()}
              conf={this.state.config}
            />
          </div>
        </div>
        <Footer />
      </MuiThemeProvider>
    );
    if (!this.state.config) {
      return <div>Loading configuration ...</div>;
    }
    let validate = validateConfig(this.state.config);
    if (!validate.valid) {
      return <div>Missing configuration: {validate.missing.join(', ')}</div>;
    }
    return (
      <BrowserRouter basename={this.state.config.PUBLIC_URL || ''}>
        <div className={classes.root}>
          <IntlProvider locale={this.state.locale} messages={messages[this.state.locale]}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={this.state.locale}>
              <Switch>
                <Route
                  exact={true}
                  path={'/stations'}
                  render={() => (
                    <MuiThemeProvider theme={createMainTheme()}>
                      <div className={classes.content}>
                        <Header
                          config={this.state.config}
                          localeSelected={() => this.handleLocaleSelect()}
                          currentLocale={this.state.locale}
                        />
                        <div id="main-content">
                          <StationsPage apiBaseUrl={this.state.config.REACT_APP_API_URL} />
                        </div>
                      </div>
                      <Footer />
                    </MuiThemeProvider>
                  )}
                />

                <Route
                  exact={false}
                  path={'/windrose'}
                  render={() => (
                    <MuiThemeProvider theme={createMainTheme()}>
                      <div className={classes.content}>
                        <Header
                          config={this.state.config}
                          localeSelected={() => this.handleLocaleSelect()}
                          currentLocale={this.state.locale}
                        />
                        <div id="main-content">
                          <WindRosePage apiBaseUrl={this.state.config.REACT_APP_API_URL} />
                        </div>
                      </div>
                      <Footer />
                    </MuiThemeProvider>
                  )}
                />
                <Route
                  exact={true}
                  path={'/regions'}
                  render={() => (
                    <MuiThemeProvider theme={createMainTheme()}>
                      <div className={classes.content}>
                        <Header
                          config={this.state.config}
                          localeSelected={() => this.handleLocaleSelect()}
                          currentLocale={this.state.locale}
                        />
                        <div id="main-content">
                          <RegionPage />{' '}
                        </div>
                      </div>
                      <Footer />
                    </MuiThemeProvider>
                  )}
                />

                <Route
                  path={'/observations/:timeResolution?/:weatherElements?/:date?/:stations?/:locale?/:fromTo?'}
                  render={renderMainPage}
                />
                <Route
                  path={'/:timeResolution?/:weatherElements?/:date?/:stations?/:locale?/:fromTo?'}
                  render={renderMainPage}
                />
              </Switch>
            </MuiPickersUtilsProvider>
          </IntlProvider>
          <Route exact={true} path="/kss" component={() => (window.location = 'https://klimaservicesenter.no/')} />
          <Route exact={true} path="/met" component={() => (window.location = 'https://www.met.no/')} />
          <Route exact={true} path="/nve" component={() => (window.location = 'https://www.nve.no/')} />
          <Route exact={true} path="/uni" component={() => (window.location = 'http://uni.no/en/')} />
          <Route exact={true} path="/bnc" component={() => (window.location = 'https://www.bjerknes.uib.no/')} />
          <Route
            exact={true}
            path="/contact"
            component={() => (window.location = 'https://klimaservicesenter.no/kss/om-oss/kontakt')}
          />
        </div>
      </BrowserRouter>
    );
  }

  handleLocaleSelect() {
    let newLocale = 'en';
    if (this.state.locale.includes('en')) {
      newLocale = 'nb';
    }
    this.setState({
      locale: newLocale,
    });
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);

import { createTheme } from '@material-ui/core';
import { black_palette, green_palette } from './metMuiThemes';
import '@fontsource/roboto';

function createMainTheme() {
  return createTheme({
    palette: {
      primary: Object.assign({}, green_palette, {
        // special primary color rules can be added here
      }),
      secondary: Object.assign({}, black_palette, {
        // special secondary color rules can be added here
      }),
      // error: will use the default color
    },

    typography: {
      fontFamily: 'Roboto',
      color: '#4A4A4A',
      fontSize: 16,
      body1: {
        fontSize: 14,
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: 14,
      },
      useNextVariants: true,
    },
  });
}

export default createMainTheme;

import { createStyles, FormControl, Grid, InputLabel, makeStyles, Theme } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlertTile } from 'components/Alert/AlertTile';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: { marginTop: theme.spacing(3) },
  }),
);

interface SelectDateProps {
  selectedDate: moment.Moment;
  setDate: (mom: moment.Moment) => void;
  labelId: string;
  views?: ('year' | 'date' | 'month')[];
  format?: string;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  openTo?: 'year' | 'date' | 'month' | undefined;
  autoFocus?: boolean;
}

export const SelectDate: React.FC<SelectDateProps> = ({
  selectedDate,
  setDate,
  labelId,
  views,
  format,
  minDate,
  maxDate,
  openTo,
  autoFocus,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [NoData, setNoData] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (selectedDate.year() >= 0 && selectedDate.year() < 1762) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [selectedDate]);

  return (
    <>
      <div className={classes.grid}>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor={labelId + '_input'} style={{ paddingBottom: 6 }}>
            <FormattedMessage id={labelId} />
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth={true}>
            <KeyboardDatePicker
              views={views}
              autoFocus={autoFocus}
              autoOk={true}
              inputVariant="outlined"
              format={format}
              id={'date-picker-' + labelId}
              value={selectedDate}
              minDate={minDate}
              maxDate={maxDate}
              minDateMessage={intl.formatMessage({ id: 'min_date_validation_msg' })}
              maxDateMessage={intl.formatMessage({ id: 'max_date_validation_msg' })}
              invalidDateMessage={intl.formatMessage({ id: 'invalid_date_format' })}
              onChange={(date) => date && setDate(date)}
              openTo={openTo}
              KeyboardButtonProps={{
                'aria-label': 'change' + labelId + ' date',
              }}
            />
          </FormControl>
        </Grid>
      </div>
      <Grid item xs={12} md={6}>
        <AlertTile
          displayAlert={NoData}
          onClose={() => setNoData(false)}
          alertTitle={intl.formatMessage({ id: 'custom_date_info' })}
          severity="info"
        />
      </Grid>
    </>
  );
};

import { SearchResponseObject } from '@Types/GeoLocation';
import { ObservationSource, ObservationSourceList } from '@Types/ObservationSource';
import { StationMesurementPeriod } from '@Types/StationMesurementPeriod';

const checkStatus = (res: Response) => {
  if (res.status >= 200 && res.status < 300) {
    return res;
  } else {
    const error = new Error(res.statusText);
    throw error;
  }
};

export function fetchStationNameSearch(
  baseUrl: string,
  endPoint: string,
  name: string | undefined = undefined,
  period: string | undefined = undefined,
): Promise<ObservationSourceList> {
  let query = `${baseUrl}/${endPoint}?`;
  if (name) {
    query = `${baseUrl}/${endPoint}?sourceName=${name}`;
  }
  if (period) {
    query = `${baseUrl}/${endPoint}?sourceName=${name}&${period}`;
  }
  return fetch(query)
    .then(checkStatus)
    .then((res) => res.json())
    .catch((error) => console.log(error));
}

export function fetchStationsFromFile(file: string): Promise<ObservationSourceList> {
  return fetch(file)
    .then(checkStatus)
    .then((res) => res.json())
    .catch((error) => console.log(error));
}

export function fetchLocationSearch(
  baseUrl: string,
  searchString: string,
  path: string | undefined = undefined,
): Promise<SearchResponseObject> {
  let query = path ? path : baseUrl + '/search?prefix=' + searchString;
  return fetch(query)
    .then(checkStatus)
    .then((res) => res.json())
    .catch((error) => console.log(error));
}
export function fetchStaionElements(
  station: string,
  locale: string,
  apiBaseUrl: string,
): Promise<StationMesurementPeriod> {
  let query = apiBaseUrl + '/stationelements?stationId=' + station + '&locale=' + locale;
  return fetch(query)
    .then(checkStatus)
    .then((res) => res.json())
    .catch((error) => console.log(error));
}

export const correctedCountyName = (name: string, otherString: string) => {
  if (name.length < 1 || name.trim() === '' || name.toLowerCase() === 'ukjent' || name.toLowerCase() === 'other') {
    return otherString;
  } else {
    return name;
  }
};

export enum ObservationSourceType {
  Station = 0,
  Ship = 1,
}

export const findObservationSourceType = (os: ObservationSource): ObservationSourceType => {
  if (os && os.municipality && os.municipality.toLowerCase() === 'skip') {
    return ObservationSourceType.Ship;
  } else {
    return ObservationSourceType.Station;
  }
};

export const isExcludedStation = (station: ObservationSource): boolean => {
  if (station.validFrom) {
    // excluding test stations, see https://agile.met.no/browse/KSS-1022
    return new Date(station.validFrom).getFullYear() === 1500;
  }
  return false;
};

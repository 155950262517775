import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { SeklimaTooltip } from '../SeklimaToolTip';
import ListIcon from '../../images/listIcon.png';
import LightTooltip from '../InfoBox/LightTooltip';

const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    gridContainer: {
      marginTop: '2em',
      marginBottom: '2em',
    },
    paper: {
      width: '90%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '5vh',
    },
    elementButton: {
      flexGrow: 1,
      textTransform: 'none',
      justifyContent: 'left',
      fontSize: '1rem',
      padding: '0.9em 1em',
    },
    modalHeader: {
      paddingLeft: '16px',
      paddingTop: '1em',
      paddingRight: '0px',
      fontSize: '1em',
      display: 'flex',
      justifyContent: 'space-between',
    },
    elementList: {
      maxHeight: '80vh',
      overflow: 'auto',
    },
    elementListItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    categoryItem: {
      borderTop: '1px solid #ddd',
    },
    chip: {
      margin: theme.spacing(),
    },
    crossOutButton: {
      minWidth: '4em',
    },
    errorButton: {
      borderColor: 'red',
    },
    buttonIcons: {
      minWidth: '55px',
      marginLeft: '0.5em',
    },
  },
  [theme.breakpoints.up('sm')]: {
    paper: {
      width: theme.spacing(50),
    },
    crossOutButton: {
      minWidth: '5.1em',
    },
    modalHeader: {
      paddingLeft: '24px',
      paddingTop: '1em',
    },
  },
});

class SelectWeatherElements extends React.Component {
  constructor(props) {
    super(props);
    this.onClickHandler = props.onClickHandler;
    this.state = {
      maxNumberOfElements: 5,
      open: false,
      defaultElementOpen: false,
      classes: props.classes,
      categoryCollapse: {},
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleDefaultElementOpen = () => {
    this.setState({ defaultElementOpen: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDefaultElementClose = () => {
    this.setState({ defaultElementOpen: false });
  };

  handleCheckboxChange = (key, elemMap) => () => {
    let newSelectedWeatherElements = Object.assign({}, this.props.selectedWeatherElements);
    if (newSelectedWeatherElements[key]) {
      delete newSelectedWeatherElements[key];
    } else {
      Object.keys(elemMap).forEach((element) => {
        const categoryValues = elemMap[element];
        const we = categoryValues.find((o) => o.id === key);
        if (we !== null && we !== undefined) {
          newSelectedWeatherElements[key] = {
            name: we.name,
            id: we.id,
          };
        }
      });
    }
    this.props.setSelectedWeatherElements(newSelectedWeatherElements);
  };

  handleCategoryCollapse = (category) => {
    let newCategoryCollapse = Object.assign({}, this.state.categoryCollapse);
    if (newCategoryCollapse[category]) {
      newCategoryCollapse[category] = false;
    } else {
      newCategoryCollapse[category] = true;
    }
    this.setState({ categoryCollapse: newCategoryCollapse });
  };

  allowSelectMore = () => {
    return Object.keys(this.props.selectedWeatherElements).length < this.state.maxNumberOfElements;
  };

  getElementModel = (elemMap) => {
    const { classes } = this.props;
    let modelMap = [];
    for (let weatherCategory in elemMap) {
      modelMap.push(
        <ListItem
          key={'item_' + weatherCategory}
          button
          className={classes.categoryItem}
          onClick={() => this.handleCategoryCollapse(weatherCategory)}
        >
          <ListItemText>{weatherCategory}</ListItemText>
          {this.state.categoryCollapse[weatherCategory] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>,
      );

      for (let weatherType in elemMap[weatherCategory]) {
        let key = elemMap[weatherCategory][weatherType].id;
        let elementLabel = elemMap[weatherCategory][weatherType].name;
        let description = elemMap[weatherCategory][weatherType].description;
        modelMap.push(
          <Collapse
            key={'collapse_' + key}
            in={this.state.categoryCollapse[weatherCategory]}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem className={classes.elementListItem}>
                <LightTooltip title={description} placement="bottom-start">
                  <FormControlLabel
                    key={'label_' + key}
                    control={
                      <Checkbox
                        checked={key in this.props.selectedWeatherElements}
                        disabled={!(this.allowSelectMore() || key in this.props.selectedWeatherElements)}
                        onChange={this.handleCheckboxChange(key, elemMap)}
                        value={key}
                      />
                    }
                    style={{ userSelect: 'none' }}
                    label={elementLabel}
                  />
                </LightTooltip>
              </ListItem>
            </List>
          </Collapse>,
        );
      }
    }
    return modelMap;
  };

  getChips = () => {
    return [
      Object.keys(this.props.selectedWeatherElements).map((key) => {
        //let name = this.props.selectedWeatherElements[key].name;
        let name = this.getElementNameById(this.props.selectedWeatherElements[key].id);
        return (
          <Chip
            key={key}
            label={name}
            onDelete={this.handleCheckboxChange(key)}
            color={'primary'}
            className={this.props.classes.chip}
            deleteIcon={<ClearIcon fontSize={'small'} />}
          />
        );
      }),
    ];
  };

  getElementNameById(elementId) {
    for (let weatherCategory in this.props.elementMap) {
      for (let weatherType in this.props.elementMap[weatherCategory]) {
        if (this.props.elementMap[weatherCategory][weatherType].id === elementId)
          return this.props.elementMap[weatherCategory][weatherType].name;
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container direction={'column'} className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
          <InputLabel htmlFor={'w_element_input'} style={{ paddingBottom: 6 }}>
            <FormattedMessage id="weather_elements" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <Button
            id={'w_element_textfield'}
            onClick={this.handleDefaultElementOpen}
            variant="outlined"
            fullWidth={false}
            className={
              this.props.noWeatherElementsSelectedAtSearch
                ? classes.elementButton + ' ' + classes.errorButton
                : classes.elementButton
            }
          >
            <FormattedMessage id="select_weather_elements_most_used" />
          </Button>
          <SeklimaTooltip title={this.props.intl.formatMessage({ id: 'select_all_elements' })} placement="top">
            <Button variant="outlined" className={classes.buttonIcons} aria-label="Location" onClick={this.handleOpen}>
              <img src={ListIcon} alt="listIcon" />
            </Button>
          </SeklimaTooltip>
        </Grid>
        <Modal open={this.state.open} onClose={this.handleClose}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              <Typography variant="h6" style={{ userSelect: 'none' }}>
                <FormattedMessage id="select_weather_elements" />
              </Typography>
              <Button className={classes.crossOutButton} onClick={this.handleClose}>
                <ClearIcon fontSize={'small'} />
              </Button>
            </div>
            <List component="nav" className={classes.elementList}>
              {this.getElementModel(this.props.elementMap)}
            </List>
          </div>
        </Modal>
        <Modal open={this.state.defaultElementOpen} onClose={this.handleDefaultElementClose}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              <Typography variant="h6" style={{ userSelect: 'none' }}>
                <FormattedMessage id="select_weather_elements_most_used" />
              </Typography>
              <Button className={classes.crossOutButton} onClick={this.handleDefaultElementClose}>
                <ClearIcon fontSize={'small'} />
              </Button>
            </div>
            <List component="nav" className={classes.elementList}>
              {this.getElementModel(this.props.defaultElementMap)}
            </List>
          </div>
        </Modal>
        <Grid item xs={12} md={6}>
          <div>{this.getChips()}</div>
        </Grid>
      </Grid>
    );
  }
}

SelectWeatherElements.propTypes = {
  classes: PropTypes.object.isRequired,
  elementMap: PropTypes.object.isRequired,
  defaultElementMap: PropTypes.object.isRequired,
  selectedWeatherElements: PropTypes.object.isRequired,
  setSelectedWeatherElements: PropTypes.func.isRequired,
  noWeatherElementsSelectedAtSearch: PropTypes.bool.isRequired,
};

export default injectIntl(withStyles(styles)(SelectWeatherElements));

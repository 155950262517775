import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MapTSComponent from '../Map/MapTSComponent';
import SearchMap from './SearchMap';
import { SelectedStations } from './CountyStationList';
import { ObservationSource } from '@Types/ObservationSource';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: '2em 2em 1em 2em',
  },
  closeButton: {
    right: '1em',
    top: '0.85em',
    position: 'absolute',
    color: theme.palette.grey[500],
  },
  dialog: {
    width: '90%',
  },
}));

const DialogTitle: FC<{ onClose: () => void }> = ({ onClose }) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">
        <FormattedMessage id="select_in_map" />
      </Typography>
      <Button endIcon={<CloseIcon />} className={classes.closeButton} onClick={onClose}>
        <FormattedMessage id="select_and_close" />
      </Button>
    </MuiDialogTitle>
  );
};

const MapPanel: FC<{
  showMap: boolean;
  toggleMapDialog: () => void;
  fetchStationsMap: (name: string) => void;
  clearStationsMap: () => void;
  stationsMap: Map<string, ObservationSource>;
  selectedStations: SelectedStations;
  setSelectedStations: (selectedStations: SelectedStations) => void;
  apiUrl: string;
  maxSelectedStations: number;
}> = ({
  apiUrl,
  clearStationsMap,
  fetchStationsMap,
  selectedStations,
  setSelectedStations,
  showMap,
  stationsMap,
  toggleMapDialog,
  maxSelectedStations,
}) => {
  const [state, setState] = useState({
    lat: 0,
    long: 0,
    isStation: false,
  });

  const handleSelectStation = (lat: number, long: number, isStation: boolean) => {
    setState({ ...state, lat: lat, long: long, isStation });
  };

  const resetStateOnToggle = () => {
    setState({ ...state, lat: 0, long: 0, isStation: false });

    toggleMapDialog();
  };

  useEffect(() => {
    if (showMap) {
      fetchStationsMap('');
    } else {
      clearStationsMap();
    }
    // eslint-disable-next-line
  }, [showMap]);

  const stations = Array.from(stationsMap.values());

  return (
    <Dialog fullScreen={true} open={showMap} onClose={resetStateOnToggle} aria-labelledby="customized-dialog-title">
      <DialogTitle onClose={resetStateOnToggle} />
      <SearchMap
        fetchStationsMap={fetchStationsMap}
        clearStationsMap={clearStationsMap}
        stationsMap={stationsMap}
        selectedStations={selectedStations}
        setSelectedStations={setSelectedStations}
        handleSelectStation={handleSelectStation}
        apiUrl={apiUrl}
      />
      <DialogContent style={{ minHeight: '70vh' }}>
        {showMap && (
          <MapTSComponent
            lat={state.lat}
            long={state.long}
            isStation={state.isStation}
            selectedStations={selectedStations}
            setSelectedStations={setSelectedStations}
            stations={stations}
            stationSelectLimit={maxSelectedStations}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MapPanel;

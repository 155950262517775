import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import timeHandler from './helper/timeHandler';

const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    container: {
      backgroundColor: '#ddd',
      padding: '0.7em',
      display: 'inline-block',
      verticalAlign: 'top',
      marginBottom: '1em',
    },
  },
});

class FetchDataWarning extends Component {
  showWarning = (obsCount) => {
    if (obsCount < 1000) {
      return null;
    } else if (obsCount < 5000) {
      return <FormattedMessage id="obs_count_warning_seconds" />;
    } else if (obsCount < 40000) {
      return <FormattedMessage id="obs_count_warning_minutes" />;
    } else {
      return <FormattedMessage id="obs_count_warning_limit" />;
    }
  };

  render() {
    const { classes } = this.props;

    const obsCount = timeHandler.numberOfObservations(
      this.props.selectedResolution,
      this.props.selectedFrom,
      this.props.selectedTo,
      this.props.nSelectedWeatherElements,
      this.props.nSelectedStations,
    );
    const message = this.showWarning(obsCount);
    if (message && obsCount) {
      return (
        <div className={classes.container}>
          {obsCount} {message}
        </div>
      );
    } else {
      return null;
    }
  }
}

FetchDataWarning.propTypes = {
  selectedResolution: PropTypes.string.isRequired,
  selectedFrom: PropTypes.object.isRequired,
  selectedTo: PropTypes.object.isRequired,
  nSelectedWeatherElements: PropTypes.number.isRequired,
  nSelectedStations: PropTypes.number.isRequired,
};

export default injectIntl(withStyles(styles)(FetchDataWarning));

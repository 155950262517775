import React, { FC, ReactNode } from 'react';
import {
  createStyles,
  Grid,
  IconButton,
  ListItem,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import Downshift, { GetItemPropsOptions } from 'downshift';
import { ObservationSource } from '@Types/ObservationSource';
import { capitalizeAllWords, capitalizeStationName } from 'utils/stringUtils';
import { ReactComponent as Pin } from '../../images/pin.svg';
import { useIntl } from 'react-intl';
import { GeoLocation } from '@Types/GeoLocation';
import type { SelectedStations } from 'components/Observations/CountyStationList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('xs')]: {
      root: {
        width: '100%',
      },
      paper: {
        position: 'absolute',
        overflow: 'auto',
        zIndex: 2,
        marginTop: theme.spacing(),
        width: '95%',
        maxWidth: 'calc(' + 1440 + ' / 2)', //TODO find a global way of getting pagemaxwidth
        maxHeight: '30%',
      },
    },
    [theme.breakpoints.up('sm')]: {
      paper: {
        width: '50%',
        maxHeight: '40%',
      },
    },
  }),
);

export type StationsMap = Map<string, ObservationSource>;

export const StationSearchField: React.FC<{
  stationMap: StationsMap;
  selectedStations: SelectedStations;
  mapMode: boolean;
  //for map mode
  geoLocations?: Array<GeoLocation>;
  clearElementMap: () => void;
  placeHolder: string;
  dropDownMaxWidth?: string;
  dropDownWidth?: string;
  width?: string;
  noStationsSelectedAtSearch?: boolean;
  handleSelectedStation: (item: any) => void;
  fetchStationsMap: (value: string) => void;
  limitSelection: {
    hasLimit: boolean;
    maxSelectedElements: number;
    maxSelectedMessage: string;
  };
}> = ({
  width,
  limitSelection,
  geoLocations = [],
  fetchStationsMap,
  clearElementMap,
  placeHolder,
  dropDownMaxWidth,
  dropDownWidth,
  stationMap,
  selectedStations,
  handleSelectedStation,
  noStationsSelectedAtSearch = false,
  mapMode,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [typingTimeout, setTypingTimeout] = React.useState(setTimeout(() => {}, 500));
  const searchLimit = 2;

  const handleSelectedElement = (station: any) => {
    setInputValue('');
    if (station) {
      handleSelectedStation(station);
    }
  };

  const handleInputChange = (event: any) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setInputValue(event.target.value);
    setTypingTimeout(
      setTimeout(
        (inputText) => {
          if (inputText && inputText.length && inputText[0].length >= searchLimit) {
            fetchStationsMap(inputText[0].trim().toLowerCase());
          } else {
            clearElementMap();
          }
        },
        500,
        [event.target.value],
      ),
    );
  };

  const handleSelectLimit = () => {
    if (limitSelection.hasLimit) {
      return selectedStations.size >= limitSelection.maxSelectedElements;
    }
    return false;
  };

  return (
    <Downshift
      id="search-stations"
      inputValue={inputValue}
      onSelect={handleSelectedElement}
      itemToString={(item) => (item ? item.name : '')}
    >
      {({ getInputProps, getItemProps, isOpen, highlightedIndex }) => {
        const inputProps = getInputProps({
          onChange: handleInputChange,
          onKeyDown: (event: any) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          },
        });
        return (
          <div className={classes.root}>
            <Grid container direction={'column'} className={classes.gridContainer}>
              <Grid item xs={12}>
                <SearchInputField
                  inputProps={inputProps}
                  maxSelectedMessage={limitSelection.maxSelectedMessage}
                  noStationsSelectedAtSearch={noStationsSelectedAtSearch}
                  placeholder={placeHolder}
                  selectLimit={handleSelectLimit()}
                  width={width}
                />
                {isOpen ? (
                  <div style={{ paddingRight: '2em' }}>
                    <Paper
                      className={classes.paper}
                      style={{ maxWidth: dropDownMaxWidth, width: dropDownWidth }}
                      square
                    >
                      {mapMode ? (
                        <MapSuggestions
                          selectedStations={selectedStations}
                          stationMap={stationMap}
                          geoLocations={geoLocations}
                          getItemProps={getItemProps}
                          highlightedIndex={highlightedIndex}
                        />
                      ) : (
                        <Suggestions
                          selectedStations={selectedStations}
                          stationMap={stationMap}
                          getItemProps={getItemProps}
                          highlightedIndex={highlightedIndex}
                        />
                      )}
                    </Paper>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </div>
        );
      }}
    </Downshift>
  );
};

const SearchInputField: FC<{
  inputProps: any;
  noStationsSelectedAtSearch: boolean;
  selectLimit: boolean;
  placeholder: string;
  maxSelectedMessage: string;
  width: string | undefined;
}> = ({ inputProps, maxSelectedMessage, noStationsSelectedAtSearch, placeholder, selectLimit, width }) => {
  return (
    <div style={{ width }}>
      <TextField
        data-testid="search-stations-field"
        error={noStationsSelectedAtSearch}
        fullWidth={true}
        disabled={selectLimit}
        placeholder={selectLimit ? maxSelectedMessage : placeholder}
        variant={'outlined'}
        InputProps={{
          ...inputProps,
        }}
      />
    </div>
  );
};

const MapSuggestion: FC<{
  title: string;
  subtitle: string;
  municipality: string;
  county: string;
  itemProp: any;
  selected: boolean;
  disabled: boolean;
  isStation: boolean;
}> = ({ county, disabled, isStation, itemProp, municipality, selected, subtitle, title }) => {
  return (
    <ListItem
      button
      {...itemProp}
      style={{
        fontWeight: 400,
        display: 'block',
      }}
      selected={selected}
      disabled={disabled}
    >
      <Grid container>
        <Grid item xs={7} md={5}>
          <Typography noWrap={true} style={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2} md={3}>
          <Typography noWrap={true}>{municipality}</Typography>
        </Grid>
        <Grid item xs={2} md={3}>
          <Typography noWrap={true}>{county}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton style={{ display: !isStation ? 'none' : 'inline', padding: 0 }} disabled={true}>
            <Pin />
          </IconButton>
        </Grid>
      </Grid>
      <Typography noWrap={true}>{subtitle}</Typography>
    </ListItem>
  );
};

const Suggestions: FC<{
  stationMap: StationsMap;
  selectedStations: Map<string, string>;
  getItemProps: (arg: GetItemPropsOptions<any>) => any;
  highlightedIndex: number | null;
}> = ({ getItemProps, highlightedIndex, selectedStations, stationMap }) => {
  const stations = Array.from(stationMap.entries());
  return (
    <>
      {stations.map(([id, station], counter) => {
        const itemProp = getItemProps({ item: station });
        const subtitle =
          station.municipality === ''
            ? capitalizeAllWords(station.country)
            : capitalizeAllWords(station.municipality) + ', ' + capitalizeAllWords(station.county);
        const title = capitalizeStationName(station.name);
        return (
          <ListItem
            button
            {...itemProp}
            key={id}
            style={{
              fontWeight: 400,
              display: 'block',
            }}
            selected={highlightedIndex === counter}
            disabled={selectedStations.has(id)}
          >
            <Typography noWrap={true} style={{ fontWeight: 'bold' }}>
              {title} ({id})
            </Typography>
            <Typography noWrap={true}>{subtitle}</Typography>
          </ListItem>
        );
      })}
    </>
  );
};

const MapSuggestions: FC<{
  stationMap: StationsMap;
  selectedStations: Map<string, string>;
  geoLocations?: Array<GeoLocation>;
  getItemProps: (arg: GetItemPropsOptions<any>) => any;
  highlightedIndex: number | null;
}> = ({ stationMap, selectedStations, geoLocations, getItemProps, highlightedIndex }) => {
  const intl = useIntl();

  let stations = new Array<ReactNode>();
  let counter = 0;
  stationMap.forEach((station, stationId) => {
    let itemProp = getItemProps({ item: station });
    let title = capitalizeStationName(station.name) + ', (' + stationId + ')';
    let { municipality, county, masl, id } = station;
    stations.push(
      <MapSuggestion
        title={title}
        subtitle={intl.formatMessage({ id: 'search_subtitle' }) + masl + ' m'}
        municipality={municipality}
        county={county}
        itemProp={itemProp}
        key={counter}
        selected={highlightedIndex === counter}
        disabled={selectedStations.has(id)}
        isStation={true}
      />,
    );
    counter++;
  });
  geoLocations?.forEach((location) => {
    let itemProp = getItemProps({ item: location });
    let subtitle = '';
    let { feature_class, feature_class_no, admin_level_1, admin_level_2 } = location;
    subtitle = intl.locale === 'en' ? feature_class[0] : feature_class_no[0];
    stations.push(
      <MapSuggestion
        title={capitalizeStationName(location.name)}
        subtitle={subtitle}
        municipality={admin_level_1[0]}
        county={admin_level_2[0]}
        itemProp={itemProp}
        key={counter}
        selected={highlightedIndex === counter}
        disabled={false}
        isStation={false}
      />,
    );
    counter++;
  });
  return <>{stations}</>;
};

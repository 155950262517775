import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    tabContainer: {
      backgroundColor: '#F3F9F5',
      paddingTop: 24,
      paddingBottom: 24,
      marginTop: '15px',
    },
  },
});

function TabContainer(props) {
  const { classes } = props;
  return (
    <Typography component="div" className={classes.tabContainer}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(TabContainer));

import { Button, Collapse } from '@material-ui/core';
import React from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { ObservationSource } from '@Types/ObservationSource';
import { SortableTable } from 'components/SorteableTable/SortableTable';
import { WeatherElement, WeatherElementKeys, StationMesurementPeriod } from '@Types/StationMesurementPeriod';
import { capitalizeStationName } from 'utils/stringUtils';
import { IntlShape, useIntl } from 'react-intl';
import { fetchStaionElements } from 'utils/stationsUtils';
import { Moment } from 'moment';
import moment from 'moment';
import { Locale, LocaleEnum } from 'utils/localizationUtils';
import { getTimeresolutionAsText } from 'utils/dateUtils';
import { Order } from 'components/SorteableTable/SortUtils';
import { Descriptions, HeaderCells, BodyCells } from 'components/SorteableTable/SortableTableTypes';
import { AlertTile } from 'components/Alert/AlertTile';

export interface DisplayPeroid {
  [key: string]: boolean;
}

interface MesurementPeriods {
  [key: string]: WeatherElement[];
}

export type LocaleMesurementPeriod = {
  [key in Locale]: MesurementPeriods;
};

interface Props {
  station: ObservationSource;
  apiBaseUrl: string;
  displayMesurmentPeriods: DisplayPeroid | undefined;
  setDisplayMesurmentPeriods: (arg0: DisplayPeroid) => void;
  stationMesurementPeriod: LocaleMesurementPeriod;
  setStationMesurementPeriod: (arg0: LocaleMesurementPeriod) => void;
}

const buildHeaderCells = (intl: IntlShape): HeaderCells[] => {
  let headCells: HeaderCells[] = [];
  headCells = [
    { id: WeatherElementKeys.category, label: intl.formatMessage({ id: 'category' }) },
    { id: WeatherElementKeys.name, label: intl.formatMessage({ id: 'weather_element' }) },
    { id: WeatherElementKeys.timeResolution, label: intl.formatMessage({ id: 'time_resolution' }) },
    { id: WeatherElementKeys.validFrom, label: intl.formatMessage({ id: 'from_date' }) },
    { id: WeatherElementKeys.validTo, label: intl.formatMessage({ id: 'to_date' }) },
    { id: WeatherElementKeys.performanceCategory, label: intl.formatMessage({ id: 'performace_category' }) },
    { id: WeatherElementKeys.exposureCategory, label: intl.formatMessage({ id: 'exposure_category' }) },
  ];

  return headCells;
};

export const StationDetailsWeatherElementTable: React.FC<Props> = ({
  station,
  apiBaseUrl,
  displayMesurmentPeriods,
  setDisplayMesurmentPeriods,
  stationMesurementPeriod,
  setStationMesurementPeriod,
}) => {
  const [displayAlert, setDisplayAlert] = React.useState(true);
  const [displayAlertTimeout, setDisplayAlertTimeout] = React.useState(false);
  const intl = useIntl();

  const switchMesurementPeroid = (stationId: string) => {
    let tmpDisplay: DisplayPeroid = Object.assign({}, displayMesurmentPeriods);
    tmpDisplay[stationId] = !tmpDisplay[stationId];
    setDisplayMesurmentPeriods(tmpDisplay);
  };

  const onCollapseClose = (stationId: string) => {
    switchMesurementPeroid(stationId);
    setDisplayAlertTimeout(false);
  };

  const fetchWeatherElementTable = (stationId: string) => {
    switchMesurementPeroid(stationId);
    if (!stationMesurementPeriod || !(stationId in stationMesurementPeriod[intl.locale as Locale])) {
      fetchStaionElements(stationId, LocaleEnum.nb, apiBaseUrl).then((res) => buildStationElements(res, LocaleEnum.nb));
      fetchStaionElements(stationId, LocaleEnum.en, apiBaseUrl).then((res) => buildStationElements(res, LocaleEnum.en));
    }
  };

  const buildStationElements = (res: StationMesurementPeriod, locale: LocaleEnum) => {
    if (res && res.elements && res.sourceId) {
      let tmpMesurement: LocaleMesurementPeriod = Object.assign({}, stationMesurementPeriod);
      tmpMesurement[locale][res.sourceId.toUpperCase()] = res.elements;
      setStationMesurementPeriod(tmpMesurement);
    }
    // We want to wait a bit before showing missing data error
    setDisplayAlertTimeout(false);
    setTimeout(() => {
      setDisplayAlertTimeout(true);
    }, 1000);
  };

  function createRowData(
    category: string,
    name: string,
    timeResolution: string,
    validFrom: Moment | string,
    validTo: Moment | string,
    performanceCategory: string,
    exposureCategory: string,
  ): BodyCells {
    let rowData: BodyCells = {};
    rowData[WeatherElementKeys.category] = { value: category, id: category };
    rowData[WeatherElementKeys.name] = { value: name, id: name };
    rowData[WeatherElementKeys.timeResolution] = { value: timeResolution, id: timeResolution };
    rowData[WeatherElementKeys.validFrom] = { value: validFrom, id: validFrom.toString() };
    rowData[WeatherElementKeys.validTo] = { value: validTo, id: validFrom.toString() };
    rowData[WeatherElementKeys.performanceCategory] = { value: performanceCategory, id: performanceCategory };
    rowData[WeatherElementKeys.exposureCategory] = { value: exposureCategory, id: exposureCategory };
    return rowData;
  }
  const buildTableRows = (stationId: string, mesurementPeriod: MesurementPeriods): BodyCells[] => {
    let elemRows: BodyCells[] = [];
    for (let elem of mesurementPeriod[stationId]) {
      for (let series of elem.SeriesData) {
        elemRows.push(
          createRowData(
            elem.category,
            elem.name,
            getTimeresolutionAsText(series.timeResolution, intl),
            moment(series.validFrom),
            series.validTo !== '0001-01-01T00:00:00Z'
              ? moment(series.validTo)
              : intl.formatMessage({ id: 'operative' }),
            series.performanceCategory,
            series.exposureCategory,
          ),
        );
      }
    }
    return elemRows;
  };

  const buildDescriptions = (stationId: string, mesurementPeriod: MesurementPeriods): Descriptions => {
    let descriptions: Descriptions = {};
    for (let elem of mesurementPeriod[stationId]) {
      descriptions[elem.name] = elem.description;
    }
    return descriptions;
  };

  const externalComparatorRule = (a: number | string | Moment, b: number | string | Moment, order: Order): number => {
    if (b instanceof moment && typeof a === 'string') {
      return -1;
    }
    if (a instanceof moment && typeof b === 'string') {
      return 1;
    }
    // Handeling moment objects
    if (b instanceof moment && a instanceof moment) {
      let tmpB = b as unknown;
      let tmpA = a as unknown;
      if ((tmpB as Moment).isBefore(tmpA as Moment)) return -1;
      if ((tmpB as Moment).isAfter(tmpA as Moment)) return 1;
    } else {
      if (b < a) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
    }
    return 0;
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        endIcon={displayMesurmentPeriods && displayMesurmentPeriods[station.id] ? <ExpandLess /> : <ExpandMore />}
        onClick={() =>
          displayMesurmentPeriods && displayMesurmentPeriods[station.id]
            ? onCollapseClose(station.id)
            : fetchWeatherElementTable(station.id)
        }
      >
        {intl.formatMessage({ id: 'measurement_periods' })}
      </Button>
      {station.id in stationMesurementPeriod[intl.locale as Locale] ? (
        <Collapse in={displayMesurmentPeriods && displayMesurmentPeriods[station.id]} timeout="auto" unmountOnExit>
          <SortableTable
            title={
              intl.formatMessage({ id: 'measurement_periods_for' }) +
              capitalizeStationName(station.name) +
              ' (' +
              station.id +
              ')'
            }
            headerCells={buildHeaderCells(intl)}
            bodyCells={buildTableRows(station.id, stationMesurementPeriod[intl.locale as Locale])}
            orderByDefault={WeatherElementKeys.category}
            descriptions={buildDescriptions(station.id, stationMesurementPeriod[intl.locale as Locale])}
            rowsPerPageOptions={[10, 25, 100]}
            rowsPerPageDefault={10}
            dense={false}
            defaultSort={WeatherElementKeys.category}
            externalComparatorRule={externalComparatorRule}
            labelRowsPerPage={intl.formatMessage({ id: 'label_rows_per_page' })}
          ></SortableTable>
        </Collapse>
      ) : (
        displayAlertTimeout && (
          <AlertTile
            alertTitle={'Missing data from api'}
            displayAlert={displayAlert}
            onClose={() => setDisplayAlert(false)}
          />
        )
      )}
    </>
  );
};

import PropTypes from 'prop-types';
import { AppBar, Toolbar, Hidden, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from './Menu.tsx';
import { MobileMenu } from './MobileMenu.tsx';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
const styles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    root: {
      color: '#FFFFFF',
      height: '4rem',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    kssLogo: {
      flexGrow: 3,
      display: 'inline-block',
      marginLeft: '1rem',
      width: 250,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
    },

    localeButton: {
      minWidth: '2em',
      paddingRight: 0,
      paddingLeft: 0,
    },
    alignment: {
      color: 'inherit',
      display: 'inline-block',
      fontSize: '1.2rem',
    },
  },
  [theme.breakpoints.up('sm')]: {
    alignment: {
      fontSize: '2rem',
    },
  },
  [theme.breakpoints.up('md')]: {},
});

function Header(props) {
  const { classes } = props;

  return (
    <>
      <AppBar position={'fixed'} style={{ flexDirection: 'row' }}>
        <JumpToMainContent />
        <Toolbar className={'global-root ' + classes.root}>
          <Link to={'/kss'} style={{ color: 'inherit' }} className={classes.alignment}>
            <FormattedMessage id={'logoKss'}>
              {(...fragments) => (
                <img className={classes.kssLogo} src={process.env.PUBLIC_URL + fragments} alt={'KSS logo'} />
              )}
            </FormattedMessage>
          </Link>
          <div style={{ flexGrow: 1, textAlign: 'right' }}>
            <Hidden smDown>
              <Button className={classes.localeButton} color="inherit" onClick={props.localeSelected}>
                <Typography style={props.currentLocale.includes('nb') ? { fontWeight: 600 } : {}}>{'Norsk'}</Typography>
                <Typography>/</Typography>
                <Typography style={props.currentLocale.includes('en') ? { fontWeight: 600 } : {}}>
                  {'English'}
                </Typography>
              </Button>
            </Hidden>
            <Hidden mdUp>
              <MobileMenu
                kssBaseUrl={props.config.REACT_APP_KSS_URL}
                localeSelected={props.localeSelected}
                currentLocale={props.currentLocale}
              />
            </Hidden>
          </div>
        </Toolbar>
      </AppBar>
      <Hidden smDown>
        <Menu seklimaBaseUrl={props.config.REACT_APP_SEKLIMA_URL} kssBaseUrl={props.config.REACT_APP_KSS_URL} />
      </Hidden>
    </>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  currentLocale: PropTypes.string.isRequired,
  localeSelected: PropTypes.func,
};

export default withStyles(styles)(Header);

const JumpToMainContent = () => {
  const [showButton, setShowButton] = useState(false);

  const location = useLocation();
  return (
    <Box
      style={{
        position: 'absolute',
        zIndex: 1,
      }}
    >
      {!showButton ? (
        <Box
          tabIndex={0}
          onFocus={() => {
            setShowButton(true);
          }}
        />
      ) : (
        <>
          <Button
            ref={(ref) => {
              ref?.focus();
            }}
            onBlur={() => {
              setShowButton(false);
            }}
            href={`${location.pathname}${location.search}#main-content`}
            variant="contained"
            color="secondary"
          >
            <FormattedMessage id="jump_to_main_content" />
          </Button>
        </>
      )}
    </Box>
  );
};

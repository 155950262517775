import React from 'react';
import { PageHeader } from '../components/PageHeader';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('xs')]: {
      homeContainer: {
        marginLeft: '1em',
        marginRight: '1em',
      },
      button: {
        marginBottom: theme.spacing(),
        marginRight: '1em',
      },
    },
  }),
);

interface Props {}

export const RegionPage: React.FC<Props> = () => {
  const classes = useStyles();
  const intl = useIntl();
  React.useEffect(() => {
    document.title = intl.formatMessage({ id: 'region_information' }) + ' - Seklima';
    document.documentElement.lang = intl.locale;
  }, [intl]);

  return (
    <div className="global-root">
      <div className={classes.homeContainer}>
        <PageHeader titleId="region_information" infoTextId="region_information_description" />
      </div>
    </div>
  );
};

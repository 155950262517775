import { createStyles, Dialog, DialogTitle, List, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { BodyCells, HeaderCells } from 'components/SorteableTable/SortableTableTypes';
import { useIntl } from 'react-intl';
import { ReactComponent as DownloadIcon } from '../../images/downloadIcon.svg';
import exportTable from './ExportTable';

interface ExportDialogProps {
  data: BodyCells[];
  header: HeaderCells[];
  open: boolean;
  items?: string[];
  title: string;
  dateFormat: string;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelIcons: {
      width: 20,
      height: 'auto',
      paddingRight: theme.spacing(),
      display: 'inline-block',
    },
  }),
);

export const ExportDialog: React.FC<ExportDialogProps> = ({
  data,
  header,
  open,
  items = ['csv', 'ods', 'xlsx'],
  title,
  dateFormat,
  handleClose,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  function handleListItemClick(value: string) {
    exportTable(value, data, header, title, intl, dateFormat);
    handleClose();
  }
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{intl.formatMessage({ id: 'download_format' })}</DialogTitle>
      <List>
        {items.map((item) => (
          <ListItem button onClick={() => handleListItemClick(item)} key={item}>
            <DownloadIcon className={classes.panelIcons}></DownloadIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

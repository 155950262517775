import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import SelectTimeResolution from '../components/Observations/SelectTimeResolution';
import SelectTimePeriod from '../components/Observations/SelectTimePeriod';
import SelectWeatherElements from '../components/Observations/SelectWeatherElements';
import { Counties, SelectedStations } from '../components/Observations/CountyStationList';
import { FormattedMessage, useIntl } from 'react-intl';
import ObservationPanel from '../components/Observations/ObservationPanel';
import FetchDataWarning from '../components/Observations/FetchDataWarning';
import AlertDialog from '../components/Alert/AlertDialog';
import Button from '@material-ui/core/Button';
import timeHandler from '../components/Observations/helper/timeHandler';
import MessagePanel from '../components/Observations/MessagePanel';
import moment, { Moment } from 'moment';
import getElementById from '../components/Observations/helper/elementHandler';
import { PageHeader } from '../components/PageHeader';
import { SelectDate } from '../components/SelectDate';
import { getCalenderView, getCalenderFormat, getCalenderType } from '../utils/dateUtils';
import { AlertTile } from '../components/Alert/AlertTile';
import { timeResolution } from '../components/Observations/helper/timeHandler';
import {
  buildObservationDataElement,
  fetchCountyList,
  fetchElementMapService,
  fetchStations,
  getStation,
} from './observationPageUtils';
import { useParams } from 'react-router-dom';
import { ObservationSource } from '@Types/ObservationSource';
import { Stations } from '../components/Stations/Stations';
import { StationsMap } from 'components/Stations/StationSearchField';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up('xs')]: {
    homeContainer: {
      marginLeft: '1em',
      marginRight: '1em',
    },
    button: {
      marginBottom: theme.spacing(),
      marginRight: '1em',
    },
  },
}));

export const ModeType = {
  INIT_STATE: 'INIT_STATE', // the first time the app loads
  UPDATE_NEED: 'UPDATE_NEED', // selection changed, new data is not fetched
  FETCHING_DATA: 'FETCHING_DATA', // currently fetching observations
  NO_DATA: 'NO_DATA', // get set at the beginning of an API call
  VALID_DATA: 'VALID_DATA', // when all data has been gotten, VALID_DATA is set, and graph is updated
};

const ObservationPage: FC<{
  conf: any;
  currentLocale: string;
  localeSelected: () => void;
  multipleDateLabels: any;
}> = ({
  conf,
  currentLocale,
  localeSelected,
  multipleDateLabels = {
    custom_period: { from: 'from', to: 'to' },
    recurring_period: { from: 'recurring_time_period_start', to: 'recurring_time_period_end' },
  },
}) => {
  const [state, setState] = useState({
    selectedResolution: 'days',
    tempResolution: '', // Temporary value set for selected resolution. (in case user does not want to reset all chosen options)
    selectedPeriod: '', // The selected timeperiod, can be eg: last_7_days, custom_period, recurring_period [...]
    selectedFrom: timeHandler.resetTime(moment(), 'days', 'selectedFrom'),
    selectedTo: timeHandler.resetTime(moment(), 'days', 'selectedTo'),
    elementMapNo: {}, //List of elements per category in norwegian
    elementMapEn: {}, //List of elements per category in english
    defaultElementMapNo: {}, //List of most used elements per category in norwegian
    defaultElementMapEn: {}, //List of most used elements per category in english
    selectedWeatherElements: {} as SelectedWeatherElements, // object with selected elements
    selectedStations: new Map() as SelectedStations, // object with selected stations
    observationData: {} as Highcharts.Options, // Current observation to be shown in graph and table
    displayTimeResolutionAlert: false, // Show dialog for reset all selections
    mode: ModeType.INIT_STATE, // See const above
    noWeatherElementsSelectedAtSearch: false, // show error in element input
    noPeriodSelectedAtSearch: false, // show error in period input
    observationsFetchError: '', // error request message
    displayFetchAlert: false, // display alert tile for obs errors
    pendingDataRequests: [], // requests that was rejected due to high load (http 429)
  });

  const getNewModeOnChanged = () => {
    // keep init mode until data is fetched
    if (state.mode !== ModeType.INIT_STATE) {
      return ModeType.UPDATE_NEED;
    } else {
      return ModeType.INIT_STATE;
    }
  };

  const timePeriodChangeHandler = (value: string) => {
    setState((state) => ({
      ...state,
      selectedPeriod: value,
      selectedFrom: timeHandler.timePeriodDateChanger(value, state.selectedResolution),
      selectedTo: timeHandler.resetTime(moment(), state.selectedResolution, 'selectedTo'),
      mode: getNewModeOnChanged(),
      noPeriodSelectedAtSearch: false,
    }));
  };

  const setNoWeatherElementsSelectedAtSearch = (value: boolean) => {
    setState((state) => ({ ...state, noWeatherElementsSelectedAtSearch: value }));
  };
  const setNoPeriodSelectedAtSearch = (value: boolean) => {
    setState((state) => ({ ...state, noPeriodSelectedAtSearch: value }));
  };

  const dateChangeHandler = (field: string) => (value: Moment | null) => {
    if (value !== null) {
      if (timeResolution.MONTHS === state.selectedResolution || timeResolution.SEASONS === state.selectedResolution) {
        field === 'selectedFrom' ? value.startOf('month') : value.endOf('month');
      } else if (timeResolution.YEARS === state.selectedResolution) {
        field === 'selectedFrom' ? value.startOf('year') : value.endOf('year');
      } else {
        //for all the other timeresolutions we just set day.
        field === 'selectedFrom' ? value.startOf('day') : value.endOf('day');
      }
      setState((state) => ({ ...state, [field]: value, mode: getNewModeOnChanged() }));
    }
  };

  const intl = useIntl();
  const params = useParams<UrlParams>();

  useEffect(() => {
    let { timeResolution, date, fromTo, stations, locale } = params;

    document.title = intl.formatMessage({ id: 'observations_and_weather_statistics' }) + ' - Seklima';
    document.documentElement.lang = intl.locale;

    fetchElementMap(timeResolution ? timeResolution : state.selectedResolution);

    // we use a switch that defaults to nb. So we only check if eng is set.
    // this can be problematic for multiple language support.
    if (locale === 'en') {
      localeSelected();
    }
    if (timeResolution) setState((state) => ({ ...state, selectedResolution: timeResolution }));
    if (date) {
      timePeriodChangeHandler(date);
      let selectedFrom: any = null;
      let selectedTo: any = null;
      if (date === 'custom_period' || date === 'recurring_period') {
        if (fromTo) {
          let arr = fromTo.split(';');
          try {
            selectedFrom = moment(arr[0]);
            selectedTo = moment(arr[1]);
            setState((state) => ({ ...state, selectedFrom, selectedTo }));
          } catch (e) {
            console.log('bad date format on uri: ' + e);
          }
        }
      } else {
        selectedFrom = timeHandler.timePeriodDateChanger(
          date,
          timeResolution ? timeResolution : state.selectedResolution,
        );
        selectedTo = moment();
      }
      if (selectedFrom && selectedTo && stations) {
        getStationsFromUri(
          stations,
          selectedFrom,
          selectedTo,
          state.selectedResolution,
          state.selectedStations,
          conf.REACT_APP_API_URL,
          getWeatherElementsAsString(state.selectedWeatherElements),
        ).then((initialSelectedStations) => setSelectedStations(initialSelectedStations));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchElementMap = (timeResolution: string) => {
    fetchElementMapByLanguage(timeResolution, 'nb');
    fetchElementMapByLanguage(timeResolution, 'en');
  };

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'observations_and_weather_statistics' }) + ' - Seklima';
    document.documentElement.lang = intl.locale;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl.locale]);

  useEffect(() => {
    let { timeResolution } = params;

    if (
      Object.entries(state.elementMapNo).length > 0 &&
      (!timeResolution || timeResolution === state.selectedResolution)
    ) {
      if (intl.locale === 'nb') setElementsFromUri(state.elementMapNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.elementMapNo]);

  useEffect(() => {
    let { timeResolution } = params;

    if (Object.entries(state.elementMapEn).length > 0 && timeResolution === state.selectedResolution) {
      if (intl.locale === 'en') setElementsFromUri(state.elementMapEn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.elementMapEn]);

  const setElementsFromUri = (currentElementMap: any) => {
    let { weatherElements } = params;

    if (weatherElements) {
      let uriElement = weatherElements.split(',');
      let selectedElements: any = {};
      for (let index in uriElement) {
        try {
          let element = getElementById(currentElementMap, uriElement[index]);
          if (element) selectedElements[uriElement[index]] = element;
        } catch (e) {
          console.log('error with uri params: ' + e);
        }
      }

      setSelectedWeatherElements(selectedElements);
    }
  };

  const fetchElementMapByLanguage = async (timeResolution: string, localeCode: string) => {
    let rimLocale = localeCode === 'en' ? 'en' : 'no';

    const result = await fetchElementMapService(timeResolution, rimLocale, conf.REACT_APP_API_URL);
    if (result) {
      const { newDefaultElementMap, newElementMap } = result;
      if (localeCode === 'en') {
        setState((state) => ({ ...state, elementMapEn: newElementMap, defaultElementMapEn: newDefaultElementMap }));
      } else {
        setState((state) => ({ ...state, elementMapNo: newElementMap, defaultElementMapNo: newDefaultElementMap }));
      }
    }
  };

  const setSelectedWeatherElements = (newWeatherElements: any) => {
    setState((state) => ({ ...state, selectedWeatherElements: newWeatherElements, mode: getNewModeOnChanged() }));
    setNoWeatherElementsSelectedAtSearch(false);
  };

  const setSelectedStations = (newSelectedStations: SelectedStations) => {
    setState((state) => ({ ...state, selectedStations: newSelectedStations, mode: getNewModeOnChanged() }));
  };

  const handleTimeResolutionReset = (timeResolution: string) => {
    const selectedFrom = timeHandler.resetTime(moment(), timeResolution, 'selectedFrom');
    const selectedTo = timeHandler.resetTime(moment(), timeResolution, 'selectedTo');
    setState((state) => ({
      ...state,
      selectedFrom,
      selectedTo,
      mode: ModeType.INIT_STATE,
      noWeatherElementsSelectedAtSearch: false,
      noPeriodSelectedAtSearch: false,
    }));
  };

  /**
   * Changes time resolution.
   * If a weather element/station is already chosen, we prompt the user with an alert,
   * in case they want to abort the change/reset of the stations/elements.
   * @param event
   */
  const handleTimeResolutionChange = (event: any) => {
    if (state.selectedResolution !== event.target.value) {
      if (
        state.selectedStations.size ||
        Object.keys(state.selectedWeatherElements).length ||
        state.selectedPeriod !== ''
      ) {
        setState((state) => ({ ...state, displayTimeResolutionAlert: true, tempResolution: event.target.value }));
      } else {
        handleTimeResolutionReset(event.target.value);
        setState((state) => ({ ...state, selectedResolution: event.target.value }));
        fetchElementMap(event.target.value);
      }
    }
  };

  const timeResolutionAlertHandler = (alertOption: any) => {
    if (alertOption && state.tempResolution !== null) {
      handleTimeResolutionReset(state.tempResolution);
      setState((state) => ({
        ...state,
        selectedResolution: state.tempResolution,
        selectedWeatherElements: {},
        selectedStations: new Map(),
        selectedPeriod: '',
      }));
      fetchElementMap(state.tempResolution);
    } else {
      setState((state) => ({ ...state, tempResolution: '' }));
    }
    setState((state) => ({ ...state, displayTimeResolutionAlert: false }));
  };

  const buildShareUrl = () => {
    let url = `${conf.REACT_APP_SEKLIMA_URL}${process.env.PUBLIC_URL}/${state.selectedResolution}/`;
    for (let value of Object.values(state.selectedWeatherElements)) {
      url += `${value.id},`;
    }
    url = url.substring(0, url.length - 1) + '/';
    url += `${state.selectedPeriod}/`;
    for (let key of state.selectedStations.keys()) {
      url += `${key},`;
    }
    url = url.substring(0, url.length - 1) + '/';
    url += `${currentLocale}/`;
    if (state.selectedPeriod === 'recurring_period' || state.selectedPeriod === 'custom_period') {
      url += `${state.selectedFrom.format()};${state.selectedTo.format()}`;
    }
    return encodeURI(url);
  };

  const buildObsDataElement = async (stations: string[], weatherElements: any[], refTime: any, resolution: string) => {
    setState((state) => ({ ...state, mode: ModeType.FETCHING_DATA, observationData: {} }));
    try {
      const observationData = await buildObservationDataElement(
        stations,
        weatherElements,
        refTime,
        resolution,
        conf.REACT_APP_API_URL,
        intl,
        state.selectedWeatherElements,
        state.selectedStations,
      );
      const newMode = Object.entries(observationData.series!).length ? ModeType.VALID_DATA : ModeType.NO_DATA;
      setState((state) => ({ ...state, mode: newMode, observationData }));
    } catch (msg: any) {
      setState((state) => ({
        ...state,
        observationsFetchError: msg,
        displayFetchAlert: true,
        mode: ModeType.NO_DATA,
        observationData: {},
      }));
    }
  };

  const fetchResultSet = () => {
    if (
      state.selectedStations.size === 0 ||
      !Object.entries(state.selectedWeatherElements).length ||
      !state.selectedPeriod
    ) {
      if (!Object.entries(state.selectedWeatherElements).length) setNoWeatherElementsSelectedAtSearch(true);
      if (!state.selectedPeriod) setNoPeriodSelectedAtSearch(true);
    } else {
      let weatherElementsBuilder = [];
      for (let key in state.selectedWeatherElements) {
        weatherElementsBuilder.push(state.selectedWeatherElements[key].id);
      }
      const refTime = timeHandler.getRefrenceTime(
        state.selectedPeriod,
        state.selectedFrom,
        state.selectedTo,
        state.selectedResolution,
      );
      buildObsDataElement(
        Array.from(state.selectedStations.keys()),
        weatherElementsBuilder,
        refTime,
        state.selectedResolution,
      );
    }
  };

  const getMaxDate = (selectedPeriod: string) => {
    if (selectedPeriod === 'recurring_period' || selectedPeriod === 'custom_period') {
      return moment(new Date('2100-01-01'));
    }
    return moment();
  };

  const classes = useStyles();
  let currentElementMap = intl.locale === 'en' ? state.elementMapEn : state.elementMapNo;
  let currentDefaultElementMap = intl.locale === 'en' ? state.defaultElementMapEn : state.defaultElementMapNo;

  const FetchDataWarningFixed: any = FetchDataWarning;
  const MessagePanelFixed: any = MessagePanel;
  const SelectTimeResolutionFixed: any = SelectTimeResolution;
  const SelectWeatherElementsFixed: any = SelectWeatherElements;
  return (
    <div className="global-root">
      <div className={classes.homeContainer}>
        <PageHeader titleId="observations_and_weather_statistics" infoTextId="info_text_header" />
        <form autoComplete="off">
          <SelectTimeResolutionFixed
            labelId="time_resolution"
            onClickHandler={handleTimeResolutionChange}
            selectedResolution={state.selectedResolution}
            displayTimeResolutionAlert={state.displayTimeResolutionAlert}
          />
          <SelectWeatherElementsFixed
            elementMap={currentElementMap}
            defaultElementMap={currentDefaultElementMap}
            selectedWeatherElements={state.selectedWeatherElements}
            setSelectedWeatherElements={setSelectedWeatherElements}
            noWeatherElementsSelectedAtSearch={state.noWeatherElementsSelectedAtSearch}
          />
          <SelectTimePeriod
            changed={timePeriodChangeHandler}
            selectedPeriod={state.selectedPeriod}
            selectedResolution={state.selectedResolution}
            noPeriodSelectedAtSearch={state.noPeriodSelectedAtSearch}
          />
          {Object.keys(multipleDateLabels).includes(state.selectedPeriod) && (
            <>
              <SelectDate
                labelId={
                  state.selectedPeriod in multipleDateLabels ? multipleDateLabels[state.selectedPeriod]['from'] : 'from'
                }
                selectedDate={state.selectedFrom}
                setDate={dateChangeHandler('selectedFrom')}
                autoFocus={true}
                views={getCalenderView(state.selectedResolution)}
                format={getCalenderFormat(state.selectedResolution)}
                openTo={getCalenderType(state.selectedResolution)}
                minDate={moment(new Date('1000-01-01T00:00:00'))} // We need a min date for the error messages to work correctly
              />
              <SelectDate
                labelId={
                  state.selectedPeriod in multipleDateLabels ? multipleDateLabels[state.selectedPeriod]['to'] : 'to'
                }
                selectedDate={state.selectedTo}
                setDate={dateChangeHandler('selectedTo')}
                views={getCalenderView(state.selectedResolution)}
                format={getCalenderFormat(state.selectedResolution)}
                openTo={getCalenderType(state.selectedResolution)}
                minDate={state.selectedFrom}
                maxDate={getMaxDate(state.selectedPeriod)}
              />
            </>
          )}
          <ObservationPageStations
            baseUrl={conf.REACT_APP_API_URL}
            selectedFrom={state.selectedFrom}
            selectedResolution={state.selectedResolution}
            selectedStations={state.selectedStations}
            selectedTo={state.selectedTo}
            selectedWeatherElements={state.selectedWeatherElements}
            setSelectedStations={setSelectedStations}
          />
          <div>
            <Button onClick={() => fetchResultSet()} variant={'contained'} color={'primary'} className={classes.button}>
              <FormattedMessage id="show_result_button" />
            </Button>
            {state.mode === ModeType.UPDATE_NEED && (
              <FetchDataWarningFixed
                selectedResolution={state.selectedResolution}
                selectedFrom={state.selectedFrom}
                selectedTo={state.selectedTo}
                nSelectedWeatherElements={Object.keys(state.selectedWeatherElements).length}
                nSelectedStations={state.selectedStations.size}
              />
            )}
          </div>
          <AlertDialog
            open={state.displayTimeResolutionAlert}
            alertHandler={timeResolutionAlertHandler}
            title={'time_resolution_alert_title'}
            message={'time_resolution_alert'}
          />
          <AlertTile
            displayAlert={state.displayFetchAlert}
            onClose={() => setState((state) => ({ ...state, displayFetchAlert: false }))}
            alertTitle={intl.formatMessage({ id: 'fetch_error_alert' })}
            message={state.observationsFetchError}
          />
          <MessagePanelFixed mode={state.mode} />
          <ObservationPanel
            displayObservationPanel={state.mode === ModeType.VALID_DATA}
            observationData={state.observationData}
            elementMap={currentElementMap}
            selectedResolution={state.selectedResolution}
            shareUrl={buildShareUrl}
            selectedPeriod={state.selectedPeriod}
          />
        </form>
      </div>
    </div>
  );
};

export default ObservationPage;

const getStationsFromUri = async (
  stationsString: string,
  selectedFrom: Moment | string,
  selectedTo: Moment | string,
  selectedResolution: string,
  selectedStations: SelectedStations,
  baseUrl: string,
  weatherElements: string,
) => {
  let stationIds = stationsString.split(',');
  const stationsPromises: Promise<ObservationSource | undefined>[] = stationIds.map((stationId) => {
    return getStation(
      stationId,
      selectedFrom,
      selectedTo,
      weatherElements,
      selectedResolution,
      baseUrl,
      selectedStations,
    );
  });
  const stations = await Promise.all(stationsPromises);

  const newSelectedStations = stations.reduce<SelectedStations>((selectedStations, station) => {
    if (!station) {
      return selectedStations;
    }
    selectedStations.set(station.id, station.name);
    return selectedStations;
  }, new Map());

  return newSelectedStations;
};

const getWeatherElementsAsString = (selectedWeatherElements: { [key: string]: any }) => {
  let baseString = Object.keys(selectedWeatherElements).map((key) => {
    return selectedWeatherElements[key].id;
  });
  return baseString.join(',');
};

interface UrlParams {
  timeResolution: string;
  date: string;
  fromTo: string;
  stations: string;
  locale: string;
  weatherElements: string;
}

export type SelectedWeatherElements = {
  [key: string]: any;
};

export const ObservationPageStations: FC<{
  baseUrl: string;
  selectedWeatherElements: SelectedWeatherElements;
  selectedResolution: string;
  selectedFrom: Moment;
  selectedTo: Moment;
  selectedStations: SelectedStations;
  setSelectedStations: (stations: SelectedStations) => void;
}> = ({
  baseUrl,
  selectedStations,
  setSelectedStations,
  selectedWeatherElements,
  selectedFrom,
  selectedResolution,
  selectedTo,
}) => {
  const intl = useIntl();

  const allCurrentStationsUri = (name: string) => {
    let uri = `/stations?sourceName=${name}`;
    uri += '&weatherElements=' + getWeatherElementsAsString(selectedWeatherElements);
    uri += '&timeResolution=' + selectedResolution;
    uri += '&from=' + selectedFrom.format('YYYY-MM-DD');
    uri += '&to=' + selectedTo.format('YYYY-MM-DD');
    uri += '&includeRegions=true';
    return uri;
  };

  const [counties, setCounties] = useState<Counties>({});

  const [stationsMap, setStationsMap] = useState<StationsMap>(new Map());

  return (
    <Stations
      baseUrl={baseUrl}
      counties={counties}
      fetchCounties={async () => {
        const stationsUri = allCurrentStationsUri('');
        const newCountyList = await fetchCountyList(
          baseUrl,
          stationsUri,
          intl.formatMessage({ id: 'others' }),
          intl.formatMessage({ id: 'regions' }),
        );
        setCounties(newCountyList);
      }}
      stationsMap={stationsMap}
      fetchStationsMap={async (name: string) => {
        const urlParams = allCurrentStationsUri(name);
        const newStationsMap = await fetchStations(baseUrl, urlParams);
        setStationsMap(newStationsMap);
      }}
      clearStationsMap={() => {
        setStationsMap(new Map());
      }}
      selectedStations={selectedStations}
      setSelectedStations={setSelectedStations}
      maxSelectedStations={50}
    />
  );
};

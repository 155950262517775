import React from 'react';
import { Button, Snackbar, Typography } from '@material-ui/core';
import { ReactComponent as ShareIcon } from '../../images/shareIcon.svg';
import { useIntl } from 'react-intl';
import copy from 'copy-to-clipboard';

export const ShareButton: React.FC = () => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    copy(window.location.href);
    setOpen(true);
  }
  return (
    <>
      <Button onClick={() => handleClick()}>
        <ShareIcon style={{ width: 20, paddingRight: '1em' }}></ShareIcon>
        <Typography style={{ textTransform: 'none' }}>{intl.formatMessage({ id: 'share' })}</Typography>
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}
        message={intl.formatMessage({ id: 'share_button' })}
      ></Snackbar>
    </>
  );
};

import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import { TableRow, TableCell } from '@material-ui/core';
import { getComparator, Order, stableSort } from './SortUtils';
import { Moment } from 'moment';
import moment from 'moment';
import LightTooltip from '../InfoBox/LightTooltip';
import { Descriptions, BodyCells, BodyCellsData } from './SortableTableTypes';

interface TableRowsProps {
  rows: BodyCells[];
  descriptions: Descriptions | undefined;
  order: Order;
  orderBy: string | number;
  page: number;
  rowsPerPage: number;
  dateFormat: string;
  dense: boolean;
  externalComparatorRule?: (a: string | number | Moment, b: string | number | Moment, order: Order) => number;
}

const tableValue = (cell: BodyCellsData, dateFormat: string) => {
  if (cell.label) return cell.label;
  if (cell.value instanceof moment) {
    return (cell.value as Moment).format(dateFormat);
  }
  return cell.value;
};

//TODO table description as a separate object
/*eslint array-callback-return: ["error", { allowImplicit: true }]*/
export const TableBodyRows: React.FC<TableRowsProps> = ({
  rows,
  descriptions,
  order,
  orderBy,
  page,
  rowsPerPage,
  dateFormat,
  dense,
  externalComparatorRule,
}) => {
  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy, externalComparatorRule))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => {
          return (
            <TableRow key={i} style={{ backgroundColor: i % 2 === 0 ? 'rgba(77, 163, 106, 0.1)' : '' }}>
              {Object.keys(row).map((key, index) => {
                if (descriptions && descriptions[row[key].id]) {
                  return (
                    <LightTooltip key={key + '_' + index} title={descriptions[row[key].id]}>
                      <TableCell style={{ padding: dense ? '10px' : '16px' }}>
                        {tableValue(row[key], dateFormat)}
                      </TableCell>
                    </LightTooltip>
                  );
                }
                return (
                  <TableCell style={{ padding: dense ? '10px' : '16px' }} key={key + '_' + index}>
                    {tableValue(row[key], dateFormat)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
    </TableBody>
  );
};

import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import type { Counties, SelectedStations } from 'components/Observations/CountyStationList';
import moment, { Moment } from 'moment';
import client from 'utils/client';
import { responseHasValidData } from 'components/Observations/helper/observationHandler';
import observationData from '../components/Observations/helper/observationHandler';
import { cloneDeep } from 'lodash';
import { ObservationSource } from '@Types/ObservationSource';
import type { StationsMap } from 'components/Stations/StationSearchField';
import { defaultWeatherElements } from 'utils/defaultValues';
import { SelectedWeatherElements } from './ObservationPage';

export const fetchStations = async (url: string, baseUri: string): Promise<Map<string, ObservationSource>> => {
  const response = await client.get(url, encodeURI(baseUri));
  const stations: ObservationSource[] | undefined = response.data?.data;

  let newStationsMap: StationsMap = new Map();
  stations?.forEach((station) => {
    newStationsMap.set(station.id, station);
  });
  return newStationsMap;
};

export const fetchCountyList = async (url: string, stationsUri: string, others: string, regions: string) => {
  const response = await client.get(url, encodeURI(stationsUri));
  const stations: ObservationSource[] | undefined = response.data?.data;
  const counties = getCounties(stations, regions, others);
  return counties;
};

export const buildObservationDataElement = (
  stations: string[],
  weatherElements: string[],
  refTime: string,
  resolution: string,
  baseUrl: string,
  intl: any,
  selectedWeatherElements: SelectedWeatherElements,
  selectedStations: SelectedStations,
) => {
  // frost (and rim) returns 428 when too many requests are pending.
  // the per-client limit is 10 for normal and 20 for VIP, rim is ONE client.
  // we try to run all requests, then retry all 428 respons after a delay.
  // If wi still get 428 after x retries we tell the user to try again later.
  axiosRetry(axios, {
    retries: 2, // number of retries
    retryDelay: (retryCount) => {
      let delay = retryCount * 500 + Math.floor(Math.random() * 500); // time interval between retries
      return delay;
    },
    retryCondition: (error) => {
      if (error && error.response && error.response.status) {
        return error.response.status === 429; // too many pending request on frost
      } else {
        return true; // try again until we get an error status
      }
    },
  });

  let requests: Promise<AxiosResponse<any>>[] = [];
  stations.forEach((station) => {
    weatherElements.forEach((element: string) => {
      let url = `${baseUrl}/observations?sources=${station}&referenceTime=${refTime}&elements=${element}&timeResolution=${resolution}`;
      const request = axios.get(url, {
        validateStatus: (status) => {
          // allow 412 (missing series) and 404 (no data) so that we can handle data from other requests
          return status === 412 || status === 404 || (status >= 200 && status < 300);
        },
      });
      requests.push(request);
    });
  });
  return axios
    .all(requests)
    .then((responses) => {
      let responseDataArray = [];
      for (let i = 0; i < responses.length; i++) {
        if (responseHasValidData(responses[i])) {
          responseDataArray.push(...responses[i].data.data);
        }
      }
      const convertedObservations = observationData.convertObservationData(
        responseDataArray,
        selectedWeatherElements,
        selectedStations,
      );
      const obsData = cloneDeep(convertedObservations);
      return obsData;
    })
    .catch((error) => {
      let msg = '';
      if (error.response && error.response.status) {
        msg = error.response.config.url;
        if (error.response.status === 429) {
          msg = intl.formatMessage({ id: 'service_too_busy' });
        } else if (error.response.status === 403) {
          msg = intl.formatMessage({ id: 'too_much_data' });
        }
      }
      throw msg;
    });
};

export const getStation = async (
  stationId: string,
  selectedFrom: string | Moment,
  selectedTo: string | Moment,
  weatherElements: string,
  timeResolution: string,
  baseUrl: string,
  selectedStations: SelectedStations,
): Promise<ObservationSource | undefined> => {
  let baseUri = '/stations?';
  baseUri += 'sourceName=' + stationId;
  baseUri += '&weatherElements=' + weatherElements;
  baseUri += '&timeResolution=' + timeResolution;
  baseUri += '&from=' + moment(selectedFrom).format('YYYY-MM-DD');
  baseUri += '&to=' + moment(selectedTo).format('YYYY-MM-DD');
  baseUri += '&includeRegions=true';

  const response = await client.get(baseUrl, encodeURI(baseUri));

  const stations: ObservationSource[] | undefined = response.data?.data;
  return stations?.find((station) => {
    const stationIsNotInList = !selectedStations.has(station.id);
    return stationIsNotInList && station.id === stationId;
  });
};

export const fetchElementMapService = async (timeResolution: string, locale: string, url: string) => {
  let newElementMap: any = {};
  let newDefaultElementMap: any = {};
  const response = await client.get(url, '/elements?timeResolution=' + timeResolution + '&locale=' + locale);
  let elements = response?.data?.data?.[0]?.elements;
  if (elements) {
    for (let x in elements) {
      const category = elements[x].category;
      const elementsWithCategory = elements.filter((element: any) => {
        if (element.category === category) {
          return element;
        }
        return null;
      });
      newElementMap[category] = elementsWithCategory;
    }

    //default element map
    for (let x in elements) {
      const category = elements[x].category;
      newDefaultElementMap[category] = elements.filter((element: any) => {
        const defaultWeatherElementsForTimeResolution = (defaultWeatherElements as any)[timeResolution];
        const elementIsInDefault = defaultWeatherElementsForTimeResolution.indexOf(element.id) >= 0;
        if (element.category === category && elementIsInDefault) {
          return element;
        }
        return null;
      });
    }

    //remove empty category
    Object.keys(newDefaultElementMap).forEach(
      (key) => newDefaultElementMap[key].length === 0 && delete newDefaultElementMap[key],
    );
    return { newElementMap, newDefaultElementMap };
  }
};
export function getCounties(stations: ObservationSource[] | undefined, regions: string, others: string) {
  let countyList: Counties = {};
  stations?.forEach((station) => {
    let countyName = station.county;

    const isRegion = station['@type'] === 'RegionDataset';
    let isOthers = false;
    if (isRegion) {
      countyName = regions;
    } else if (countyName === '') {
      countyName = others;
      isOthers = true;
    }
    const newStation = {
      stationId: station.id,
      name: station.name,
      municipality: station.municipality,
      masl: station.masl,
    };
    if (countyName in countyList) {
      countyList[countyName].stations.push(newStation);
    } else {
      countyList[countyName] = {
        stations: [newStation],
        id: countyName,
        isRegion,
        isOthers,
      };
    }
  });
  return countyList;
}

import { WindrosePeriod, WindrosePeriodOptions } from '@Types/WindrosePeriod';
import { XAxisOptions, YAxisOptions } from 'highcharts';
import moment from 'moment';

const checkStatus = (res: Response) => {
  if (res.status >= 200 && res.status < 300) {
    return res;
  } else {
    const error = new Error(res.statusText);
    throw error;
  }
};

const getPeriodFromTime = (period: WindrosePeriod): moment.Moment => {
  if (period.option === WindrosePeriodOptions.LAST_10_YEARS) {
    return moment.utc().subtract(10, 'years');
  } else if (period.option === WindrosePeriodOptions.LAST_30_YEARS) {
    return moment.utc().subtract(30, 'years');
  } else if (period.option === WindrosePeriodOptions.ALL_YEARS) {
    return moment('1000-01-01');
  }
  return moment(period.from);
};

const getPeriodToTime = (period: WindrosePeriod): moment.Moment => {
  if (
    period.option === WindrosePeriodOptions.CUSTOM_PERIOD ||
    period.option === WindrosePeriodOptions.RECURRING_PERIOD
  ) {
    return moment(period.to);
  }
  return moment().utc();
};

export function fetchWindroseValues(
  baseUrl: string,
  station: number,
  period: WindrosePeriod,
  recurringMonths: number[],
): Promise<windrose.RootObject> {
  const df = 'YYYY-MM-DD';
  const from = getPeriodFromTime(period);
  const to = getPeriodToTime(period).endOf('month');
  let query = `${baseUrl}/windrose/values?stationid=${station}&fromtime=${from.format(df)}T00:00:00Z&totime=${to.format(
    df,
  )}T00:00:00Z`;
  if (WindrosePeriodOptions.RECURRING_PERIOD === period.option) {
    query += `&months=[${recurringMonths}]`;
  }
  return fetch(query)
    .then(checkStatus)
    .then((res) => res.json());
}

export namespace Windrose {
  export const mobileLegend: Highcharts.LegendOptions = {
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    backgroundColor: '#FAFAFA',
    squareSymbol: false,
    y: 0,
  };

  export const desktopLegend: Highcharts.LegendOptions = {
    align: 'right',
    verticalAlign: 'top',
    y: 60,
    layout: 'vertical',
    itemMarginBottom: 4,
    backgroundColor: '#FAFAFA',
    squareSymbol: false,
  };

  export const colors = [
    '#d2f2ad',
    '#addd58',
    '#58b531',
    '#0d751d',
    '#0d4006',
    '#f3e60a',
    '#f3b30b',
    '#eb6a06',
    '#d8203f',
    '#ac1d5a',
    '#601a3e',
    '#3b0a1c',
  ];

  export const chart: Highcharts.ChartOptions = {
    polar: true,
    type: 'column',
    height: '45%',
  };

  export const mobileChart: Highcharts.ChartOptions = {
    polar: true,
    type: 'column',
    height: '200%',
  };

  export const xAxis: XAxisOptions = {
    tickmarkPlacement: 'on',
    type: 'category',
  };

  export const yAxis: YAxisOptions = {
    min: 0,
    endOnTick: false,
    showLastLabel: true,
    labels: {
      format: '{value} %',
    },
    reversedStacks: false,
    angle: 5,
  };

  export const plotOptions: Highcharts.PlotOptions = {
    series: {
      stacking: 'normal',
      shadow: false,
      pointPlacement: 'on',
    },
  };

  export const tooltip: Highcharts.TooltipOptions = {
    valueSuffix: '%',
  };

  export const enDescription = {
    direction: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'],
    beaufort: [
      'Silent',
      'Light air',
      'Light breeze',
      'Gentle breeze',
      'Moderate breeze',
      'Fresh breeze',
      'Strong breeze',
      'Near gale',
      'Gale',
      'Strong gale',
      'Storm',
      'Violent storm',
      'Hurricane',
    ],
    month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan-Dec'],
  };

  export const nbDescription = {
    direction: ['N', 'NNØ', 'NØ', 'ØNØ', 'Ø', 'ØSØ', 'SØ', 'SSØ', 'S', 'SSV', 'SV', 'VSV', 'V', 'VNV', 'NV', 'NNV'],
    beaufort: [
      'Stille',
      'Flau vind',
      'Svak vind',
      'Lett bris',
      'Laber bris',
      'Frisk bris',
      'Liten kuling',
      'Stiv kuling',
      'Sterk kuling',
      'Liten storm',
      'Full storm',
      'Sterk storm',
      'Orkan',
    ],
    month: ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des', 'jan-des'],
  };
}

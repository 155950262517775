export interface SeriesData {
  validFrom: string;
  validTo: string;
  timeResolution: string;
  performanceCategory: string;
  exposureCategory: string;
}

/*
  This enum needs to contain the keys from SeriesData and WeatherElement that are going to be displayed in tables
 */
export enum WeatherElementKeys {
  id = 'id',
  name = 'name',
  category = 'category',
  description = 'description',
  validFrom = 'validFrom',
  validTo = 'validTo',
  timeResolution = 'timeResolution',
  performanceCategory = 'performanceCategory',
  exposureCategory = 'exposureCategory',
}

export interface WeatherElement {
  id: string;
  name: string;
  category: string;
  description: string;
  SeriesData: SeriesData[];
}

export interface StationMesurementPeriod {
  sourceId: string;
  elements: WeatherElement[];
}
